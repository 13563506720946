import { TrashIcon } from '@heroicons/react/20/solid';
import React, { CSSProperties, useContext } from 'react';
import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import type { DraggableProvided } from '@hello-pangea/dnd';
import { borderRadius, grid } from './constants';
import type { Quote, AuthorColors } from './types';
import ApiClient from '../../ApiClient';
import { SummaryStore } from '../..';

interface Props {
  quote: Quote;
  isDragging: boolean;
  provided: DraggableProvided;
  isClone?: boolean;
  isGroupedOver?: boolean;
  style?: CSSProperties;
  index?: number;
}

const getBackgroundColor = (
  isDragging: boolean,
  isGroupedOver: boolean,
  authorColors: AuthorColors
) => {
  if (isDragging) {
    return 'white';
  }

  if (isGroupedOver) {
    return colors.N30;
  }

  return colors.N0;
};

const getBorderColor = (isDragging: boolean, authorColors: AuthorColors) =>
  isDragging ? authorColors.hard : 'transparent';

const imageSize = 40;

const CloneBadge = styled.div`
  background: ${colors.G100};
  bottom: ${grid / 2}px;
  border: 2px solid ${colors.G200};
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 10px;
  position: absolute;
  right: -${imageSize / 3}px;
  top: -${imageSize / 3}px;
  transform: rotate(40deg);

  height: ${imageSize}px;
  width: ${imageSize}px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ContainerProps {
  colors: AuthorColors | any;
  isDragging: boolean;
  isGroupedOver: boolean;
}

const Container = styled.a<ContainerProps>`
  border-radius: ${borderRadius}px;
  border: 2px solid transparent;
  border-color: ${(props) => getBorderColor(props.isDragging, props.colors)};
  background-color: ${(props) =>
    getBackgroundColor(props.isDragging, props.isGroupedOver, props.colors)};
  box-shadow: ${({ isDragging }) =>
    isDragging ? `2px 2px 1px ${colors.N70}` : 'none'};
  box-sizing: border-box;
  padding: ${grid}px;
  min-height: ${imageSize}px;
  margin-bottom: ${grid}px;
  user-select: none;

  /* anchor overrides */
  color: ${colors.N900};

  &:hover,
  &:active {
    color: ${colors.N900};
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.colors};
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;

const Content = styled.div`
  /* flex child */
  flex-grow: 1;

  /*
    Needed to wrap text in ie11
    https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
  flex-basis: 100%;

  /* flex parent */
  display: flex;
  justify-content: space-between;
`;

const BlockQuote = styled.div``;

function getStyle(provided: DraggableProvided, style?: CSSProperties | null) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
function QuoteItem(props: Props) {
  const summaryStore = useContext(SummaryStore);
  const {
    quote,
    isDragging,
    isGroupedOver,
    provided,
    style,
    isClone,
    index = 0,
  } = props;

  const deleteIncidentReason = (id: number) => {
    ApiClient.deleteIncidentReason(id)
      .then((response) => {
        summaryStore.deleteIncidentReason(id);
      })
      .catch((error) => {});
  };

  return (
    <Container
      isDragging={isDragging}
      isGroupedOver={Boolean(isGroupedOver)}
      colors={'white'}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={quote?.id}
      data-index={index}
      aria-label={`${quote.id} quote ${quote.stringContent}`}
    >
      {isClone ? <CloneBadge>Clone</CloneBadge> : null}
      <Content>
        <BlockQuote>
          {index + 1}. {quote.stringContent}
        </BlockQuote>
        <TrashIcon
          className="h-5 w-10 flex-shrink-0 text-red-400 hover:cursor-pointer"
          aria-hidden="true"
          onClick={() =>
            deleteIncidentReason(Number(quote.venueStringSettingId!))
          }
        />
      </Content>
    </Container>
  );
}

export default React.memo<Props>(QuoteItem);
