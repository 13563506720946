import { Colors } from '../../Theme';

export const BallLoader = () => {
  return (
    <div className="ball-pulse">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export const GoldBallLoader = () => {
  return (
    <div className="ball-pulse flex-cen-horizontal">
      <div style={{ backgroundColor: '#CDAC3D' }}></div>
      <div style={{ backgroundColor: '#CDAC3D' }}></div>
      <div style={{ backgroundColor: '#CDAC3D' }}></div>
    </div>
  );
};

export const GreenBallLoader = () => {
  return (
    <div className="ball-pulse flex-cen-horizontal">
      <div style={{ backgroundColor: Colors.GreenMatte }}></div>
      <div style={{ backgroundColor: Colors.GreenMatte }}></div>
      <div style={{ backgroundColor: Colors.GreenMatte }}></div>
    </div>
  );
};

export const RedBallLoader = () => {
  return (
    <div className="ball-pulse flex-cen-horizontal">
      <div style={{ backgroundColor: Colors.RedMatte }}></div>
      <div style={{ backgroundColor: Colors.RedMatte }}></div>
      <div style={{ backgroundColor: Colors.RedMatte }}></div>
    </div>
  );
};
