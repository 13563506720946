/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useContext, useEffect, Fragment, ReactNode, useRef } from 'react';
import { observer } from 'mobx-react';
import { SummaryStore, UserStore } from '../../index';
import { useHistory } from 'react-router';
import { reaction, when } from 'mobx';
import {
  faMagnifyingGlass,
  faKey,
  faChartLine,
  faMobileScreen,
  faToolbox,
  faUsers,
  faClipboard,
  faGlobe,
  faCog,
  faMessage,
  faVault,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PageContainer, PageLoader } from '../ui/PageComponents';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
// TYPES
export type SectionStatus = 'OK' | 'ALERT';
export type HomeItemProps = {
  title: string;
  subtitle: string;
  pageRoute: string;
  faIcon: IconProp;
  sectionComponent?: ReactNode;
  status?: SectionStatus;
};

// CONSTANTS
const HOME_SECTIONS = [
  {
    isAdminRequired: false,
    sectionName: 'Case Management',
    pages: [
      {
        title: 'Cases Overview',
        description: 'View and manage cases',
        icon: faClipboard,
        pageRoute: 'cases',
      },
      {
        title: 'Search Cases',
        description:
          'Search members and cases by name or identification number',
        icon: faMagnifyingGlass,
        pageRoute: 'case-search',
      },
    ],
  },
  {
    isAdminRequired: false,
    sectionName: 'Analytics & Reports',
    pages: [
      {
        title: `Gaming Incident Register`,
        description: 'Search and export historical records',
        icon: faVault,
        pageRoute: 'gaming-incident-register',
      },
      {
        title: 'Performance Metrics',
        description: 'View and analyze performance metrics',
        icon: faChartLine,
        pageRoute: 'analytics',
      },
      {
        title: 'Report Generation',
        description: 'Create and export reports',
        icon: faFilePdf,
        pageRoute: 'reporting',
        disabled: true,
      },
    ],
  },
  {
    isAdminRequired: false,
    sectionName: 'Venue Configuration',
    pages: [
      {
        title: 'Sms Configuration',
        description: 'Configure SMS settings',
        icon: faMessage,
        pageRoute: 'sms-configuration',
      },
      {
        title: 'Device Management',
        description: 'Manage venue devices',
        icon: faMobileScreen,
        pageRoute: 'venue-devices',
      },
      {
        title: 'Settings',
        description: 'Configure venue settings',
        icon: faToolbox,
        pageRoute: 'settings',
      },
    ],
  },

  {
    isAdminRequired: true,
    sectionName: 'Geofencing Safety Measures',
    pages: [
      {
        title: 'Geofence Safety Setup',
        description: 'Monitor safety Fences',
        icon: faGlobe,
        pageRoute: 'admin/geo-safety',
      },
      {
        title: 'Geofence Creator',
        description: 'Create and Edit fences',
        icon: faCog,
        pageRoute: 'admin/geo-creator',
      },
    ],
  },
  {
    isAdminRequired: true,
    sectionName: 'PE Administration',
    pages: [
      {
        title: 'User Admin',
        description: 'Create & Manage Command Centre Users',
        icon: faUsers,
        pageRoute: 'admin/command-centre-users',
      },
      {
        title: 'Incident App Device Keys',
        description: 'Create & Manage Device Keys for Incident App',
        icon: faKey,
        pageRoute: 'admin/incident-app-device-keys',
      },
      {
        title: 'Responder App Device Keys',
        description: 'Create & Manage Device Keys for Responder App',
        icon: faKey,
        pageRoute: 'admin/responder-app-device-keys',
      },
    ],
  },
];
// MAIN COMPONENT
export const HomePage = observer(() => {
  const userStore = useContext(UserStore);

  const summaryStore = useContext(SummaryStore);
  const history = useHistory();
  const pollingTimer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    when(
      () => userStore.selectedVenue === undefined,
      () => {
        history.push('/login');
      }
    );

    reaction(
      () => [userStore.isLoggedIn, userStore.selectedVenue],
      (values) => {
        const isLoggedIn = values[0];
        const selectedVenue = values[1];

        if (!isLoggedIn || !selectedVenue) {
          history.push('/login');
        } else {
          summaryStore.refreshHomeData();

          summaryStore.resetCaseSearch();

          summaryStore.startHomeDataPolling();
        }
      },
      {
        fireImmediately: true,
      }
    );

    return () => {
      if (pollingTimer.current) clearInterval(pollingTimer.current);
    };
  }, []);

  return (
    <PageContainer
      centerContent
      breadcrumbs={[{ name: 'Home', route: '/home' }]}
    >
      {!summaryStore.dataLoaded ? (
        <PageLoader />
      ) : (
        <div className="mt-8">
          {HOME_SECTIONS.map(({ isAdminRequired, sectionName, pages }) => (
            <div key={sectionName} className="mt-4">
              {(!isAdminRequired || (isAdminRequired && userStore.isAdmin)) && (
                <Fragment>
                  <div className="text-2xl font-bold">{sectionName}</div>

                  <div
                    id="alert-additional-content-1"
                    className={`${
                      sectionName === 'Case Management' ? '' : 'hidden'
                    } mt-2 p-4 mb-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800`}
                    role="alert"
                  >
                    {[
                      {
                        title: 'Active Cases',
                        value: summaryStore.todaysRequestsCount,
                      },
                      {
                        title: 'Previous Day Cases',
                        value: summaryStore.previousDayRequestsCount,
                      },
                      {
                        title: 'Last 14 Days Cases',
                        value: summaryStore.last28DaysRequestsCount,
                      },
                    ].map((i) => (
                      <div className="flex items-center">
                        <svg
                          className="flex-shrink-0 w-4 h-4 me-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Info</span>
                        <h3 className="text-lg font-medium">
                          {`${i.title}: ${i.value}`}
                        </h3>
                      </div>
                    ))}
                    <div className="mt-2 mb-4 text-sm">
                      More info about this can be viewed at Cases Overview page.
                    </div>
                    <div className="flex">
                      <button
                        onClick={() => history.push('/cases')}
                        type="button"
                        className="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        <svg
                          className="me-2 h-3 w-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 14"
                        >
                          <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                        </svg>
                        View now
                      </button>
                    </div>
                  </div>

                  <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {pages.map(
                      (
                        { title, description, icon, pageRoute, disabled },
                        index
                      ) => (
                        <div
                          key={sectionName + index}
                          onClick={() => {
                            if (disabled) return;

                            history.push(`/${pageRoute}`);
                          }}
                          className="h-52 bg-gray-200 rounded-lg p-4 hover:bg-gray-50 hover:shadow-md transition-all cursor-pointer"
                        >
                          <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-red-500 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <FontAwesomeIcon
                              color={'WHITE'}
                              icon={icon}
                              className="w-6 h-6"
                            />
                          </div>
                          <h3 className="mb-2 text-xl font-bold dark:text-white">
                            {title === 'Gaming Incident Register'
                              ? `${userStore.selectedVenue?.displayName} ${title}`
                              : title}
                          </h3>
                          <p className="text-gray-500 dark:text-gray-400">
                            {description}
                          </p>
                          {disabled && (
                            <p className="text-gray-900 font-bold mt-2">
                              *This page is coming soon
                            </p>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          ))}
        </div>
      )}
    </PageContainer>
  );
});
