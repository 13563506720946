/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  NavLink,
} from 'reactstrap';
import { UserHelpRequests } from '../../../state/SummaryState';
import { useHistory } from 'react-router';
import { buttonCss } from '../../ui/Styles';
import { Colors } from '../../../Theme';
import moment from 'moment';
import {
  getCaseSearchRequestIcon,
  getRequestBackground,
  getRequestIcon,
} from '../../ui/HelperFunctions';
import { Link } from 'react-router-dom';

export type CaseListItemProps = {
  case: UserHelpRequests;
};

export const CaseSearchListItem = (props: CaseListItemProps) => {
  const history = useHistory();
  const isMemberRequest = props.case.requesterMemberNumber != undefined;

  return (
    <Card style={{ marginBottom: 24 }}>
      <CardHeader
        style={{
          fontSize: 22,
          fontWeight: 'bold',
          borderColor: getRequestBackground(props.case),
          display: 'flex',
          borderBottomWidth: 3,
        }}
      >
        {moment(props.case.requestInitiatedTime).format(
          'h:mm:ss a, dddd, MMMM Do YYYY'
        )}{' '}
        {getCaseSearchRequestIcon(props.case)}
      </CardHeader>

      <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
        {isMemberRequest ? (
          <CardTitle tag="h5">MEMBER REQUEST</CardTitle>
        ) : (
          <CardTitle tag="h5">NON-MEMBER REQUEST</CardTitle>
        )}

        {isMemberRequest && (
          <CardSubtitle style={{ marginBottom: 8 }}>
            Member No: {props.case.requesterMemberNumber}
          </CardSubtitle>
        )}

        <CardSubtitle>
          Name: {props.case.requesterFirstName} {props.case.requesterLastName}
        </CardSubtitle>

        <CardSubtitle>
          Phone Number: {props.case.requesterMobileNumber}
        </CardSubtitle>

        <CardTitle style={{ fontSize: 16, fontWeight: 'bold' }}>
          Help Case Count: {props.case.requesterCaseCount}
        </CardTitle>

        <NavLink tag={Link} to={`/case-details/${props.case.helpRequestId}`}>
          <button
            style={{ marginTop: 12 }}
            className="btn btn-primary"
            type="submit"
            css={buttonCss}
          >
            View Case Log
          </button>
        </NavLink>
      </CardBody>
    </Card>
  );
};
