import { Fragment, useState } from 'react';

import ModalContainer from './modal-container';
import DataTable from './DataTable';
import { PageLoader } from '../../ui/PageComponents';

type Props = {
  data: any;
  columnsState: any[];
  error: string;
  loading: boolean;
};

export default function DataTableContainer({
  data,
  columnsState,
  error,
  loading,
}: Props) {
  return (
    <div className="my-4">
      {!error && loading ? (
        <div className="flex flex-col gap-4 items-center justify-center h-64">
          <PageLoader />
        </div>
      ) : data?.searchResults?.length > 0 ? (
        <CaseLogTable data={data} columnsState={columnsState} />
      ) : null}
    </div>
  );
}

const CaseLogTable = ({
  data,
  columnsState,
}: {
  data: any;
  columnsState: {
    show: boolean;
    name: string;
    key: string;
    render: (val: any) => string | JSX.Element;
  }[];
}) => {
  const handleColumns = () => {
    return columnsState.filter((column) => column.show);
  };

  const visibleColumns = handleColumns();

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(null);
  const handleClose = () => {
    setModalData(null);
    setOpenModal(false);
  };
  return (
    <Fragment>
      <ModalContainer
        data={modalData}
        openModal={openModal}
        handleClose={handleClose}
      />

      <DataTable
        data={data}
        visibleColumns={visibleColumns}
        handleOnClick={(data: any) => {
          setModalData(data);
          setOpenModal(true);
        }}
      />
    </Fragment>
  );
};
