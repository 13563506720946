import { Config } from '../Config';
import { CommandCentreVenue } from '../state/UserState';

class UserVenuesStore {
  MIGRATION_KEY = 'userVenueMigrationKey';
  STORAGE_KEY = 'ok2puserVenues';

  migrateStore() {
    let needsMigration =
      localStorage.getItem(this.MIGRATION_KEY) !== Config.buildNumber;

    if (needsMigration) {
      this.clearStore();
      localStorage.setItem(this.MIGRATION_KEY, Config.buildNumber);
    }
  }

  getVenues(): CommandCentreVenue[] | undefined {
    let venues = localStorage.getItem(this.STORAGE_KEY);

    if (venues == null) return undefined;

    return JSON.parse(venues);
  }

  storeVenues(venues: CommandCentreVenue[]) {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(venues));
  }

  clearStore() {
    localStorage.removeItem(this.STORAGE_KEY);
  }
}

export default new UserVenuesStore();
