import * as React from 'react';
import Tippy from '@tippy.js/react';
import { useLocalStore, observer } from 'mobx-react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Fragment } from 'react';
import { CommandCentreVenue } from '../../state/UserState';

type VenuesDropMenuProps = {
  isSingleVenue: boolean;
  selectedVenue?: CommandCentreVenue;
  userVenues?: CommandCentreVenue[];
  totalVenueCount: number;
  selectVenue: (venue: CommandCentreVenue) => void;
  venueSearchTerm?: string;
  updateVenueSearchTerm: (venueSearchTerm: string) => void;
};

export const VenuesDropMenu = observer((props: VenuesDropMenuProps) => {
  const searchBoxRef = React.useRef<HTMLInputElement>(null);
  const { selectedVenue } = props;

  const localState = useLocalStore(() => ({
    isOpen: false,
  }));

  const itemSelected = (venue: CommandCentreVenue) => {
    localState.isOpen = false;
    props.selectVenue(venue);
  };

  const toggleVenueList = () => {
    localState.isOpen = !localState.isOpen;

    if (localState.isOpen && searchBoxRef.current) {
      setTimeout(() => {
        searchBoxRef.current?.focus();
      }, 100);
    }
  };

  if (props.isSingleVenue) {
    return (
      <div className="w-max items-center flex flex-row gap-2 hover:shadow-2xl p-2 rounded-full hover:text-gray-400 dark:hover:text-gray-300">
        <img
          className="w-10 h-10 rounded-full"
          src={selectedVenue?.commCenAppIconUrl}
          alt="Rounded avatar"
        />

        <p>{`${selectedVenue?.displayName}`} </p>
      </div>
    );
  }

  return (
    <OutsideClickHandler onOutsideClick={() => (localState.isOpen = false)}>
      <Tippy
        content={
          <Fragment>
            <div
              id="dropdownSearch"
              className="z-10 bg-white rounded-lg shadow w-60 dark:bg-gray-700"
            >
              <div className="p-3">
                <label htmlFor="input-group-search" className="sr-only">
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                  <input
                    value={props.venueSearchTerm}
                    onChange={(event) =>
                      props.updateVenueSearchTerm(
                        event.target.value.toLowerCase()
                      )
                    }
                    type="text"
                    id="input-group-search"
                    className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search Venue"
                  />
                </div>
              </div>
              <ul
                className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownSearchButton"
              >
                {props.userVenues?.map((venue) => (
                  <li key={venue.venueId} onClick={() => itemSelected(venue)}>
                    <div className="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer">
                      <img
                        className="w-10 h-10 my-2 rounded-full"
                        src={venue.commCenAppIconUrl}
                        alt="logo"
                      />

                      <label
                        htmlFor="checkbox-item-11"
                        className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300 cursor-pointer"
                      >
                        {venue.displayName}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div css={{ padding: 4 }}>
              {props.userVenues?.length === 0 && (
                <div
                  css={{ textAlign: 'center', fontSize: 16, marginBottom: 4 }}
                >
                  No Venues found
                </div>
              )}
            </div>
          </Fragment>
        }
        placement="bottom"
        animation="scale-subtle"
        theme="material"
        arrow={true}
        duration={200}
        delay={[75, 0]}
        distance={8}
        interactive={true}
        visible={localState.isOpen}
      >
        <div
          onClick={toggleVenueList}
          className="flex items-center gap-4 font-medium cursor-pointer hover:shadow-2xl p-2 rounded-full hover:text-gray-400 dark:hover:text-gray-300"
        >
          <img
            className="bottom-2 w-10 h-10 rounded-full "
            src={selectedVenue?.commCenAppIconUrl}
            alt="logo"
          />

          <div className="hover:color-gray-400">
            {selectedVenue?.displayName}
          </div>
        </div>
      </Tippy>
    </OutsideClickHandler>
  );
});
