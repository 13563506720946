/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, {Component, Fragment, useContext, useEffect, useRef, useState} from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import {DataStore, SummaryStore, UserStore} from "../../../index";
import {useHistory} from "react-router";
import {reaction, runInAction, when} from "mobx";
import {Colors} from "../../../Theme";
import ApiClient from "../../../ApiClient";
import {Columns, Rows} from "../../UiKit";
import {PageContainer, PageHeader} from "../../ui/PageComponents";

const cardCss = css({ marginBottom: 24, cursor: 'pointer' });

type VenueSetup = {
    venueId: number;
    displayName: string;
    commCenAppIconUrl: string;
    caseOpenedSmsText: string;
}

export const VenuesSetupAdminPage = observer(() => {
    const userStore = useContext(UserStore);
    const summaryStore = useContext(SummaryStore);
    const history = useHistory();
    
    const [setupVenues, setSetupVenues] = useState<VenueSetup[] | undefined>(undefined);
        
    useEffect(() => {
        when(
            () => userStore.selectedVenue === undefined || !userStore.isAdmin,
            () => {
                history.push('/login');
            }
        );
        
        reaction(
            () => userStore.isLoggedIn,
            (isLoggedIn) => {
                if (!isLoggedIn) {
                    history.push('/login');
                    return;
                }
                
                getVenueSetups();
            },
            {
                fireImmediately: true,
            }
        );
    }, [])
        
    const getVenueSetups = () => {
        ApiClient.getVenueSetups()
            .then((response) => {
                setSetupVenues(response.data.setupVenues);
            })
            .catch(() => {
            });
    };
        
    return (
        <PageContainer breadcrumbs={[
            {name: 'Home', route: '/home'},
            {name: 'Venue Setup Admin', route: '/admin/venues'}
        ]}>
            <PageHeader title={'Ok2Play Venues Management'} />

            {/*{setupVenues === undefined && (*/}
            {/*    <div>*/}
            {/*        <GoldBallLoader />*/}
            {/*        <div>Searching</div>*/}
            {/*    </div>*/}
            {/*)}*/}

            <div css={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '40px 0', width: '100%' }}>
                {setupVenues?.map((venue) => {
                    return <Columns css={{
                        flexGrow: 1,
                        margin: '8px auto',
                        borderRadius: 6,
                        backgroundColor: Colors.VeryLightGrey,
                        padding: 24,
                        width: '60%',
                        flexDirection: 'row'
                    }}>
                        <div
                            className={'flex-cen-stack'}
                            css={{
                                flexGrow: 1,
                                marginRight: 24
                            }}
                        >
                            <Rows css={{ width: '100%' }}>
                                <div css={{ marginRight: 'auto', fontWeight: 'bold' }}>{venue.displayName}</div>
                            </Rows>

                            <Rows css={{ width: '100%' }}>
                                <img css={{ height: 120, marginRight: 'auto' }} src={venue.commCenAppIconUrl} />
                            </Rows>
                        </div>

                        <div>
                            {/*<XCircleFill*/}
                            {/*    css={{*/}
                            {/*        color: Colors.RedMatte,*/}
                            {/*        alpha: 0.8,*/}
                            {/*        '&:hover': {*/}
                            {/*            cursor: 'pointer',*/}
                            {/*            color: 'red',*/}
                            {/*            alpha: 1.0*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*    size={32}*/}
                            {/*    onClick={() => deleteAlertReceiver(receiver.alertReceiverId!)}*/}
                            {/*/>*/}
                        </div>
                    </Columns>
                })}
            </div>
        </PageContainer>
    );
});

