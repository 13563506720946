import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { Key, useEffect, useState } from 'react';
import { UserHelpRequests } from '../../state/SummaryState';
import { RequestActionTaken } from '../pages/cases/CaseDetailsPage';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { cloneDeep } from 'lodash';
import ApiClient from '../../ApiClient';

function compareIncidentReasons(updatedArray: any[], originalArray: any[]) {
  // Initialize arrays to hold the new and removed incident reasons
  let newIncidentReasons: any[] = [];
  let removedIncidentReasons: any[] = [];

  // Create a map for quick lookup of original items by venueStringSettingId
  let originalMap = new Map();
  originalArray.forEach((item: { venueStringSettingId: any }) => {
    originalMap.set(item.venueStringSettingId, item);
  });

  // Iterate through the updated array to find new incident reasons and removed incident reasons
  updatedArray.forEach((updatedItem) => {
    let originalItem = originalMap.get(updatedItem.venueStringSettingId);
    if (originalItem) {
      // Check if the 'selected' property has changed to true
      if (
        updatedItem.selected === true &&
        !originalItem.hasOwnProperty('selected')
      ) {
        newIncidentReasons.push(updatedItem);
      } else if (
        updatedItem.selected === true &&
        originalItem.selected !== true
      ) {
        newIncidentReasons.push(updatedItem);
      }

      // Check if the 'selected' property has changed to false
      if (updatedItem.selected === false && originalItem.selected === true) {
        removedIncidentReasons.push(updatedItem);
      }
    } else if (updatedItem.selected === true) {
      // In case the item was not in the original array but is selected in the updated array
      newIncidentReasons.push(updatedItem);
    }
  });

  // Iterate through the original array to find removed incident reasons
  originalArray.forEach((originalItem) => {
    if (
      originalItem.hasOwnProperty('selected') &&
      originalItem.selected === true
    ) {
      let updatedItem = updatedArray.find(
        (item) =>
          item.venueStringSettingId === originalItem.venueStringSettingId
      );
      if (updatedItem && !updatedItem.selected) {
        removedIncidentReasons.push(updatedItem);
      }
    }
  });

  //remove duplicates
  newIncidentReasons = newIncidentReasons
    .filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (t) => t.venueStringSettingId === item.venueStringSettingId
        )
    )
    .map((item) => ({
      VenueStringSettingId: item.venueStringSettingId,
      Category: item.category,
      IncidentReason: item.stringContent,
    }));

  removedIncidentReasons = removedIncidentReasons
    .filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (t) => t.venueStringSettingId === item.venueStringSettingId
        )
    )
    .map((item) => parseInt(item.requestExtrasId, 10));

  return {
    newIncidentReasons: newIncidentReasons,
    removedIncidentReasons: removedIncidentReasons,
  };
}

export type CaseActionModalProps = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  actionCallback: (actionTaken: RequestActionTaken, actionNote: string) => void;
  userHelpRequest: UserHelpRequests;
  customActions: any;
  incidentReasons: any;
};

export const CasePlayerAssessment = (props: CaseActionModalProps) => {
  const [modalIncidentReasons, setModalIncidentReasons] = useState<any>([]);
  const [modalIncidentReasonsByCategory, setModalIncidentReasonsByCategory] =
    useState<any>({});
  const [actionNote, setActionNote] = useState('');

  const saveSelections = () => {
    const incidentReasonsSelectedOnly = Object.keys(
      modalIncidentReasonsByCategory
    )
      .map((reason) => {
        return modalIncidentReasonsByCategory[reason];
      })
      .flat();

    const updatedArray = cloneDeep(incidentReasonsSelectedOnly);
    const originalArray = cloneDeep(modalIncidentReasons);

    // Run comparison
    const result = compareIncidentReasons(updatedArray, originalArray);

    ApiClient.addActionToIncident({
      requestId: props.userHelpRequest.helpRequestId,
      actionNote,
      newIncidentReasons: result.newIncidentReasons,
      removedIncidentReasonIds: result.removedIncidentReasons,
      customActions: [],
      ActionTaken: 'PLAYER_ASSESSMENT',
    })
      .then((response) => {
        resetModal();
      })
      .catch((error) => {
        console.error('Error saving action:', error);
      });
  };

  const resetModal = () => {
    setActionNote('');
    props.setShowModal(false);
  };

  useEffect(() => {
    const venueIncidentReasons = props.incidentReasons;
    const incidentReasons = props.userHelpRequest.incidentReasons;
    const modalIncidentReasons = venueIncidentReasons.map(
      (venueAction: any) => {
        let incidentReason: any = {
          requestExtrasId: null,
        };
        const isMatch = incidentReasons.find((incidentAction: any) => {
          incidentReason = incidentAction;
          return (
            incidentAction.venueStringSettingId ===
            venueAction.venueStringSettingId
          );
        });
        if (isMatch) {
          return {
            requestExtrasId: incidentReason.requestExtrasId ?? null,
            ...venueAction,
            selected: true,
          };
        }
        return {
          ...venueAction,
          requestExtrasId: null,
        };
      }
    );
    setModalIncidentReasons(modalIncidentReasons);
  }, [props.showModal]);

  useEffect(() => {
    const groupedReasons = modalIncidentReasons.reduce(
      (acc: any, reason: any) => {
        if (!acc[reason.category]) {
          acc[reason.category] = [];
        }
        acc[reason.category].push(reason);
        return acc;
      },
      {}
    );
    setModalIncidentReasonsByCategory(groupedReasons);
  }, [modalIncidentReasons]);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={props.showModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <form
            className="max-w p-10 mx-auto"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h1 className="text-left text-xl font-semibold text-gray-900 dark:text-white mb-4">
              {`Player Asessment`}
            </h1>
            <p className="text-left text-sm font-light text-gray-900 dark:text-white mb-4">
              Pick the sign that best describes the conversation start.
            </p>
            <div>
              <h1 className="text-left text-sm font-semibold text-gray-900 dark:text-white mb-3 mt-3">
                Player Assessment
              </h1>
              {Object.keys(modalIncidentReasonsByCategory).map((reason) => {
                return (
                  <Accordion key={reason} className="mb-2">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className="text-md font-semibold text-gray-900 dark:text-white">
                        {reason}

                        {modalIncidentReasonsByCategory[reason].filter(
                          (action: any) => action.selected
                        ).length > 0
                          ? ` (${
                              modalIncidentReasonsByCategory[reason].filter(
                                (action: any) => action.selected
                              ).length
                            })`
                          : ''}
                      </p>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className="flex flex-col gap-2">
                        {modalIncidentReasonsByCategory[reason].map(
                          (action: any, index: Key | null | undefined) => {
                            const isChecked = action.selected || false;
                            return (
                              <div
                                key={`checkbox-${reason}-${index}`}
                                onClick={() => {
                                  setModalIncidentReasonsByCategory(
                                    (prev: { [x: string]: any[] }) => {
                                      return Object.keys(prev).reduce(
                                        (acc: any, key: any) => {
                                          acc[key] = prev[key].map(
                                            (item: any) => {
                                              if (
                                                item.venueStringSettingId ===
                                                action.venueStringSettingId
                                              ) {
                                                return {
                                                  ...item,
                                                  selected: !item.selected,
                                                };
                                              }
                                              return item;
                                            }
                                          );
                                          return acc;
                                        },
                                        {}
                                      );
                                    }
                                  );
                                }}
                                className={`transition duration-150 ease-out cursor-pointer flex flex-row justify-between items-center p-3 gap-3 rounded-xl ${
                                  isChecked
                                    ? 'border-primary-900 border-2'
                                    : 'border'
                                }`}
                              >
                                <label
                                  htmlFor={`checkbox-${reason}-${index}`}
                                  className={`ms-2 text-sm font-medium dark:text-gray-300 ${
                                    isChecked
                                      ? 'text-primary-900'
                                      : 'text-gray-900'
                                  }`}
                                >
                                  {action.stringContent}
                                </label>
                                <input
                                  id={`checkbox-${reason}-${index}`}
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => {}}
                                  className="w-4 h-4 text-primary-900 bg-gray-100 border-gray-300 rounded focus:ring-primary-900 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>

            <div className="flex flex-wrap items-center justify-center gap-2 mt-4 mb-2 dark:gap-4">
              <button
                onClick={saveSelections}
                type="button"
                className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Submit Action
              </button>
              <button
                type="button"
                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                onClick={resetModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};
