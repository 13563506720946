/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Container, NavLink } from 'reactstrap';
import { ReactNode, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Columns } from '../UiKit';
import { RedBallLoader } from './Loading';
import BreadCrumbs from '../BreadCrumbs';

export type BreadCrumb = {
  name: string;
  route: string;
};

export type PageHeaderProps = {
  title: string;
  cssProps?: {};
};

export const PageHeader = (props: PageHeaderProps) => {
  return (
    <div
      style={{
        marginTop: 0,
        fontSize: 26,
        textAlign: 'center',
        marginBottom: 16,
        ...props.cssProps,
      }}
    >
      {props.title}
    </div>
  );
};

export const PageSubHeader = (props: PageHeaderProps) => {
  return (
    <div
      style={{
        fontSize: 20,
        textAlign: 'center',
        marginTop: 16,
        marginBottom: 16,
        ...props.cssProps,
      }}
    >
      {props.title}
    </div>
  );
};

export const PageSubText = (props: PageHeaderProps) => {
  return (
    <div
      style={{
        fontSize: 18,
        textAlign: 'center',
        marginTop: 16,
        marginBottom: 16,
        ...props.cssProps,
      }}
    >
      {props.title}
    </div>
  );
};

export type PageContainerProps = {
  children: ReactNode;
  breadcrumbs: BreadCrumb[];
  centerContent?: boolean;
  css?: {};
};

let breadCrumbCss = css({
  padding: '8px 12px',
  fontSize: 16,
  color: 'black',
});

let activeBreadCrumbCss = css({
  padding: '8px 12px',
  fontSize: 16,
  color: 'black',
  '&:hover': {
    color: 'black',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

export const PageLoader = () => {
  return (
    <Columns
      style={{
        height: 600,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          color: 'black',
          fontWeight: 'bold',
          fontSize: 20,
          marginBottom: 24,
        }}
      >
        Loading...
      </div>
      <RedBallLoader />
    </Columns>
  );
};

export const PageContainer = (props: PageContainerProps) => {
  return (
    <Fragment>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-4 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <BreadCrumbs routes={props.breadcrumbs} />
          {props.centerContent ? (
            <div className={'flex flex-cen-stack'}>{props.children}</div>
          ) : (
            <Fragment>{props.children}</Fragment>
          )}
        </div>
      </section>
    </Fragment>
  );
};
