/** @jsxRuntime classic */
/** @jsx jsx */
import {css} from "@emotion/react";
import {Colors} from "../../Theme";

export const buttonCss = css({
    margin: 'auto',
    display: 'block',
    backgroundColor: Colors.LegoDark,
    borderColor: Colors.LegoDark
});

export const borderCss = css({
    border: '1px solid lightgrey',
    padding: 8,
    margin: 4,
    borderRadius: 12
});

export const buttonLightCss = css({
    margin: 'auto',
    display: 'block',
    backgroundColor: Colors.LightGrey,
    borderColor: Colors.LightGrey
});

export const exitButtonCss = css({
    margin: 'auto',
    display: 'block',
    backgroundColor: Colors.RedMatte,
    borderColor: Colors.RedMatte
});

export const redButtonCss = css({
    margin: 'auto',
    display: 'block',
    backgroundColor: Colors.RedMatte,
    borderColor: Colors.RedMatte
});

export const greenButtonCss = css({
    margin: 'auto',
    display: 'block',
    backgroundColor: Colors.GreenMatte,
    borderColor: Colors.GreenMatte
});

export const buttonStyle = {
    margin: 'auto',
    display: 'block',
    backgroundColor: Colors.LegoDark,
    borderColor: Colors.LegoDark,
    marginRight: 12
};

export const countCircleCss = css({
    backgroundColor: 'lightgray',
    borderRadius: '50%',
    height: 100,
    width: 100,
    marginBottom: 6
});

export const modalStyle = {
    content: {
        top: '20%',
        left: '20%',
        right: '20%',
        bottom: '20%',
        overscrollBehavior: 'contain'
    }
};