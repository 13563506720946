import { css } from '@emotion/react';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { reaction, when } from 'mobx';
import { Card, CardBody } from 'reactstrap';
import { CheckCircleFill, ExclamationCircle } from 'react-bootstrap-icons';
import moment from 'moment/moment';
import { UserStore } from '../../../index';
import ApiClient from '../../../ApiClient';
import { Colors } from '../../../Theme';
import { Rows } from '../../UiKit';
import { PageContainer, PageHeader } from '../../ui/PageComponents';

const cardCss = css({ marginBottom: 24, cursor: 'pointer' });

export type VenueDevice = {
  venueDeviceId: number;
  venueId: number;
  deviceNumber: number;
  lastLoginVenueStaffName: string;
  lastLoginTime: string;
  lastPingTime: string;
  batteryPercent: string;
  lastPingIsLoggedIn: boolean;
};

export const VenueDevicesPage = observer(() => {
  const userStore = useContext(UserStore);
  const history = useHistory();

  const pollingTimer = useRef<NodeJS.Timeout>();

  const [venueDevices, setVenueDevices] = useState<VenueDevice[] | undefined>(
    undefined
  );

  useEffect(() => {
    when(
      () => userStore.selectedVenue === undefined,
      () => {
        history.push('/login');
      }
    );

    reaction(
      () => userStore.isLoggedIn,
      (isLoggedIn) => {
        if (!isLoggedIn) {
          history.push('/login');
        } else {
          loadVenueDevices();

          pollingTimer.current = setInterval(() => {
            loadVenueDevices();
          }, 10000);
        }

        console.log('reaction running.. refresh');
      },
      {
        fireImmediately: true,
      }
    );

    return () => {
      if (pollingTimer.current) clearInterval(pollingTimer.current);
    };
  }, []);

  const loadVenueDevices = () => {
    ApiClient.getVenueDevices()
      .then((response: any) => {
        setVenueDevices(response.data.venueDevices);
      })
      .catch((error) => {});
  };

  const venueDeviceViews = () => {
    return venueDevices!!.map((device) => {
      return (
        <Card style={cardCss && { marginTop: 30, marginBottom: 16 }}>
          <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
            <h5
              className="card-title"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div>Device {device.deviceNumber}</div>

              {device.lastPingIsLoggedIn ? (
                <div style={{ fontWeight: 'bold', marginLeft: 'auto' }}>
                  Currently Logged In:{' '}
                  <CheckCircleFill
                    size={28}
                    color={Colors.GreenMatte}
                    style={{ marginLeft: 8 }}
                  />
                </div>
              ) : (
                <div style={{ marginLeft: 'auto' }}>
                  <ExclamationCircle
                    size={28}
                    color={Colors.LegoYellow}
                    style={{ marginLeft: 8 }}
                  />
                </div>
              )}
            </h5>

            <div style={{ fontSize: 18, marginBottom: 8, marginTop: 4 }}>
              {device.lastPingIsLoggedIn ? (
                <Fragment>
                  <b>{device.lastLoginVenueStaffName}</b> logged in at{' '}
                  {moment(device.lastLoginTime).format(
                    'h:mm:ss a, dddd, MMMM Do YYYY'
                  )}
                </Fragment>
              ) : (
                <div style={{ fontStyle: 'italic' }}>
                  There is no one logged into this device
                </div>
              )}
            </div>

            <div>
              Battery:{' '}
              <span
                style={{
                  color: Colors.GreenMatte,
                  fontWeight: 'bold',
                  marginTop: 4,
                }}
              >
                {device.batteryPercent}%
              </span>
            </div>

            <div>
              Last Ping Time:{' '}
              {moment(device.lastPingTime).format(
                'h:mm:ss a, dddd, MMMM Do YYYY'
              )}
            </div>

            <div style={{ height: 1, background: 'lightgray', margin: 15 }} />

            <Rows style={{ marginLeft: 'auto' }}>
              <div style={{ marginRight: 12, color: 'green' }}>
                This Device is Active
              </div>
              {/*<button*/}
              {/*    className="btn btn-primary"*/}
              {/*    type="submit"*/}
              {/*    style={[exitButtonCss, css({ marginRight: 0 })]}*/}
              {/*>*/}
              {/*    Disable Device*/}
              {/*</button>*/}
            </Rows>
          </CardBody>
        </Card>
      );
    });
  };

  return (
    <PageContainer
      breadcrumbs={[
        { name: 'Home', route: '/home' },
        { name: 'Venue Devices', route: '/venue-devices' },
      ]}
    >
      <PageHeader title={'Device Management'} />

      {venueDevices == undefined ? (
        <div>Loading...</div>
      ) : (
        <Fragment>
          <div style={{ fontSize: 20 }}>
            {venueDevices.length} Devices Installed
          </div>

          {venueDeviceViews()}
        </Fragment>
      )}
    </PageContainer>
  );
});
