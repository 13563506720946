import {
  PencilSquareIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import React, { Component, ReactElement, useContext, useState } from 'react';
import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import { Draggable } from '@hello-pangea/dnd';
import type {
  DraggableProvided,
  DraggableStateSnapshot,
} from '@hello-pangea/dnd';
import { grid, borderRadius } from './constants';
import QuoteList from './quote-list';
import Title from './title';
import type { Quote } from './types';
import ApiClient from '../../ApiClient';
import { SummaryStore } from '../..';

const Container = styled.div`
  margin: ${grid}px;
  width: 300px;
  display: flex;
  flex-direction: column;
`;

interface HeaderProps {
  isDragging: boolean;
}

const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
  background-color: ${({ isDragging }) =>
    isDragging ? colors.G50 : colors.N30};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${colors.G50};
  }
`;

interface Props {
  title: string;
  quotes: Quote[];
  index: number;
  isScrollable?: boolean;
  isCombineEnabled?: boolean;
  useClone?: boolean;
  getUpdatedIncidentReasons: () => void;
}

const Column: React.FC<Props> = ({
  title,
  quotes,
  index,
  isScrollable = false,
  isCombineEnabled = false,
  useClone = false,
  getUpdatedIncidentReasons,
}) => {
  const summaryStore = useContext(SummaryStore);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [category, setCategory] = useState(title);

  const handleSubmit = async () => {
    if (category === title) return;

    ApiClient.renameIncidentReasonCategory({
      OldCategory: title,
      NewCategory: category,
    })
      .then((response) => {
        summaryStore.refreshHomeData();
        getUpdatedIncidentReasons();
        setIsEditingCategory(false);
      })
      .catch((error) => {});
  };

  return (
    <Draggable draggableId={title} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <Container ref={provided.innerRef} {...provided.draggableProps}>
          <Header isDragging={snapshot.isDragging} className="">
            {isEditingCategory ? (
              <div className="w-full">
                <input
                  type="text"
                  id="category"
                  className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Category name"
                  required
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
                <div className="flex items-center justify-center mt-2">
                  <CheckCircleIcon
                    className="h-5 w-10 flex-shrink-0 text-green-900 hover:text-green-500 hover:cursor-pointer"
                    aria-hidden="true"
                    onClick={() => handleSubmit()}
                  />
                  <XCircleIcon
                    className="h-5 w-10 flex-shrink-0 text-red-900 hover:text-red-500 hover:cursor-pointer"
                    aria-hidden="true"
                    onClick={() => {
                      setIsEditingCategory(false);
                      setCategory(title);
                    }}
                  />
                </div>
              </div>
            ) : (
              <>
                <span className="inline-flex items-center justify-center w-6 h-6 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                  {index + 1}
                </span>
                <Title
                  className="text-lg font-bold"
                  {...provided.dragHandleProps}
                  aria-label={`${title} quote list`}
                >
                  {title}
                </Title>
                <PencilSquareIcon
                  className="h-5 w-10 flex-shrink-0 text-gray-900 hover:cursor-pointer"
                  aria-hidden="true"
                  onClick={() => setIsEditingCategory(true)}
                />
              </>
            )}
          </Header>
          <QuoteList
            listId={title}
            listType="QUOTE"
            style={{
              width: '100%',
              backgroundColor: snapshot.isDragging ? colors.G50 : undefined,
            }}
            quotes={quotes}
            internalScroll={isScrollable}
            isCombineEnabled={isCombineEnabled}
            useClone={useClone}
          />
        </Container>
      )}
    </Draggable>
  );
};

export default Column;
