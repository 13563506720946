/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

export type ModalTitleProps = {
  title: string;
};

export const ModalTitle = (props: ModalTitleProps) => {
  return (
    <div css={{ textAlign: 'center', marginBottom: 24, fontSize: 24 }}>
      {props.title}
    </div>
  );
};
