type Props = {
  setSearchType: (value: string) => void;
  setSearchValue: (value: string) => void;
  setError: (value: string) => void;
  setData: (value: any) => void;
};
export default function RadioGroup({
  setSearchType,
  setSearchValue,
  setError,
  setData,
}: Props) {
  return (
    <div>
      <div className="flex items-center mb-4 ml-8">
        <input
          id="option-1"
          type="radio"
          name="search"
          value="date-range"
          className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
          defaultChecked
          onChange={(e) => {
            setSearchType(e.target.value);
            setError('');
            setData({
              searchResults: [],
              totalResults: 0,
            });
          }}
        />
        <label
          htmlFor="option-1"
          className="block ms-2  text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Search by Date Range
        </label>
      </div>

      <div className="flex items-center mb-4 ml-8">
        <input
          id="option-2"
          type="radio"
          name="search"
          value="member-name"
          className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
          onChange={(e) => {
            setSearchType(e.target.value);
            setSearchValue('');
            setError('');
            setData({
              searchResults: [],
              totalResults: 0,
            });
          }}
        />
        <label
          htmlFor="option-2"
          className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Search by Member Name or Mobile Number
        </label>
      </div>

      <div className="flex items-center mb-4 ml-8">
        <input
          id="option-3"
          type="radio"
          name="search"
          value="staff-name"
          className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
          onChange={(e) => {
            setSearchType(e.target.value);
            setSearchValue('');
            setError('');
            setData({
              searchResults: [],
              totalResults: 0,
            });
          }}
        />
        <label
          htmlFor="option-3"
          className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Search by Staff Member
        </label>
      </div>
    </div>
  );
}
