// @ts-nocheck

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { SummaryStore, UserStore } from '../../../index';
import { useHistory } from 'react-router';
import { reaction, toJS, when } from 'mobx';
import ApiClient from '../../../ApiClient';
import { Columns, Rows } from '../../UiKit';
import { PageContainer, PageHeader } from '../../ui/PageComponents';
import GoogleMapReact from 'google-map-react';
import IDGenerator from '../../../IDGenerator';
import MapSearchBox from './MapSearchBox';

const cardCss = css({ marginBottom: 24, cursor: 'pointer' });

type VenueGeofence = {
  locationId: string;
  name: string;
  coordinates: any[];

  updatedName: string;
  fenceStatus?: 'UPDATED' | 'NEW' | 'DELETED';
  updatedCoordinates: any[];
  poly?: any;
};

// C# vars on html
declare var google: any;

export const GeoCreatorAdminPage = observer(() => {
  const userStore = useContext(UserStore);
  const history = useHistory();

  const [pageLoaded, setPageLoaded] = useState(false);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);

  const [map, setMap] = useState<any>(null);
  const [mapApi, setMapApi] = useState<any>(null);
  const [selectedPlace, setSelectedPlace] = useState<any>(null);

  const [selectedPolygon, setSelectedPolygon] = useState<
    VenueGeofence | undefined
  >(undefined);
  const selectedPolygonRef = useRef(selectedPolygon);

  const [venueGeofences, setVenueGeofences] = useState<VenueGeofence[]>([]);
  const venueGeofencesRef = useRef(venueGeofences);

  useEffect(() => {
    when(
      () => userStore.selectedVenue === undefined || !userStore.isAdmin,
      () => {
        history.push('/login');
      }
    );

    reaction(
      () => userStore.isLoggedIn,
      (isLoggedIn) => {
        if (!isLoggedIn) {
          history.push('/login');
          return;
        }
      },
      {
        fireImmediately: true,
      }
    );
  }, []);

  const handleApiLoaded = (loadedMap: any, loadedMapApi: any) => {
    console.log('handleApiLoaded');

    console.log(loadedMap);
    console.log(loadedMapApi);

    setMap(loadedMap);
    setMapApi(loadedMapApi);
    // setGoogleMapsLoaded(true);
    // loadFenceConfig();
  };

  useEffect(() => {
    if (map && mapApi) {
      setGoogleMapsLoaded(true);

      loadFenceConfig();
    }
  }, [map, mapApi]);

  useEffect(() => {
    console.log('venueGeofences length: ' + venueGeofences.length);
    console.log(venueGeofences);

    // Sync the ref with state
    venueGeofencesRef.current = venueGeofences;
  }, [venueGeofences]);

  useEffect(() => {
    console.log('selected Poly');

    // Sync the ref with state
    selectedPolygonRef.current = selectedPolygon;
  }, [selectedPolygon]);

  const loadFenceConfig = () => {
    ApiClient.getAllGeofences()
      .then((response: any) => {
        let fences = response.data.geofences;

        setPageLoaded(true);

        fences.forEach((fence: VenueGeofence) => {
          let realCoords: any[] = [];

          fence.coordinates
            .slice(0, fence.coordinates.length - 1)
            .forEach((coord) => {
              realCoords.push(
                new google.maps.LatLng(coord.latitude, coord.longitude)
              );
            });

          fence.coordinates = realCoords;
          fence.updatedCoordinates = realCoords;
          fence.updatedName = fence.name;

          generateMapPolygon(fence, realCoords);
          addFenceToVenueGeofences(fence);
        });
      })
      .catch(() => {});
  };

  const addNewPolygon = () => {
    let mapCenter;

    if (selectedPlace != undefined) {
      mapCenter = selectedPlace.geometry.location;
      setSelectedPlace(undefined);
    } else {
      mapCenter = map.getCenter();
    }

    let scale = 0.05 * 0.01;

    let polyCoordinates = [
      new google.maps.LatLng(mapCenter.lat() - scale, mapCenter.lng() + scale),
      new google.maps.LatLng(mapCenter.lat() + scale, mapCenter.lng() + scale),
      new google.maps.LatLng(mapCenter.lat() + scale, mapCenter.lng() - scale),
      new google.maps.LatLng(mapCenter.lat() - scale, mapCenter.lng() - scale),
    ];

    console.log(polyCoordinates);

    let id = IDGenerator.generate();

    console.log(id);

    let newFence: VenueGeofence = {
      locationId: id,
      name: '',
      updatedName: '',
      coordinates: polyCoordinates,
      updatedCoordinates: polyCoordinates,
      fenceStatus: 'NEW',
    };

    const localFence = generateMapPolygon(newFence, polyCoordinates);

    console.log('created local fence...');
    console.log(localFence);
    console.log(venueGeofences);

    addFenceToVenueGeofences(localFence);

    console.log(venueGeofences);

    setSelectedPolygon(localFence);
  };

  const deleteSelected = () => {
    if (!selectedPolygon) return;

    let fence = selectedPolygon;
    if (fence.fenceStatus == 'NEW') {
      setSelectedPolygon(undefined);

      // remove from local array
      let fenceOb = venueGeofences
        .slice()
        .filter((f) => f.locationId == fence.locationId)[0];
      let fenceIndex = venueGeofences.indexOf(fenceOb);

      const fences = venueGeofences.splice(fenceIndex, 1);
      setVenueGeofences(fences);

      // remove from map
      fence.poly.setMap(null);

      return;
    }

    ApiClient.deleteGeofence(fence.locationId)
      .then((response: any) => {
        setSelectedPolygon(undefined);

        // remove from local array
        let fenceOb = venueGeofences
          .slice()
          .filter((f) => f.locationId == fence.locationId)[0];
        let fenceIndex = venueGeofences.indexOf(fenceOb);

        const fences = venueGeofences.splice(fenceIndex, 1);
        setVenueGeofences(fences);

        // remove from map
        fence.poly.setMap(null);
      })
      .catch((error) => {});
  };

  const deleteNew = (fence: VenueGeofence) => {
    // remove from local array
    let fenceOb = venueGeofences
      .slice()
      .filter((f) => f.locationId == fence.locationId)[0];
    let fenceIndex = venueGeofences.indexOf(fenceOb);

    const fences = venueGeofences.splice(fenceIndex, 1);
    setVenueGeofences(fences);

    // remove from map
    fence.poly.setMap(null);

    setSelectedPolygon(undefined);
  };

  const generateMapPolygon = (fence: VenueGeofence, coordinates: any) => {
    let color = '#FF0000';
    let purp = '#A020F0';

    if (fence.fenceStatus == 'NEW') {
      color = '#0000FF';
    } else if (fence.fenceStatus == 'UPDATED') {
      color = '#008000';
    }

    // Styling & Controls
    let fencePoly = new google.maps.Polygon({
      paths: coordinates,
      draggable: true, // turn off if it gets annoying
      editable: true,
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.35,
    });

    fencePoly.setMap(map);

    google.maps.event.addListener(fencePoly, 'click', () => {
      const fences = [...venueGeofencesRef.current];
      const selectedPolygon = selectedPolygonRef.current;

      // update previous
      if (selectedPolygon) {
        const fenceIndex = fences.findIndex(
          (f) => f.locationId === selectedPolygon.locationId
        );

        if (fenceIndex !== -1) {
          const updatedGeofences = [...fences];
          const fenceOb = updatedGeofences[fenceIndex];

          switch (fenceOb.fenceStatus) {
            case 'NEW':
              fenceOb.poly.setOptions({
                fillColor: '#0000FF',
                strokeColor: '#0000FF',
              });
              break;
            case 'UPDATED':
              fenceOb.poly.setOptions({
                fillColor: '#008000',
                strokeColor: '#008000',
              });
              break;
            default:
              fenceOb.poly.setOptions({
                fillColor: '#FF0000',
                strokeColor: '#FF0000',
              });
              break;
          }

          // This line sets the updated array to state
          setVenueGeofences(updatedGeofences);
        }
      }

      // update new
      setSelectedPolygon(fence);

      console.log('clicked on locationId: ' + fence.locationId);
      console.log('fences count: ' + fences.length);

      let fenceOb = fences.filter((f) => f.locationId == fence.locationId)[0];
      let fenceIndex = fences.indexOf(fenceOb);

      const updatedName = fences[fenceIndex].updatedName;
      updateSelectedPolygonName(fenceIndex, updatedName);

      fence.poly.setOptions({ fillColor: purp, strokeColor: purp });

      // Try to prevent event propagation to the map
      /* eslint-disable no-restricted-globals */
      event!!.stopImmediatePropagation();
      event!!.cancelBubble = true;
      if (event?.stopPropagation) {
        event.stopPropagation();
      }
      if (event?.preventDefault) {
        event.preventDefault();
      } else {
        event!!.returnValue = false;
      }
      /* eslint-enable no-restricted-globals */
    });

    google.maps.event.addListener(fencePoly, 'dragend', () => {
      const fences = [...venueGeofencesRef.current];

      const fenceIndex = fences.findIndex(
        (f) => f.locationId === fence.locationId
      );
      if (fenceIndex !== -1) {
        const fenceOb = fences[fenceIndex];
        fenceOb.updatedCoordinates = fencePoly.getPath().getArray();
        updateLocalFence(fenceOb);
      }

      // Try to prevent event propagation to the map
      /* eslint-disable no-restricted-globals */
      event!!.stopImmediatePropagation();
      event!!.cancelBubble = true;
      if (event?.stopPropagation) {
        event!!.stopPropagation();
      }
      if (event?.preventDefault) {
        event!!.preventDefault();
      } else {
        event!!.returnValue = false;
      }
      /* eslint-enable no-restricted-globals */
    });

    google.maps.event.addListener(fencePoly.getPath(), 'set_at', () => {
      const fences = [...venueGeofencesRef.current];

      console.log('set_at');

      const fenceIndex = fences.findIndex(
        (f) => f.locationId === fence.locationId
      );
      if (fenceIndex !== -1) {
        const fenceOb = fences[fenceIndex];
        fenceOb.updatedCoordinates = fencePoly.getPath().getArray();
        updateLocalFence(fenceOb);
      }
    });

    google.maps.event.addListener(fencePoly.getPath(), 'insert_at', () => {
      const fences = [...venueGeofencesRef.current];

      console.log('insert_at');

      const fenceIndex = fences.findIndex(
        (f) => f.locationId === fence.locationId
      );
      if (fenceIndex !== -1) {
        const fenceOb = fences[fenceIndex];
        fenceOb.updatedCoordinates = fencePoly.getPath().getArray();
        updateLocalFence(fenceOb);
      }
    });

    google.maps.event.addListener(fencePoly.getPath(), 'remove_at', () => {
      const fences = [...venueGeofencesRef.current];

      console.log('remove_at');

      const fenceIndex = fences.findIndex(
        (f) => f.locationId === fence.locationId
      );
      if (fenceIndex !== -1) {
        const fenceOb = fences[fenceIndex];
        fenceOb.updatedCoordinates = fencePoly.getPath().getArray();
        updateLocalFence(fenceOb);
      }
    });

    fence.poly = fencePoly;

    return fence;
  };

  const saveFence = (fence: VenueGeofence) => {
    setSelectedPolygon(undefined);
    console.log('Saving fence');

    if (fence.fenceStatus == 'UPDATED') {
      ApiClient.updateGeofence(
        fence.locationId,
        fence.updatedName,
        fence.updatedCoordinates
      )
        .then((response: any) => {
          let fenceOb = venueGeofences
            .slice()
            .filter((f) => f.locationId == fence.locationId)[0];
          let fenceIndex = venueGeofences.indexOf(fenceOb);

          fenceOb.coordinates = fence.updatedCoordinates;
          fenceOb.fenceStatus = undefined;

          // this.venueGeofences[fenceIndex] = fenceOb;
          updateFenceInVenueGeofences(fenceIndex, fenceOb);

          // we're calling map drawing functions here
          fence.poly.setPath(fenceOb.coordinates);
          fence.poly.setOptions({
            fillColor: '#FF0000',
            strokeColor: '#FF0000',
          });
        })
        .catch((error) => {});
    } else if (fence.fenceStatus == 'NEW') {
      ApiClient.addNewGeofence(fence.updatedName, fence.updatedCoordinates)
        .then((response: any) => {
          let remoteId = response.data;

          console.log('response Id');
          console.log(remoteId);

          let fenceOb = venueGeofences
            .slice()
            .filter((f) => f.locationId == fence.locationId)[0];
          let fenceIndex = venueGeofences.indexOf(fenceOb);

          fenceOb.coordinates = fence.updatedCoordinates;
          fenceOb.name = fence.updatedName;
          fenceOb.fenceStatus = undefined;

          // this.venueGeofences[fenceIndex] = fenceOb;
          updateFenceInVenueGeofences(fenceIndex, fenceOb);

          // we're calling map drawing functions here
          fence.poly.setPath(fenceOb.coordinates);
          fence.poly.setOptions({
            fillColor: '#FF0000',
            strokeColor: '#FF0000',
          });
        })
        .catch((error) => {});
    }
  };

  const addFenceToVenueGeofences = (fence: VenueGeofence) => {
    setVenueGeofences((prevState) => [...prevState, fence]);
  };

  const updateSelectedPolygonName = (
    fenceIndex: number,
    updatedName: string
  ) => {
    if (selectedPolygon) {
      const updatedPolygon = {
        ...selectedPolygon,
        updatedName: updatedName,
      };

      setSelectedPolygon(updatedPolygon);
    }
  };

  const updateFenceInVenueGeofences = (
    fenceIndex: number,
    fenceOb: VenueGeofence
  ) => {
    const updatedGeofences = [...venueGeofencesRef.current];
    updatedGeofences[fenceIndex] = fenceOb;
    setVenueGeofences(updatedGeofences);
  };

  const updateLocalFence = (fence: VenueGeofence) => {
    const fences = [...venueGeofencesRef.current];
    const selectedPolygon = selectedPolygonRef.current;

    let fenceOb = fences.filter((f) => f.locationId == fence.locationId)[0];
    let fenceIndex = fences.indexOf(fenceOb);

    fenceOb.updatedCoordinates = fence.updatedCoordinates;

    if (
      fence.updatedCoordinates != fence.coordinates &&
      fenceOb.fenceStatus != 'NEW'
    ) {
      fenceOb.fenceStatus = 'UPDATED';

      if (selectedPolygon == undefined || toJS(selectedPolygon) != fence)
        fenceOb.poly!!.setOptions({
          fillColor: '#008000',
          strokeColor: '#008000',
        });
    }

    updateFenceInVenueGeofences(fenceIndex, fenceOb);
  };

  const handleGeofenceNameChange = (event: any) => {
    if (!selectedPolygon) return;

    let fence = selectedPolygon;
    let fenceOb = venueGeofences
      .slice()
      .filter((f) => f.locationId == fence.locationId)[0];
    let fenceIndex = venueGeofences.indexOf(fenceOb);

    fenceOb.updatedName = event.target.value;
    updateSelectedPolygonName(fenceIndex, event.target.value);
    // this.selectedPolygon.updatedName = event.target.value;

    if (fenceOb.name != fenceOb.updatedName && fenceOb.fenceStatus != 'NEW') {
      fenceOb.fenceStatus = 'UPDATED';
    }

    // this.venueGeofences[fenceIndex] = fenceOb;
    updateFenceInVenueGeofences(fenceIndex, fenceOb);
  };

  const clearSelected = () => {
    if (!selectedPolygon) return;

    const fenceIndex = venueGeofences.findIndex(
      (f) => f.locationId === selectedPolygon.locationId
    );
    if (fenceIndex !== -1) {
      const updatedGeofences = [...venueGeofences];
      const fenceOb = updatedGeofences[fenceIndex];

      switch (fenceOb.fenceStatus) {
        case 'NEW':
          fenceOb.poly.setOptions({
            fillColor: '#0000FF',
            strokeColor: '#0000FF',
          });
          break;
        case 'UPDATED':
          fenceOb.poly.setOptions({
            fillColor: '#008000',
            strokeColor: '#008000',
          });
          break;
        default:
          fenceOb.poly.setOptions({
            fillColor: '#FF0000',
            strokeColor: '#FF0000',
          });
          break;
      }

      // This line sets the updated array to state
      setVenueGeofences(updatedGeofences);
    }

    setSelectedPolygon(undefined);
  };

  const addPlace = (place: any) => {
    setSelectedPlace(place);
  };

  const getContent = () => {
    // if (!pageLoaded) {
    //     return <div>
    //         Loading...
    //     </div>
    // }

    console.log('venueGeofences', venueGeofences);

    return (
      <Fragment>
        <Columns>
          <div
            style={{ color: 'black', marginBottom: 30 }}
            className={'flex-cen-stack'}
          >
            <div className={'col-gold-main'}>
              Fences Count: {venueGeofences.length}
            </div>
          </div>

          <button
            className={'btn btn-primary flex-cen-horizontal'}
            css={{ margin: '0 8px', maxWidth: 400 }}
            onClick={addNewPolygon}
          >
            Add new Geofence
          </button>

          {googleMapsLoaded && (
            <MapSearchBox map={map} mapApi={mapApi} addplace={addPlace} />
          )}
        </Columns>

        <div
          style={{ width: '100%', height: '400px' }}
          className={'md-elevation-z6'}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyC2M5Q4tBg_vWDT1rsw59Jse5E5PDwXnlA',
              libraries: ['places'],
            }}
            yesIWantToUseGoogleMapApiInternals
            defaultCenter={{ lat: -33.87418, lng: 151.215595 }} // TODO: this should just be the center point of the venue that is logged in
            defaultZoom={11}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            onClick={clearSelected}
          >
            {/*{fenceMarkers}*/}
          </GoogleMapReact>
        </div>

        {selectedPolygon != undefined && (
          <div
            style={{
              color: 'black',
              padding: 12,
              margin: 28,
              borderRadius: 36,
              textAlign: 'center',
              fontSize: 19,
              backgroundColor: '#343A40',
            }}
            className={'flex-cen-stack md-elevation-z4'}
          >
            <div css={{ color: 'white', marginBottom: 12 }}>
              Currently Selected Fence
            </div>

            <Rows>
              <input
                css={{ marginRight: 20, width: 450 }}
                className={'notification-input-container ta-title'}
                name="title"
                value={selectedPolygon.updatedName}
                placeholder={'Add geofence name'}
                onChange={(event: any) => handleGeofenceNameChange(event)}
              />

              <button
                className={'btn btn-primary btn-red flex-cen-horizontal'}
                onClick={deleteSelected}
              >
                DELETE FENCE
              </button>
            </Rows>
          </div>
        )}

        {pageLoaded && (
          <div
            style={{ color: 'black', marginBottom: 20 }}
            className={'flex-cen-stack'}
          >
            <div
              css={css({ margin: 32, fontSize: 22 })}
              className={'col-gold-main'}
            >
              PENDING FENCE UPDATES
            </div>

            {venueGeofences.filter((fence) => fence.fenceStatus != undefined)
              .length == 0 && (
              <div className={'col-gold-main'}>No fence updates detected</div>
            )}

            {venueGeofences
              .filter((fence) => fence.fenceStatus != undefined)
              .map((fence) => {
                return (
                  <div
                    className={'md-elevation-z4 flex-cen-horizontal'}
                    css={css({
                      color: 'white',
                      padding: 12,
                      backgroundColor: '#343A40',
                      marginBottom: 8,
                      borderRadius: 8,
                    })}
                  >
                    <div css={css({ minWidth: 400 })}>{fence.updatedName}</div>

                    <div
                      css={css({
                        padding: 8,
                        backgroundColor:
                          fence.fenceStatus == 'UPDATED' ? 'green' : 'blue',
                      })}
                    >
                      FENCE: {fence.fenceStatus}
                    </div>

                    <button
                      className={'btn btn-primary flex-cen-horizontal'}
                      css={css({ margin: '8px 8px' })}
                      onClick={() => saveFence(fence)}
                    >
                      Save
                    </button>

                    <button
                      className={'btn btn-primary btn-red flex-cen-horizontal'}
                      // onClick={() => fence.fenceStatus == "NEW" ? deleteNew(fence) : resetFence(fence)}
                    >
                      {fence.fenceStatus == 'NEW' ? 'Delete' : 'Undo'}
                    </button>
                  </div>
                );
              })}
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <PageContainer
      breadcrumbs={[
        { name: 'Home', route: '/home' },
        { name: 'Geofence Creator', route: '/admin/geo-creator' },
      ]}
    >
      <PageHeader title={'Geofence Protection Creator'} />

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: '40px 0',
          width: '100%',
        }}
      >
        {getContent()}
      </div>
    </PageContainer>
  );
});
