// @ts-nocheck
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { DataStore, SummaryStore, UserStore } from '../../../index';
import { useHistory } from 'react-router';
import { reaction, runInAction, when } from 'mobx';
import { Colors } from '../../../Theme';
import ApiClient from '../../../ApiClient';
import { Columns, Rows } from '../../UiKit';
import { PageContainer, PageHeader } from '../../ui/PageComponents';
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { PELineGraph } from '../../graphs/PELineGraph';
import { PELineGraphZoneEntry } from '../../graphs/PELineGraphZoneEntry';

const cardCss = css({ marginBottom: 24, cursor: 'pointer' });

type VenueGeofence = {
  locationId: string;
  name: string;
  coordinates: { lat: number; lng: number }[];
  centerLat: number;
  centerLng: number;
};

// C# vars on html
declare var google: any;

export const GeoSafetyAdminPage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();

  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [map, setMap] = useState<any>(null);
  const [venueGeofences, setVenueGeofences] = useState<VenueGeofence[]>([]);

  useEffect(() => {
    when(
      () => userStore.selectedVenue === undefined || !userStore.isAdmin,
      () => {
        history.push('/login');
      }
    );

    reaction(
      () => userStore.isLoggedIn,
      (isLoggedIn) => {
        if (!isLoggedIn) {
          history.push('/login');
          return;
        }
      },
      {
        fireImmediately: true,
      }
    );
  }, []);

  const handleApiLoaded = (loadedMap: any, loadedMapApi: any) => {
    setMap(loadedMap);
  };

  useEffect(() => {
    if (map) {
      setGoogleMapsLoaded(true);

      loadFenceData();
    }
  }, [map]);

  useEffect(() => {
    if (venueGeofences.length > 0) drawGeofencesOnMap();
  }, [venueGeofences]);

  const loadFenceData = () => {
    ApiClient.getAllGeofences().then((response: any) => {
      let fenceData: VenueGeofence[] = [];

      response.data.geofences.forEach((fence: any) => {
        let coordsGeoJson: any[] = [];
        let coordsRaw: [number, number][] = [];

        fence.coordinates.forEach((coord: any) => {
          coordsGeoJson.push({ lat: coord.latitude, lng: coord.longitude });
          coordsRaw.push([coord.latitude, coord.longitude]);
        });
        fence.coordinates = coordsGeoJson;

        let center = getGeoCentroid(coordsRaw);

        fence.centerLat = center.lat();
        fence.centerLng = center.lng();

        fenceData.push(fence);
      });

      setVenueGeofences(fenceData);
    });
  };

  const drawGeofencesOnMap = () => {
    console.log(venueGeofences);

    console.log('Calculate: ' + map);

    if (map == null) return;

    console.log('this.venueGeofences: ' + venueGeofences.length);

    venueGeofences.forEach((geofence) => {
      console.log(geofence);

      let coords = geofence.coordinates.slice(0, geofence.coordinates.length);
      console.log('fence coords is: ' + coords);
      console.log(coords);

      let style = new google.maps.Polygon({
        paths: coords,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
      });
      style.setMap(map);
    });
  };

  const onMarkerClicked = (geofence: VenueGeofence) => {
    console.log('marker clicked for ' + geofence.name);
  };

  const getGeoCentroid = (coords: any) => {
    // var coords = [
    //     [ -1.2, 5.1 ],
    //     [ -1.3, 5.2 ],
    //     [ -1.8, 5.9 ],
    //     [ -1.9, 5.8 ]
    // ];

    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < coords.length; i++) {
      bounds.extend(new google.maps.LatLng(coords[i][0], coords[i][1]));
    }
    return bounds.getCenter();
  };

  // Map the fences to map markers
  const fenceMarkers = venueGeofences.map((fence) => {
    return (
      <GeofenceMapMarker
        lat={fence.centerLat}
        lng={fence.centerLng}
        geofence={fence}
        onClick={() => onMarkerClicked(fence)}
      />
    );
  });

  return (
    <PageContainer
      breadcrumbs={[
        { name: 'Home', route: '/home' },
        { name: 'Geofence Safety Setup', route: '/admin/geo-safety' },
      ]}
    >
      <PageHeader title={'Ok2Play Geofence Protection'} />

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '40px 0',
          width: '100%',
        }}
      >
        <Rows
          css={{
            border: '1px solid grey',
            borderRadius: 20,
            padding: '12px 18px',
            marginBottom: 30,
          }}
          className={'md-elevation-z4'}
        >
          <FontAwesomeIcon
            icon={faCheck}
            css={{
              fontSize: 40,
              color: Colors.GreenMatte,
            }}
          />

          <div
            css={{ fontSize: 20, marginLeft: 20 }}
            className={'col-gold-main'}
          >
            0 excluded people currently in Safe Zones
          </div>
        </Rows>

        <div css={{ fontSize: 22 }} className={'col-gold-main'}>
          Monitoring {venueGeofences.length} Locations for Player Protection
        </div>

        <div css={{ fontSize: 18, marginTop: 12 }} className={'col-gold-main'}>
          Locations
        </div>

        <div
          css={{ color: 'black', marginBottom: 20, fontSize: 13 }}
          className={'flex-cen-stack'}
        >
          {venueGeofences.map((fence) => {
            return <div>{fence.name}</div>;
          })}
        </div>

        <div
          style={{ width: '100%', height: '400px', marginBottom: 44 }}
          className={'md-elevation-z6'}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyC2M5Q4tBg_vWDT1rsw59Jse5E5PDwXnlA',
            }}
            defaultCenter={{ lat: -33.87418, lng: 151.215595 }} // TODO: this should just be the center point of the venue that is logged in
            defaultZoom={11}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            {fenceMarkers}
          </GoogleMapReact>
        </div>

        <PELineGraphZoneEntry
          chartTitle={'Protected User Zone Entries'}
          itemHexColour={Colors.BlueMatte}
        />
      </div>
    </PageContainer>
  );
});

type MapGeofence = {
  lat: number;
  lng: number;
  geofence: VenueGeofence;
  onClick: () => void;
};

export const GeofenceMapMarker = (props: MapGeofence) => (
  <div
    style={{
      // fontSize: '10px',
      color: 'white',
      background: 'dodgerblue',
      padding: '8px 5px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)',
    }}
    onClick={props.onClick}
  >
    {props.geofence.name}
  </div>
);
