import { createContext } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import DataState, { dataState } from './state/DataState';
import UserState, { userState } from './state/UserState';
import ApiClient from './ApiClient';
import SummaryState, { summaryState } from './state/SummaryState';
import './app.global.scss';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;
const rootElement = document.getElementById('root');

export const DataStore = createContext<DataState>(dataState);
export const UserStore = createContext<UserState>(userState);
export const SummaryStore = createContext<SummaryState>(summaryState);

export const apiBaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://api-prod.ok2play-dev.com/api'
    : 'https://api-prod.ok2play-dev.com/api';

ApiClient.init(apiBaseUrl, userState);

ReactDOM.render(
  <UserStore.Provider value={userState}>
    <SummaryStore.Provider value={summaryState}>
      <BrowserRouter basename={baseUrl}>
        <App />
      </BrowserRouter>
    </SummaryStore.Provider>
  </UserStore.Provider>,
  rootElement
);
