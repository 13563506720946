/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, {Component} from "react";

type MapSearchBoxProps = {
    map: any;
    mapApi: any;
    addplace: (place: any) => void;
}

export default class MapSearchBox extends Component<MapSearchBoxProps> {
    private map: any = this.props.map;
    private mapApi: any = this.props.mapApi;
    private searchInputRef = React.createRef<HTMLInputElement>();
    private searchBox: any;
    
    componentDidMount() {
        console.log("ZZ did mount.. places api is: " + this.mapApi.places);
        console.log(this.mapApi.places);
        
        let places = this.mapApi.places;
        
        this.searchBox = new places.SearchBox(this.searchInputRef.current!);
        this.searchBox.addListener('places_changed', this.onPlacesChanged);
        this.searchBox.bindTo('bounds', this.map);
    }

    componentWillUnmount() {
        this.mapApi.event.clearInstanceListeners(this.searchInputRef.current!);
    }

    onPlacesChanged = () => {
        console.log("onPlacesChanged");
        
        const selected = this.searchBox.getPlaces();
        const { 0: place } = selected;
                
        if (!place.geometry)
            return;
        
        if (place.geometry.viewport) {
            this.map.fitBounds(place.geometry.viewport);
        } else {
            this.map.setCenter(place.geometry.location);
            this.map.setZoom(6);
        }

        this.props.addplace(place);

        this.searchInputRef.current!.blur();
    };

    render() {
        return <div>
                <input
                    css={css({padding: '8px 12px', margin: 20, borderRadius: 8})}
                    ref={this.searchInputRef}
                    type={"text"}
                    // onFocus={this.clearSearchBox}
                    placeholder={"Zoom to an Address"}
                />
            </div>
    }
}