import { useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { SummaryStore, UserStore } from '../../../index';
import { useHistory } from 'react-router';
import { reaction } from 'mobx';
import {
  PageContainer,
  PageLoader,
  PageSubHeader,
} from '../../ui/PageComponents';
import moment from 'moment/moment';
import { CaseCardContainer } from './case-card-container';
import React from 'react';
import ApiClient from '../../../ApiClient';

export const CasePage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const pollingTimer = useRef<NodeJS.Timeout>();
  const [casesData, setCasesData] = React.useState<{
    todaysRequests: any[];
    previousDayRequests: any[];
    last28DaysRequests: any[];
  }>({
    todaysRequests: [],
    previousDayRequests: [],
    last28DaysRequests: [],
  });

  const fetchIncidents = () => {
    ApiClient.getIncidentsOverview()
      .then((response: any) => {
        setCasesData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reaction(
      () => [userStore.isLoggedIn, userStore.selectedVenue],
      (values) => {
        const isLoggedIn = values[0];
        const selectedVenue = values[1];

        if (!isLoggedIn || !selectedVenue) {
          history.push('/login');
        } else {
          fetchIncidents();
        }
      },
      {
        fireImmediately: true,
      }
    );

    return () => {
      if (pollingTimer.current) clearInterval(pollingTimer.current);
    };
  }, []);

  useEffect(() => {
    // Initial fetch
    fetchIncidents();
    // Set up interval to fetch every 10 seconds
    const intervalId = setInterval(fetchIncidents, 10000);
    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <PageContainer
      breadcrumbs={[
        { name: 'Home', route: '/home' },
        { name: 'Cases', route: '/cases' },
      ]}
    >
      <div style={{ fontSize: 26, textAlign: 'center', marginBottom: 16 }}>
        Cases since {moment(summaryStore.caseSearchFilterTime).format('h:mm a')}{' '}
        today: {casesData.todaysRequests.length}
      </div>

      {casesData.todaysRequests.length === 0 ? (
        <PageSubHeader title={'There are no cases today'} />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {casesData.todaysRequests.map((request, index) => (
            <div key={index} className="m-2">
              <CaseCardContainer case={request as any} />
            </div>
          ))}
        </div>
      )}

      <div
        style={{
          fontSize: 26,
          textAlign: 'center',
          marginTop: 30,
          marginBottom: 16,
        }}
      >
        Automatically Closed Cases: {casesData.previousDayRequests.length}
      </div>

      {casesData.previousDayRequests.length === 0 ? (
        <PageSubHeader title={'There are no automatically closed cases'} />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {casesData.previousDayRequests.map((request, index) => (
            <div key={index} className="m-2">
              <CaseCardContainer case={request as any} />
            </div>
          ))}
        </div>
      )}

      <div
        style={{
          fontSize: 26,
          textAlign: 'center',
          marginTop: 30,
          marginBottom: 16,
        }}
      >
        Last 14 Days: {casesData.last28DaysRequests.length}
      </div>

      {casesData.last28DaysRequests.length === 0 ? (
        <PageSubHeader title={'There are no cases from the last 14 Days'} />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {casesData.last28DaysRequests.map((request, index) => (
            <div key={index} className="m-2">
              <CaseCardContainer case={request as any} />
            </div>
          ))}
        </div>
      )}
    </PageContainer>
  );
});
