import { UserType } from '../state/UserState';
import { Config } from '../Config';

class UserStore {
  MIGRATION_KEY = 'userMigrationKey';
  STORAGE_KEY = 'ok2pUser';

  migrateStore() {
    let needsMigration =
      localStorage.getItem(this.MIGRATION_KEY) !== Config.buildNumber;

    if (needsMigration) {
      this.clearStore();
      localStorage.setItem(this.MIGRATION_KEY, Config.buildNumber);
    }

    return needsMigration;
  }

  getUser() {
    let user = localStorage.getItem(this.STORAGE_KEY);

    if (user == null) return null;

    return JSON.parse(user);
  }

  // getVenueId() {
  //     let user = localStorage.getItem(this.STORAGE_KEY);
  //
  //     if (user == null)
  //         return null;
  //
  //     return JSON.parse(user).venueId;
  // }

  // getUserId() {
  //     let user = localStorage.getItem(this.STORAGE_KEY);
  //
  //     if (user == null)
  //         return null;
  //
  //     return JSON.parse(user).userId;
  // }

  storeUser(user: UserType) {
    console.log('Store User');
    console.log(user);

    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(user));
  }

  // getLastLoginTime() {
  //     return localStorage.getItem(this.LAST_LOGIN_TIME_KEY);
  // }

  clearStore() {
    localStorage.removeItem(this.STORAGE_KEY);
  }
}

export default new UserStore();
