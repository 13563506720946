import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  HelpRequestActionLogItem,
  UserHelpRequests,
} from '../../../state/SummaryState';
import { Colors } from '../../../Theme';
import { Rows } from '../../UiKit';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { PaperClipIcon, PhotoIcon } from '@heroicons/react/20/solid';
import ApiClient from '../../../ApiClient';
import { RedBallLoader } from '../../ui/Loading';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type Props = {
  case: UserHelpRequests;
  requestActionLogs: HelpRequestActionLogItem[];
};

export default function CaseDetailsTabs(props: Props) {
  const [file, setFile] = React.useState<File | null>(null);
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const caseLogCount = props?.requestActionLogs?.length;
  const attachmentsCount = props?.case?.attachments?.length ?? 0;
  const notesCount = props?.case.notes?.length ?? 0;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleUpload = () => {
    if (!file) return;

    setLoading(true);

    ApiClient.uploadAttachmentInCaseDetails({
      helpRequestId: props.case.helpRequestId,
      file: file,
    })
      .then((res) => {
        if (res) {
          setFile(null);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="max-w-xl text-center overflow-auto bg-white border border-gray-200 rounded-xl hover:shadow-xl transition-all dark:bg-gray-800 dark:border-gray-700">
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          className="w-full"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="w-full"
          >
            <Tab label={`Case Logs (${caseLogCount})`} {...a11yProps(0)} />
            <Tab
              label={`Attachments (${attachmentsCount})`}
              {...a11yProps(1)}
            />
            <Tab label={`Notes (${notesCount})`} {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {props?.requestActionLogs?.map((actionLog, index) => {
            return (
              <div
                className={
                  index > 0 ? 'border-t border-gray-200 mt-2 pt-2' : ''
                }
                css={{
                  background: Colors.VeryLightGrey,
                  margin: 6,
                  padding: 12,
                }}
                key={'cdpral' + actionLog.requestActionLogId}
              >
                {actionLog.actionDisplayText != null && (
                  <Rows
                    style={{
                      width: 500,
                      padding: 4,
                      opacity: 0.75,
                      fontSize: 15,
                    }}
                  >
                    {actionLog.actionDisplayText}
                  </Rows>
                )}

                <Rows
                  style={
                    actionLog.actionStatus == 'REOPENED'
                      ? { width: 500, marginTop: 8 }
                      : { width: 500 }
                  }
                >
                  <div
                    className="font-bold text-lg italic"
                    style={
                      actionLog.actionStatus == 'CLOSE_CASE'
                        ? { color: Colors.GreenMatte }
                        : {}
                    }
                  >
                    {actionLog.actionStatusDisplayText}
                  </div>

                  <div
                    style={{ marginLeft: 'auto', fontSize: 15, color: 'gray' }}
                  >
                    {moment(actionLog.timeCreated).format(
                      'h:mm:ss a, dddd, MMMM Do YYYY'
                    )}
                  </div>
                </Rows>

                <Rows
                  style={{
                    width: 500,
                    textAlign: 'center',
                    padding: 12,
                    opacity: 0.7,
                    fontSize: 15,
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {actionLog.actionNote}
                </Rows>
              </div>
            );
          })}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {' '}
          <dt className="text-sm font-medium leading-6 text-gray-900">
            Attachments
          </dt>
          <div className="">
            {props?.case?.attachments?.map((item, index) => (
              <dd
                key={`attachment-${index}`}
                className="w-full mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
              >
                <ul
                  role="list"
                  className="divide-y divide-gray-100 rounded-md border border-gray-200"
                >
                  <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <div className="ml-4 flex min-w-0 flex-1 gap-2 text-left">
                        <img
                          src={item.incidentDescription}
                          className="w-20 h-20 rounded-lg object-cover"
                          alt="some file"
                        />
                      </div>
                    </div>
                    <div className="ml-4  text-left cursor-pointer flex-shrink-0">
                      <p className="flex flex-col truncate font-medium">
                        <span className="text-sm text-gray-500">
                          {moment(item.timeCreated).format(
                            'h:mm:ss a, dddd, MMMM Do YYYY'
                          )}
                        </span>
                      </p>{' '}
                      <a
                        href={item.incidentDescription}
                        target="_blank"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Open
                      </a>
                    </div>
                  </li>
                </ul>
              </dd>
            ))}
          </div>
          <div className="mt-2">
            <div className="col-span-full">
              {loading ? (
                <div className="flex items-center mt-4 justify-center">
                  <RedBallLoader />
                </div>
              ) : (
                <div className="mt-2 rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    {file && (
                      <div className="border-b mb-4 pb-2">
                        <div className="flex items-center justify-center border p-4 mb-10 shadow-sm">
                          <img
                            src={URL.createObjectURL(file)}
                            alt="file"
                            className="w-80 h-80 object-cover rounded-lg"
                          />
                        </div>
                        <div className="ml-2 pb-2 border-gray-200 flex gap-2 items-center justify-center">
                          <button
                            onClick={handleUpload}
                            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#eb4c2d] rounded-full hover:opacity-75 transition-all  focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          >
                            Upload now
                          </button>
                        </div>
                      </div>
                    )}
                    <PhotoIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="w-max text-center relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                              setFile(e.target.files[0]);
                            }
                          }}
                        />
                      </label>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {props?.case?.notes?.map((item, index) => {
            return (
              <div
                className={
                  index > 0 ? 'border-t border-gray-200 mt-2 pt-2' : ''
                }
                css={{
                  background: Colors.VeryLightGrey,
                  margin: 6,
                  padding: 12,
                }}
                key={'cdpral' + item?.helpRequestId}
              >
                <Rows>
                  <div
                    style={{ fontSize: 15, color: 'gray' }}
                    className="text-left gap-2 flex flex-col"
                  >
                    <span className="whitespace-pre-wrap text-sm text-gray-900 font-normal">
                      notes:{' '}
                      <p className="italic">
                        {item.incidentDescription.toLowerCase()}
                      </p>
                    </span>
                  </div>

                  <div
                    style={{ marginLeft: 'auto', fontSize: 15, color: 'gray' }}
                  >
                    {moment(item.timeCreated).format(
                      'h:mm:ss a, dddd, MMMM Do YYYY'
                    )}
                  </div>
                </Rows>
              </div>
            );
          })}
        </CustomTabPanel>
      </Box>
    </div>
  );
}
