import { Component, ReactElement } from 'react';
import styled from '@emotion/styled';
import type {
  DropResult,
  DraggableLocation,
  DroppableProvided,
} from '@hello-pangea/dnd';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import type {
  OutputData,
  QuoteMap,
  Quote,
  PartialAutoScrollerOptions,
} from './types';

import reorder, { reorderQuoteMap } from './reorder';
import Column from './column';
import ApiClient from '../../ApiClient';

interface ParentContainerProps {
  height: string;
}

const Container = styled.div`
  background-color: white;
  min-height: 100vh;
  /* like display:flex but will allow bleeding over the window width */
  min-width: 100vw;
  display: inline-flex;
`;

interface Props {
  getUpdatedIncidentReasons: () => void;
  initial: QuoteMap;
  withScrollableColumns?: boolean;
  isCombineEnabled?: boolean;
  containerHeight?: string;
  useClone?: boolean;
  applyGlobalStyles?: boolean;
  autoScrollerOptions?: PartialAutoScrollerOptions;
  IncidentReasonCategory: any;
}

interface State {
  columns: QuoteMap;
  ordered: string[];
}

export default class Board extends Component<Props, State> {
  /* eslint-disable react/sort-comp */
  static defaultProps = {
    isCombineEnabled: false,
    applyGlobalStyles: true,
  };

  state: State = {
    columns: this.props.initial,
    ordered: this.props.IncidentReasonCategory.sort(
      (a: { sortOrder: number }, b: { sortOrder: number }) =>
        a.sortOrder - b.sortOrder
    ).map((item: { categoryName: any }) => item.categoryName),
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.initial !== this.props.initial) {
      this.setState({
        columns: this.props.initial,
        ordered: this.props.IncidentReasonCategory.sort(
          (a: { sortOrder: number }, b: { sortOrder: number }) =>
            a.sortOrder - b.sortOrder
        ).map((item: { categoryName: any }) => item.categoryName),
      });
    }
  }

  handleUpdateIncidentReasons(data: OutputData[]) {
    if (data.length === 0) {
      return;
    }

    ApiClient.updateIncidentSortings(data);
  }

  onDragEnd = (result: DropResult): void => {
    if (result.combine) {
      if (result.type === 'COLUMN') {
        const shallow: string[] = [...this.state.ordered];
        shallow.splice(result.source.index, 1);
        this.setState({ ordered: shallow });
        return;
      }

      const column: Quote[] = this.state.columns[result.source.droppableId];
      const withQuoteRemoved: Quote[] = [...column];
      withQuoteRemoved.splice(result.source.index, 1);
      const columns: QuoteMap = {
        ...this.state.columns,
        [result.source.droppableId]: withQuoteRemoved,
      };
      this.setState({ columns });
      return;
    }

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source: DraggableLocation = result.source;
    const destination: DraggableLocation = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // reordering column
    if (result.type === 'COLUMN') {
      const ordered: string[] = reorder(
        this.state.ordered,
        source.index,
        destination.index
      );

      // Create a mapping from categoryName to the original object
      const categoryMap: {
        [key: string]: {
          categoryId: any;
          categoryName: string | number;
        };
      } = {};
      this.props.IncidentReasonCategory.forEach(
        (item: { categoryName: string; categoryId: number }) => {
          categoryMap[item.categoryName] = {
            categoryId: item.categoryId,
            categoryName: item.categoryName,
          };
        }
      );

      // Build the request data based on the new sorted columns
      const requestData = ordered.map((categoryName, index) => {
        const item = categoryMap[categoryName];
        return {
          CategoryId: item.categoryId,
          CategoryName: item.categoryName as string,
          SortOrder: index + 1, // New sort order based on the new sorted columns
        };
      });

      ApiClient.updateSortOrderIncidentReasonCategory(requestData);

      this.setState({
        ordered,
      });

      return;
    }

    const data = reorderQuoteMap({
      quoteMap: this.state.columns,
      source,
      destination,
    });

    this.setState({
      columns: data.quoteMap,
    });

    // save new sorted incident reasons
    this.handleUpdateIncidentReasons(this.transformData(data));
  };

  transformData(inputData: { quoteMap: QuoteMap }): OutputData[] {
    const output: OutputData[] = [];

    for (const key in inputData.quoteMap) {
      if (inputData.quoteMap.hasOwnProperty(key)) {
        const quotes =
          inputData.quoteMap[key as keyof typeof inputData.quoteMap];

        quotes.forEach((quote, index) => {
          output.push({
            Id: Number(quote.venueStringSettingId),
            Category: key,
            ListType: quote.listType,
            SortOrder: index + 1,
          });
        });
      }
    }

    return output;
  }
  render(): ReactElement {
    const columns: QuoteMap = this.state.columns;
    const ordered: string[] = this.state.ordered;
    const {
      containerHeight,
      useClone,
      isCombineEnabled,
      withScrollableColumns,
      applyGlobalStyles,
    } = this.props;

    const board = (
      <Droppable
        droppableId="board"
        type="COLUMN"
        direction="horizontal"
        ignoreContainerClipping={Boolean(containerHeight)}
        isCombineEnabled={isCombineEnabled}
        isDropDisabled={false}
      >
        {(provided: DroppableProvided) => (
          <Container ref={provided.innerRef} {...provided.droppableProps}>
            {ordered.map((key: string, index: number) => (
              <Column
                getUpdatedIncidentReasons={this.props.getUpdatedIncidentReasons}
                key={key}
                index={index}
                title={key}
                quotes={columns[key]}
                isScrollable={withScrollableColumns}
                isCombineEnabled={isCombineEnabled}
                useClone={useClone}
              />
            ))}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    );

    return (
      <div className="w-[103%] mt-4 overflow-x-auto overflow-y-auto border">
        <DragDropContext
          onDragEnd={this.onDragEnd}
          autoScrollerOptions={this.props.autoScrollerOptions}
        >
          {board}
        </DragDropContext>
      </div>
    );
  }
}
