import { Accordion } from 'flowbite-react';
type Props = {
  data: any[];
  columnsState: any[];
  setColumnsState: any;
};
export default function FilterContainer({
  data,
  columnsState,
  setColumnsState,
}: Props) {
  if (data) {
    return (
      <Accordion collapseAll className="mt-4">
        <Accordion.Panel>
          <Accordion.Title>Change Filter</Accordion.Title>
          <Accordion.Content>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 w-full">
              {columnsState.map((column: any, i: number) => (
                <div key={i} className="flex flex-col">
                  <MySwitchComponent
                    name={column.name}
                    show={column.show}
                    onChange={(value) => {
                      const updatedColumnsState = [...columnsState];
                      updatedColumnsState[i].show = value;
                      setColumnsState(updatedColumnsState);
                    }}
                  />
                </div>
              ))}
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    );
  }

  return <></>;
}

const MySwitchComponent = ({
  name,
  show,
  onChange,
}: {
  name: string;
  show: boolean;
  onChange: (value: boolean) => void;
}) => {
  return (
    <label className="inline-flex items-center mb-2 cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={show}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
      <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
        {name}
      </span>
    </label>
  );
};
