import moment from 'moment';
import { Key } from 'react';

export type DataTableProps = {
  data: {
    searchCount: number;
    searchResults: {
      [x: string]: any;
      timeHeader: string;
      sortIndex: string;
      userHelpRequests: {
        helpRequestId: number;
        resultType: string;
        actingFromSource: string;
        requestSource: string;
        actingStaffUserId: number;
        actingStaffMemberName: string;
        content: string;
        status: string;
        notes: string;
        personDescription: string;
        requesterName: string;
        siteLocation: string;
        machineNumber: string;
        timeCreated: number;
      }[];
    };
    success: boolean;
  };
  visibleColumns: any[];
  handleOnClick: (data: any) => void;
};

const DataTable = (DataTableprops: DataTableProps) => {
  const { data, visibleColumns, handleOnClick } = DataTableprops;

  // Sort by date (earliest to latest)
  const sortedData = data?.searchResults?.sort(
    (a: { startUnixDate: number }, b: { startUnixDate: number }) =>
      b.startUnixDate - a.startUnixDate
  );

  const searchResults = sortedData;

  const handleRow = (
    row: any,
    rowIndex: Key | null | undefined,
    visibleColumns: any,
    handleOnClick: (data: any) => void
  ) => {
    const startDate = moment(row.startUnixDate).format('h:mma Do MMMM YYYY');
    const endDate = moment(row.endUnixDate).format('h:mma Do MMMM YYYY');

    return (
      <div>
        <p className="my-2 text-2xl font-semibold text-gray-800">
          {`${startDate} to ${endDate}`}
        </p>
        {row?.userHelpRequest?.length > 0 ? (
          row.userHelpRequest.map(
            (helpRequest: any, helpRequestIndex: number) => (
              <SearchTime
                rowIndex={rowIndex}
                row={row}
                key={helpRequestIndex}
                helpRequest={helpRequest}
                helpRequestIndex={helpRequestIndex}
                visibleColumns={visibleColumns}
                handleOnClick={handleOnClick}
              />
            )
          )
        ) : (
          <p className="mb-12 mt-4 text-xl text-center font-semibold text-gray-800">
            There are no incidents recorded for this day
          </p>
        )}
      </div>
    );
  };

  return (
    <div>
      {searchResults?.map((row: any, rowIndex: Key | null | undefined) => (
        <div key={rowIndex} className="my-2">
          {row?.userHelpRequest ? (
            handleRow(row, rowIndex, visibleColumns, handleOnClick)
          ) : (
            <div className="my-4">
              <div className="my-4" key={row?.helpRequestId}>
                <div className="flex h-10 align-centerw-full gap-4 items-center justify-between">
                  <div>
                    <p className="text-xl font-semibold text-gray-800">
                      Case number: {row?.helpRequestId}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <p>Status:</p>
                    <p
                      className="text-sm text-white p-1 w-64 text-center"
                      style={{
                        backgroundColor:
                          row?.status === 'COMPLETE' ? 'green' : 'red',
                      }}
                    >
                      {row?.status}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <p>Date and Time:</p>
                    <p className="text-md">
                      {moment(row?.timeCreated).format(
                        'hh:mm a dddd, MMMM DD, YYYY'
                      )}
                    </p>
                  </div>
                </div>

                <div key={rowIndex} className="relative overflow-x-auto border">
                  <table className="w-full text-sm text-left">
                    <thead className="text-xs text-white uppercase bg-black ">
                      <tr>
                        {visibleColumns.map((column: any, index: number) => (
                          <th key={index} scope="col" className="px-6 py-2">
                            {column.name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        key={rowIndex}
                        className="w-full hover:cursor-pointer bg-gray-200 border-b  hover:bg-gray-100 dark:hover:bg-gray-700 hover:shadow-lg transition duration-150 ease-in-out"
                        onClick={() =>
                          handleOnClick({
                            searchResults: [row],
                          })
                        }
                      >
                        {visibleColumns.map((column: any, colIndex: number) => (
                          <td key={colIndex} className=" px-6 py-4">
                            {column.render(
                              column.key === 'resultType'
                                ? {
                                    isModal: false,
                                    value: row[column.key],
                                  }
                                : row[column.key]
                            )}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DataTable;

const SearchTime = ({
  rowIndex,
  row,
  helpRequest,
  helpRequestIndex,
  visibleColumns,
  handleOnClick,
}: {
  rowIndex: Key | null | undefined;
  row: any;
  helpRequest: any;
  helpRequestIndex: number;
  visibleColumns: any;
  handleOnClick: (data: any) => void;
}) => {
  return (
    <div className="my-4" key={helpRequestIndex}>
      <div className="flex h-10 align-centerw-full gap-4 items-center justify-between">
        <div>
          <p className="text-xl font-semibold text-gray-800">
            Case number: {helpRequest.helpRequestId}
          </p>
        </div>
        <div className="flex gap-2">
          <p>Status:</p>
          <p
            className="text-sm text-white p-1 w-64 text-center"
            style={{
              backgroundColor:
                helpRequest.status === 'COMPLETE' ? 'green' : 'red',
            }}
          >
            {helpRequest.status}
          </p>
        </div>
        <div className="flex gap-2">
          <p>Date and Time:</p>
          <p className="text-md">
            {moment(helpRequest.timeCreated).format(
              'hh:mm a dddd, MMMM DD, YYYY'
            )}
          </p>
        </div>
      </div>

      <div key={rowIndex} className="relative overflow-x-auto border">
        <table className="w-full text-sm text-left">
          <thead className="text-xs text-white uppercase bg-black ">
            <tr>
              <th scope="col" className="px-6 py-2">
                case number
              </th>
              {visibleColumns.map((column: any, index: number) => (
                <th key={index} scope="col" className="px-6 py-2">
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr
              key={rowIndex}
              className="w-full hover:cursor-pointer bg-gray-200 border-b  hover:bg-gray-100 dark:hover:bg-gray-700 hover:shadow-lg transition duration-150 ease-in-out"
              onClick={() =>
                handleOnClick({
                  searchResults: [
                    {
                      userHelpRequests: [helpRequest],
                    },
                  ],
                })
              }
            >
              <td className="px-6 py-4">{helpRequest.helpRequestId} </td>
              {visibleColumns.map((column: any, colIndex: number) => (
                <td key={colIndex} className=" px-6 py-4">
                  {column.render(
                    column.key === 'resultType'
                      ? { isModal: false, value: helpRequest[column.key] }
                      : helpRequest[column.key]
                  )}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
