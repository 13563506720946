import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';

type Props = {
  data: any;
  handleExport: (type: any) => void;
};

export default function ExportButtonContainer({ data, handleExport }: Props) {
  return (
    <div className="flex w-full gap-4 items-center justify-center">
      <h2 className="text-2xl font-semibold text-gray-900 m-0 mr-16">
        {data.searchResults.length > 0 &&
          `Incident Logs Found (${data.searchResults.length})`}
      </h2>

      {data.searchResults.length > 0 && (
        <Fragment>
          <div
            onClick={() => {
              handleExport('excel');
            }}
            className="flex items-center bg-gradient-to-r from-green-700 to-green-800 hover:from-green-800 hover:to-green-900 p-3 rounded-lg shadow-lg transition duration-150 ease-in-out cursor-pointer max-w-xs"
          >
            <span className="text-gray-200 font-medium truncate">
              Download as Excel
            </span>
            <FontAwesomeIcon
              icon={faFileExcel}
              fontSize="20px"
              className="text-gray-200 ml-2"
            />
          </div>

          <div
            onClick={() => {
              handleExport('pdf');
            }}
            className="flex items-center bg-gradient-to-r from-red-700 to-red-800 hover:from-red-800 hover:to-red-900 p-3 rounded-lg shadow-lg transition duration-150 ease-in-out cursor-pointer max-w-xs"
          >
            <span className="text-gray-200 font-medium truncate">
              Download as PDF
            </span>
            <FontAwesomeIcon
              icon={faFilePdf}
              fontSize="20px"
              className="text-gray-200 ml-2"
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}
