/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { SummaryStore, UserStore } from '../../../index';
import { useHistory } from 'react-router';
import { reaction } from 'mobx';
import { Card, CardBody, CardSubtitle, CardTitle, NavLink } from 'reactstrap';
import { CheckCircleFill, ExclamationCircleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { PageContainer, PageHeader, PageLoader } from '../../ui/PageComponents';
import { Colors } from '../../../Theme';
import { VenueTimeModal } from '../../modals/VenueTimeModal';
import moment from 'moment';

import SimpleDnd from '../../../components/dnd/simple-dnd';

const cardCss = css({ marginBottom: 24, cursor: 'pointer' });

export const SettingsPage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();

  const [showVenueTimeModal, setShowVenueTimeModal] = useState(false);

  useEffect(() => {
    reaction(
      () => [userStore.isLoggedIn, userStore.selectedVenue],
      (values) => {
        const isLoggedIn = values[0];
        const selectedVenue = values[1];

        if (!isLoggedIn || !selectedVenue) {
          history.push('/login');
        } else {
          summaryStore.refreshHomeData();

          summaryStore.startHomeDataPolling();
        }
      },
      {
        fireImmediately: true,
      }
    );
  }, []);

  if (!summaryStore.dataLoaded) {
    return <PageLoader />;
  }

  return (
    <PageContainer
      breadcrumbs={[
        { name: 'Home', route: '/home' },
        { name: 'Venue Setup', route: '/settings' },
      ]}
    >
      <PageHeader title={'Venue Setup'} />
      <SummaryCard
        title="Responder App Users"
        linkTo="/staff-responder-accounts"
        summaryText="These accounts have access to log in to the Ok2Play Responder App, facilitating service provision and user response."
        itemCount={summaryStore.staffResponderAccounts.length}
        icon={null}
        hasData={summaryStore.staffResponderAccounts.length > 0}
      />
      <SummaryCard
        title="Incident App Users"
        linkTo="/staff-user-accounts"
        summaryText="These accounts have access to log in to the Ok2Play Staff App, enabling the creation of cases for club members."
        itemCount={summaryStore.staffUserAccounts.length}
        icon={null}
        hasData={summaryStore.staffUserAccounts.length > 0}
      />
      <SummaryCard
        title="Setup Incident Reasons"
        linkTo="/incident-reasons"
        summaryText="Add or delete incident reasons from the list."
        itemCount={summaryStore.incidentReasons.length}
        icon={null}
        hasData={summaryStore.incidentReasons.length > 0}
      />
      <SummaryCard
        title="Setup Site Locations"
        linkTo="/site-locations"
        summaryText="Add or delete site locations."
        itemCount={summaryStore.siteLocations.length}
        icon={null}
        hasData={summaryStore.siteLocations.length > 0}
      />
      <SummaryCard
        title="Setup Custom Actions"
        linkTo="/custom-actions"
        summaryText="Add or delete custom mobile actions."
        itemCount={summaryStore.customActions.length}
        icon={null}
        hasData={summaryStore.customActions.length > 0}
      />
      <SummaryCard
        title="Setup Alert Receivers"
        linkTo="/alert-receivers"
        summaryText="These staff members will receive critical alerts and constant updates based on the status of servicing user tickets."
        itemCount={summaryStore.alertReceivers.length}
        icon={null}
        hasData={summaryStore.alertReceivers.length > 0}
      />

      <Card
        css={cardCss}
        onClick={() => setShowVenueTimeModal(true)}
        className="mt-10"
      >
        <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
          <CardTitle tag="h5" style={{ display: 'flex', alignItems: 'center' }}>
            Venue Closing Time:{' '}
            {moment(summaryStore.venueClosingTime).format('h:mm a')}
          </CardTitle>

          <CardSubtitle css={{ fontSize: 15 }}>
            Cases will be moved into the automatically closed area if they are
            still open at the close of business.
          </CardSubtitle>
        </CardBody>
      </Card>

      <VenueTimeModal
        showModal={showVenueTimeModal}
        setShowModal={(show: boolean) => setShowVenueTimeModal(show)}
      />
    </PageContainer>
  );
});

const SummaryCard = ({
  title,
  linkTo,
  summaryText,
  itemCount,
  icon,
  hasData,
}: any) => (
  <Card css={cardCss}>
    <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
      <NavLink tag={Link} to={linkTo} css={{ color: 'black', padding: 0 }}>
        <CardTitle tag="h5" style={{ display: 'flex', alignItems: 'center' }}>
          {title}
          {hasData ? (
            <CheckCircleFill
              size={24}
              color={Colors.GreenMatte}
              style={{ marginLeft: 8 }}
            />
          ) : (
            <ExclamationCircleFill
              size={24}
              color={Colors.RedMatte}
              style={{ marginLeft: 8 }}
            />
          )}
        </CardTitle>

        <CardSubtitle css={{ fontSize: 15 }}>{summaryText}</CardSubtitle>

        <CardSubtitle css={{ marginTop: 4 }}>
          <b>{itemCount}</b> {itemCount === 1 ? 'item' : 'items'}
        </CardSubtitle>
      </NavLink>
    </CardBody>
  </Card>
);
