/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { TrashIcon } from '@heroicons/react/20/solid';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { reaction } from 'mobx';
import { useHistory } from 'react-router';
import { SummaryStore, UserStore } from '../../../index';
import { PageContainer, PageHeader } from '../../ui/PageComponents';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import ApiClient from '../../../ApiClient';
import { Columns, Rows } from '../../UiKit';
import { Colors } from '../../../Theme';
import { CheckCircleFill, TrashFill, XCircleFill } from 'react-bootstrap-icons';
import { Value } from 'react-phone-number-input/input';
import SimpleDnd from '../../dnd/simple-dnd';
import { cloneDeep } from 'lodash';

export type GeneralAnalytics = {
  averageResponseTimeSecs: number;
  unresolvedTicketCount: number;
};

export const SiteLocationsPage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();
  const sortedSiteLocations = cloneDeep(summaryStore.siteLocations).sort(
    (a: any, b: any) => a.sortOrder - b.sortOrder
  );

  useEffect(() => {
    reaction(
      () => [userStore.isLoggedIn, userStore.selectedVenue],
      (values) => {
        const isLoggedIn = values[0];
        const selectedVenue = values[1];

        if (!isLoggedIn || !selectedVenue) {
          history.push('/login');
        } else {
          summaryStore.refreshHomeData();
        }
      },
      {
        fireImmediately: true,
      }
    );
  }, []);

  const [localSiteLocation, setLocalSiteLocation] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [isAddNewMode, setIsAddNewMode] = useState(false);

  useEffect(() => {
    setErrorText(undefined);

    const isValid = localSiteLocation.length > 0;

    setIsFormValid(isValid);
  }, [localSiteLocation]);

  const addSiteLocation = () => {
    setErrorText(undefined);

    if (!isFormValid) {
      setErrorText('You must fill out all fields');
      return;
    }

    ApiClient.addSiteLocation(localSiteLocation)
      .then((response) => {
        setLocalSiteLocation('');
        summaryStore.siteLocations.push(response.data.stringSetting);
      })
      .catch((error) => {});
  };

  const deleteSiteLocation = (id: number) => {
    ApiClient.deleteSiteLocation(id)
      .then((response) => {
        summaryStore.deleteSiteLocation(id);
      })
      .catch((error) => {});
  };

  const handleSort = (data: any) => {
    ApiClient.venueLocationSorting(data)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <PageContainer
      centerContent
      breadcrumbs={[
        { name: 'Home', route: '/home' },
        { name: 'Venue Setup', route: '/settings' },
        { name: 'Setup Site Locations', route: '/site-locations' },
      ]}
    >
      <PageHeader title={'Site Locations'} />

      {isAddNewMode ? (
        <div
          className={'flex-cen-stack'}
          style={{
            width: '55%',
            margin: '16px auto',
            borderRadius: 12,
            backgroundColor: Colors.DarkGrey,
            padding: 12,
          }}
        >
          <div
            style={{
              color: Colors.White,
              fontSize: 18,
              marginBottom: 12,
            }}
          >
            Enter new Site Location
          </div>

          <input
            style={{ width: '100%', padding: 6 }}
            value={localSiteLocation}
            placeholder={'Site Location...'}
            type={'text'}
            onChange={({ target: { value } }) => setLocalSiteLocation(value)}
          />

          {errorText && (
            <div style={{ fontSize: 16, color: Colors.RedMatte }}>
              {errorText}
            </div>
          )}

          <Rows>
            <button
              className="btn"
              type="submit"
              style={{
                backgroundColor: isFormValid
                  ? Colors.GreenMatte
                  : Colors.VeryLightGrey,
                borderColor: isFormValid
                  ? Colors.GreenMatte
                  : Colors.VeryLightGrey,
                color: isFormValid ? Colors.LegoBlack : Colors.DarkGrey,
                marginTop: 12,
                opacity: isFormValid ? 1 : 0.5,
                marginRight: 18,
                // '&:hover': {
                //     color: 'white'
                // }
              }}
              disabled={!isFormValid}
              onClick={addSiteLocation}
            >
              Add
            </button>

            <button
              className="btn"
              type="submit"
              style={{
                backgroundColor: Colors.RedMatte,
                borderColor: Colors.RedMatte,
                color: Colors.White,
                marginTop: 12,
              }}
              onClick={() => setIsAddNewMode(false)}
            >
              Cancel
            </button>
          </Rows>
        </div>
      ) : (
        <button
          className="btn btn-primary"
          type="submit"
          css={{
            width: 400,
            color: Colors.White,
            backgroundColor: Colors.GreenMatte,
            marginTop: 12,
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: Colors.GreenMatte,
              borderColor: 'green',
            },
          }}
          onClick={() => setIsAddNewMode(true)}
        >
          Add New Site Location
        </button>
      )}
      <div className="flex flex-col items-center justify-center w-full h-full">
        <SimpleDnd
          handleSubmit={handleSort}
          items={sortedSiteLocations.map(
            (incidentReason: any, index: { toString: () => any }) => ({
              id: index.toString(),
              content: (
                <div className="flex items-center justify-between p-6 w-[400px] bg-white border border-gray-200 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                  <p className="text-lg font-medium leading-6 text-gray-900">
                    {incidentReason.stringContent}
                  </p>
                  <TrashIcon
                    className="h-5 w-10 flex-shrink-0 text-red-400 hover:cursor-pointer"
                    aria-hidden="true"
                    onClick={() =>
                      deleteSiteLocation(incidentReason.venueStringSettingId!)
                    }
                  />
                </div>
              ),
              incidentReason,
            })
          )}
        />
      </div>
    </PageContainer>
  );
});
