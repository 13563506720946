import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { useState } from 'react';
import { UserHelpRequests } from '../../state/SummaryState';
import { RequestActionTaken } from '../pages/cases/CaseDetailsPage';

import ApiClient from '../../ApiClient';

export type CaseActionModalProps = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  actionCallback: (actionTaken: RequestActionTaken, actionNote: string) => void;
  userHelpRequest: UserHelpRequests;
  customActions: any;
  incidentReasons: any;
};

export const CaseNoteModal = (props: CaseActionModalProps) => {
  const [actionNote, setActionNote] = useState('');

  const saveSelections = () => {
    ApiClient.addActionNote({
      helpRequestId: props.userHelpRequest.helpRequestId,
      KeyNotes: actionNote,
    })
      .then((response) => {
        console.log('Action saved:', response);
        resetModal();
      })
      .catch((error) => {
        console.error('Error saving action:', error);
      });
  };

  const resetModal = () => {
    setActionNote('');
    props.setShowModal(false);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '60%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={props.showModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <form
            className="max-w p-10 mx-auto"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h1 className="text-left text-xl font-semibold text-gray-900 dark:text-white mb-4">
              {`Add Note`}
            </h1>

            <div className="mt-4 border-t">
              <input
                type="text"
                value={actionNote}
                onChange={(e) => setActionNote(e.target.value)}
                placeholder="Add a note"
                className="w-full px-4 py-3 mt-4 text-sm text-gray-900 dark:text-gray-300 dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-900 dark:focus:ring-blue-600 focus:border-transparent dark:focus:border-transparent"
              />
            </div>

            <div className="flex flex-wrap items-center justify-center gap-2 mt-4 mb-2 dark:gap-4">
              <button
                onClick={saveSelections}
                type="button"
                className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Submit
              </button>
              <button
                type="button"
                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                onClick={resetModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};
