const ModalTable = ({
  data,
  visibleColumns,
}: {
  data: any;
  visibleColumns: any;
}) => {
  const handleColumns = (columnsState: any) => {
    return columnsState.filter((column: any) => column.show);
  };

  const columns = handleColumns(visibleColumns);

  // if the status is equal to "ADD_NOTE" put it under "Notes about actions". otherwise it goes to "Other Information"

  const transformedData = data?.map((row: any) => {
    if (row.actionStatus === 'ADD_NOTE') {
      return {
        ...row,
        otherInformation: '',
      };
    }
    return {
      ...row,
      actionNote: '',
      otherInformation: row.actionNote,
    };
  });

  return (
    <div>
      <table className="w-full text-sm text-left">
        <thead className="text-xs text-white uppercase bg-black ">
          <tr>
            {columns.map((column: any, index: number) =>
              index === 0 ? (
                <>
                  <th key={`th-${index}`} scope="col" className="px-6 py-2">
                    Case Number
                  </th>
                  <th scope="col" className="px-6 py-2">
                    {column.name}
                  </th>
                </>
              ) : (
                <th key={`th-${index}`} scope="col" className="px-6 py-2">
                  {column.name}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {transformedData?.length > 0 &&
            transformedData.map((row: any, rowIndex: number) => (
              <tr
                key={`tr-${rowIndex}`}
                className="w-full bg-gray-200 border-b border-1 border-black"
              >
                {/* Display the Case Number */}
                <td className="px-6 py-4">{row.helpRequestId}</td>
                {/* Render the rest of the columns */}
                {columns.map((column: any, colIndex: number) => {
                  return (
                    <td key={`c-${colIndex}`} className="px-6 py-4">
                      {column.render(
                        column.modalKey === 'actionStatus'
                          ? { isModal: true, value: row[column.modalKey] }
                          : row[column.modalKey]
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ModalTable;
