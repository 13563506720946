/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { reaction } from 'mobx';
import { useHistory } from 'react-router';
import { SummaryStore, UserStore } from '../../../index';
import { PageContainer, PageHeader } from '../../ui/PageComponents';
import ApiClient from '../../../ApiClient';
import { Columns, Rows } from '../../UiKit';
import { Colors } from '../../../Theme';
import { CheckCircleFill, TrashFill, XCircleFill } from 'react-bootstrap-icons';
import { LocalStaffAccountType } from './StaffResponderAccountsPage';
import PhoneInput from 'react-phone-number-input/input';
import { Value, isValidPhoneNumber } from 'react-phone-number-input';

export const StaffUserAccountsPage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();

  useEffect(() => {
    reaction(
      () => [userStore.isLoggedIn, userStore.selectedVenue],
      (values) => {
        const isLoggedIn = values[0];
        const selectedVenue = values[1];

        if (!isLoggedIn || !selectedVenue) {
          history.push('/login');
        } else {
          summaryStore.refreshHomeData();
        }
      },
      {
        fireImmediately: true,
      }
    );
  }, []);

  const localInitialState: LocalStaffAccountType = {
    venueStaffUserId: undefined,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    userHasSetup: false,
  };

  const [localStaffAccount, setLocalStaffAccount] = useState(localInitialState);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [isAddNewAccountMode, setIsAddNewAccountMode] = useState(false);

  useEffect(() => {
    setErrorText(undefined);

    const isValid =
      localStaffAccount.firstName.length > 0 &&
      localStaffAccount.lastName.length > 0 &&
      isValidPhoneNumber(localStaffAccount.phoneNumber || '');

    setIsFormValid(isValid);
  }, [localStaffAccount]);

  useEffect(() => {
    setIsPhoneValid(isValidPhoneNumber(localStaffAccount.phoneNumber || ''));
  }, [localStaffAccount.phoneNumber]);

  const addStaffUserAccount = () => {
    setErrorText(undefined);

    if (!isFormValid) {
      setErrorText(
        isPhoneValid
          ? 'You must fill out all fields'
          : 'Phone Number is not Valid'
      );
      return;
    }

    ApiClient.addStaffUserAccount(localStaffAccount)
      .then((response) => {
        setLocalStaffAccount(localInitialState);
        summaryStore.staffResponderAccounts.push(response.data.venueStaffUser);
      })
      .catch((error) => {});
  };

  const deleteStaffUserAccount = (venueStaffUserId: number) => {
    ApiClient.deleteStaffUserAccount(venueStaffUserId)
      .then((response) => {
        summaryStore.deleteStaffAccount(venueStaffUserId);
      })
      .catch((error) => {});
  };

  return (
    <PageContainer
      centerContent
      breadcrumbs={[
        { name: 'Home', route: '/home' },
        { name: 'Venue Setup', route: '/settings' },
        { name: 'Incident App Users', route: '/staff-user-accounts' },
      ]}
    >
      <PageHeader title={'Incident App Users'} />

      {isAddNewAccountMode ? (
        <div
          className={'flex-cen-stack'}
          style={{
            width: '55%',
            margin: '16px auto',
            borderRadius: 12,
            backgroundColor: Colors.DarkGrey,
            padding: 12,
          }}
        >
          <div
            style={{
              color: Colors.White,
              fontSize: 18,
              marginBottom: 12,
            }}
          >
            Enter Staff User Details
          </div>

          <input
            style={{ width: '100%', padding: 6 }}
            value={localStaffAccount.firstName}
            placeholder={'First First Name'}
            type={'text'}
            onChange={({ target: { value } }) =>
              setLocalStaffAccount({
                ...localStaffAccount,
                firstName: value,
              })
            }
          />

          <input
            style={{ width: '100%', padding: 6 }}
            value={localStaffAccount.lastName}
            placeholder={'Enter Last Name'}
            type={'text'}
            onChange={({ target: { value } }) =>
              setLocalStaffAccount({
                ...localStaffAccount,
                lastName: value,
              })
            }
          />

          <div style={{ width: '100%', padding: 6, backgroundColor: 'white' }}>
            <PhoneInput
              defaultCountry={'AU'}
              country={'AU'}
              value={localStaffAccount.phoneNumber as any}
              onChange={(value: Value) =>
                setLocalStaffAccount({
                  ...localStaffAccount,
                  phoneNumber: value ?? '',
                })
              }
              placeholder={'Enter Phone Number'}
            />
          </div>

          {errorText && (
            <div style={{ fontSize: 16, color: Colors.RedMatte }}>
              {errorText}
            </div>
          )}

          <Rows>
            <button
              className="btn"
              type="submit"
              style={{
                backgroundColor: isFormValid
                  ? Colors.GreenMatte
                  : Colors.VeryLightGrey,
                borderColor: isFormValid
                  ? Colors.GreenMatte
                  : Colors.VeryLightGrey,
                color: isFormValid ? Colors.LegoBlack : Colors.DarkGrey,
                marginTop: 12,
                opacity: isFormValid ? 1 : 0.5,
                marginRight: 18,
                // '&:hover': {
                //     color: 'white'
                // }
              }}
              disabled={!isFormValid}
              onClick={addStaffUserAccount}
            >
              Add User
            </button>

            <button
              className="btn"
              type="submit"
              style={{
                backgroundColor: Colors.RedMatte,
                borderColor: Colors.RedMatte,
                color: Colors.White,
                marginTop: 12,
              }}
              onClick={() => setIsAddNewAccountMode(false)}
            >
              Cancel
            </button>
          </Rows>
        </div>
      ) : (
        <button
          className="btn btn-primary"
          type="submit"
          css={{
            width: 400,
            color: Colors.White,
            backgroundColor: Colors.GreenMatte,
            marginTop: 12,
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: Colors.GreenMatte,
              borderColor: 'green',
            },
          }}
          onClick={() => setIsAddNewAccountMode(true)}
        >
          Create New Account
        </button>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: '40px 0',
          width: '100%',
        }}
      >
        {summaryStore.staffUserAccounts.map((staffAccount) => {
          return (
            <Columns
              key={'sma' + staffAccount.venueStaffUserId}
              style={{
                flexGrow: 1,
                margin: '8px auto',
                borderRadius: 6,
                backgroundColor: Colors.VeryLightGrey,
                padding: 24,
                width: '60%',
                flexDirection: 'row',
              }}
            >
              <Rows style={{ width: '100%' }}>
                <div
                  className={'flex-cen-stack'}
                  style={{
                    flexGrow: 1,
                    marginRight: 24,
                  }}
                >
                  <div>
                    Full Name: {staffAccount.firstName} {staffAccount.lastName}
                  </div>
                  <div>Mobile Number: {staffAccount.phoneNumber}</div>
                  <div style={{ fontSize: 14, marginTop: 8 }}>
                    User Setup in App:{' '}
                    {staffAccount.userHasSetup ? (
                      <CheckCircleFill
                        style={{ marginLeft: 6 }}
                        color={Colors.GreenMatte}
                        size={20}
                      />
                    ) : (
                      <XCircleFill
                        style={{ marginLeft: 6 }}
                        color={Colors.RedMatte}
                        size={20}
                        opacity={0.6}
                      />
                    )}
                  </div>
                </div>

                <TrashFill
                  css={{
                    color: Colors.RedMatte,
                    alpha: 0.8,
                    '&:hover': {
                      cursor: 'pointer',
                      color: 'red',
                      alpha: 1.0,
                    },
                  }}
                  size={32}
                  onClick={() =>
                    deleteStaffUserAccount(staffAccount.venueStaffUserId!)
                  }
                />
              </Rows>
            </Columns>
          );
        })}
      </div>
    </PageContainer>
  );
});
