import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { SummaryStore, UserStore } from '../../../index';
import { useHistory } from 'react-router';
import { reaction } from 'mobx';
import { PageContainer, PageLoader } from '../../ui/PageComponents';
import { SmsContainer, HandleSaveType } from '@kimoycatayas/pe-ui-kit-test';
import ApiClient from '../../../ApiClient';
import { isEmpty, isEqual } from 'lodash';

export const SmsPage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();

  const [remoteState, setRemoteState] = useState<any>({});
  const [localState, setLocalState] = useState<any>({});
  const [smsHeader, setSmsHeader] = useState<string>('');

  const handleSave = ({ rawString, title }: HandleSaveType) => {
    let SmsFieldTypes =
      title === 'caseOpenedSms'
        ? 'CASE_OPEN_SMS'
        : title === 'userCheckUpSms'
        ? 'USER_CHECK_SMS'
        : '';

    if (SmsFieldTypes === '') {
      return;
    }

    console.log({
      SmsFieldTypes,
      SmsTemplateText: rawString,
    });

    try {
      ApiClient.saveSmsData({
        SmsFieldTypes,
        SmsTemplateText: rawString,
      }).then((res) => {
        console.log(res);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSmsData = () => {
    try {
      ApiClient.getSmsData().then((res) => {
        console.log(res);
        if (res.data.success) {
          setSmsHeader(res.data.smsConfiguration.smsHeader);
          delete res.data.smsConfiguration.smsHeader;
          setRemoteState(res.data.smsConfiguration);
          setLocalState(res.data.smsConfiguration);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    reaction(
      () => [userStore.isLoggedIn, userStore.selectedVenue],
      (values) => {
        const isLoggedIn = values[0];
        const selectedVenue = values[1];

        if (!isLoggedIn || !selectedVenue) {
          history.push('/login');
        } else {
          summaryStore.refreshHomeData();

          summaryStore.startHomeDataPolling();
        }
      },
      {
        fireImmediately: true,
      }
    );

    fetchSmsData();
  }, []);

  useEffect(() => {
    fetchSmsData();
  }, [userStore.selectedVenue]);

  if (!summaryStore.dataLoaded) {
    return <PageLoader />;
  }

  return (
    <PageContainer
      breadcrumbs={[
        { name: 'Home', route: '/home' },
        { name: 'Sms Configuration', route: '/sms-configuration' },
      ]}
    >
      <h1 className="text-3xl font-bold text-gray-900 mb-4">
        Sms Configuration
      </h1>
      <div className="flex  flex-col w-full">
        <div>
          <p className="my-2">Sms Header</p>
          <input
            type="text"
            className="w-full p-2 border border-gray-200"
            value={smsHeader}
            onChange={(e) => {
              setSmsHeader(e.target.value);
            }}
          />
          <p>
            <small className="text-gray-500">max character 20/20</small>
          </p>
        </div>
      </div>
      <div className="">
        {!isEmpty(localState) &&
          Object.keys(localState)?.map((item) => (
            <div key={item} className="my-4 bg-white p-4 rounded-lg border">
              <SmsContainer
                key={item}
                title={item}
                smsFields={localState[item]?.allowedSmsFields}
                richHtml={localState[item]?.smsTemplateText}
                handleSave={handleSave}
              />
            </div>
          ))}
      </div>
    </PageContainer>
  );
});
