import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import UserStore from '../store/UserStore';
import UserVenuesStore from '../store/UserVenuesStore';
import ApiClient from '../ApiClient';

export type UserType = {
  userId: number;
  venueId: string;

  firstName: string;
  lastName: string;
  emailAddress: string;

  isAdmin: boolean;
};

export type CommandCentreVenue = {
  venueId: number;
  displayName: string;
  nameKeyStr: string;

  commCenAppIconUrl: string;
};

export default class UserState {
  isLoggedIn: boolean = false;

  user?: UserType = undefined;

  selectedVenue?: CommandCentreVenue = undefined;

  userVenues?: CommandCentreVenue[] = undefined;

  loginInProgress = false;

  venueSearchTerm = '';

  constructor() {
    makeAutoObservable(this);

    let user = UserStore.getUser();
    let userVenues = UserVenuesStore.getVenues();

    if (user !== null && userVenues !== null) {
      this.isLoggedIn = true;
      this.user = user;
      this.userVenues = userVenues;

      if (this.userVenues?.length === 1) {
        this.selectedVenue = this.userVenues[0];
      }
    }
  }

  @computed get getUserId() {
    return this.user?.userId;
  }

  @computed get userVenuesAlphabetical() {
    let venues = this.userVenues?.filter(
      (venue) => venue !== this.selectedVenue
    );

    if (this.venueSearchTerm !== undefined) {
      venues = venues?.filter((venue) =>
        venue.displayName.toLowerCase().includes(this.venueSearchTerm)
      );
    }

    return venues?.sort((venueOne, venueTwo) => {
      if (venueOne.displayName > venueTwo.displayName) {
        return 1;
      }

      if (venueOne.displayName < venueTwo.displayName) {
        return -1;
      }

      return 0;
    });
  }

  @computed get getVenueId() {
    return this.selectedVenue?.venueId;
  }

  @computed get isAdmin() {
    return this.user !== undefined && this.user.isAdmin;
  }

  @action
  login(user: UserType, userVenues: CommandCentreVenue[]) {
    UserStore.storeUser(user);
    UserVenuesStore.storeVenues(userVenues);

    this.user = user;
    this.userVenues = userVenues;

    if (this.userVenues?.length === 1) {
      this.selectedVenue = this.userVenues[0];
    }

    this.isLoggedIn = true;

    this.loginInProgress = false;
  }

  refreshUserVenues() {
    if (!this.isLoggedIn) return;

    ApiClient.refreshUserVenues()
      .then((response: any) => {
        this.login(response.data.user, response.data.commandCentreVenues);
      })
      .catch((error) => {});
  }

  logout() {
    document.cookie =
      'OK2P-AUTH' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    ApiClient.userLogout().then((response) => {
      runInAction(() => {
        this.isLoggedIn = false;
        this.user = undefined;
        this.selectedVenue = undefined;
        this.userVenues = undefined;
        this.loginInProgress = false;

        UserStore.clearStore();
        UserVenuesStore.clearStore();
      });
    });
  }
}

export const userState = new UserState();
