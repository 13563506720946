import { CheckCircleFill, ExclamationCircleFill } from 'react-bootstrap-icons';
import { Colors } from '../../Theme';
import { UserHelpRequests } from '../../state/SummaryState';

export const getRequestBackground = (request: UserHelpRequests) => {
  if (!request.alertLevel) return;

  if (request.actionStatus === 'COMPLETE') {
    return '#4EDE79';
  }

  switch (request.alertLevel) {
    case 'OK':
      return '#4EDE79';
    case 'DELAYED':
      return '#DE9E43';
    case 'WARNING':
      return '#AB3433';
  }
};

export const getRequestIcon = (request: UserHelpRequests) => {
  if (request.actionStatus === 'COMPLETE') {
    return (
      <CheckCircleFill
        size={24}
        color={Colors.GreenMatte}
        style={{ marginLeft: 'auto' }}
      />
    );
  }

  switch (request.alertLevel) {
    case 'OK':
      return (
        <ExclamationCircleFill
          size={24}
          color={Colors.GreenMatte}
          style={{ marginLeft: 'auto' }}
        />
      );
    case 'DELAYED':
      return (
        <ExclamationCircleFill
          size={24}
          color={'#DE9E43'}
          style={{ marginLeft: 'auto' }}
        />
      );
    case 'WARNING':
      return (
        <ExclamationCircleFill
          size={24}
          color={Colors.RedMatte}
          style={{ marginLeft: 'auto' }}
        />
      );
  }
};

export const getCaseSearchRequestIcon = (request: UserHelpRequests) => {
  if (!request.alertLevel) return;

  let message = '';

  switch (request.actionStatus) {
    case 'COMPLETE':
      message = 'Case is Closed';
      break;
    case 'REOPENED':
      message = 'Case was Reopened';
      break;
    case 'INCOMPLETE':
      message = 'Case is Open';
      break;
    case 'HANDED_TO_THIRD_PARTY':
      message = 'Case was handed to a Provider';
      break;
  }

  if (request.actionStatus === 'COMPLETE') {
    return (
      <div
        style={{ marginLeft: 'auto', alignItems: 'center', display: 'flex' }}
      >
        {message}{' '}
        <CheckCircleFill
          size={24}
          color={Colors.GreenMatte}
          style={{ marginLeft: 12 }}
        />
      </div>
    );
  }

  switch (request.alertLevel) {
    case 'OK':
      return (
        <div
          style={{ marginLeft: 'auto', alignItems: 'center', display: 'flex' }}
        >
          {message}{' '}
          <CheckCircleFill
            size={24}
            color={Colors.GreenMatte}
            style={{ marginLeft: 12 }}
          />
        </div>
      );
    case 'DELAYED':
      return (
        <div
          style={{ marginLeft: 'auto', alignItems: 'center', display: 'flex' }}
        >
          {message}{' '}
          <ExclamationCircleFill
            size={24}
            color={'#DE9E43'}
            style={{ marginLeft: 12 }}
          />
        </div>
      );
    case 'WARNING':
      return (
        <div
          style={{ marginLeft: 'auto', alignItems: 'center', display: 'flex' }}
        >
          {message}{' '}
          <ExclamationCircleFill
            size={24}
            color={Colors.RedMatte}
            style={{ marginLeft: 12 }}
          />
        </div>
      );
  }
};
