/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ApiClient from '../../../ApiClient';
import moment from 'moment/moment';
import {
  ActionMeta,
  OnChangeValue,
} from 'react-select/dist/declarations/src/types';
import Select from 'react-select';
import { SelectedOptionType, ValidVenue } from './CcAccountsAdminPage';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { PencilSquare } from 'react-bootstrap-icons';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export type CcUserType = {
  userId: number;
  venueId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  isAdmin: boolean;
  userHasSetup: boolean;
  timeCreated: number;
  userVenueIds: number[];
};

export type CcUserListItemProps = {
  ccUser: CcUserType;
  validVenues: ValidVenue[] | undefined;
  handleUpdate: () => void;
};

export const CcUserListItem = observer((props: CcUserListItemProps) => {
  const { ccUser } = props;

  const allSelectOptions = props.validVenues
    ?.map((venue) => ({
      value: venue.venueId,
      label: venue.displayName,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const [openModal, setOpenModal] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const [editUser, setEditUser] = useState<any | null>(null);

  const [selectedOptions, setSelectedOptions] = useState<OnChangeValue<
    SelectedOptionType,
    true
  > | null>(null);
  const [userVenueIds, setUserVenueIds] = useState<number[]>(
    ccUser.userVenueIds
  );

  const handleClose = () => {
    setOpenModal(false);
    setError('');
    setSuccess('');
  };

  const handleEditUser = async (ccUserId: number) => {
    const confirmEdit = window.confirm(
      `Are you sure you want to edit this user?
      `
    );

    if (!confirmEdit) return;

    try {
      const res = await ApiClient.editCcUser({
        ccUserId,
        firstName: editUser.firstName,
        lastName: editUser.lastName,
      });

      props.handleUpdate();
    } catch (err) {
      console.log(err);
    } finally {
      setEditUser(null);
    }
  };

  const handleDeleteUser = async (ccUserId: number) => {
    // window confirm
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this user? This action cannot be undone.
      `
    );

    if (!confirmDelete) return;

    try {
      const res = await ApiClient.deleteCCUser({ ccUserId });
      props.handleUpdate();
    } catch (err) {
      console.log(err);
    }
  };

  const handleResetPassword = (email: string) => {
    ApiClient.sendPasswordResetEmail(email)
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          setSuccess('Password reset email sent successfully');
          return;
        }
        setError('Error sending password reset email. retry later.');
      })
      .catch((error) => {
        console.log(error);
        setError('Error sending password reset email. retry later.');
      });
  };

  useEffect(() => {
    if (!allSelectOptions || !userVenueIds) return;

    const filteredOptions = allSelectOptions.filter((opt) =>
      userVenueIds.includes(opt.value)
    );

    setSelectedOptions(filteredOptions);
  }, [userVenueIds]);

  const selectVenueOption = (
    option: OnChangeValue<SelectedOptionType, true>,
    actionMeta: ActionMeta<SelectedOptionType>
  ) => {
    if (actionMeta.action === 'select-option') {
      if (!actionMeta.option) return;

      const venueId = actionMeta.option.value;
      const addedIdList = [...userVenueIds, venueId];

      ApiClient.addVenueToUser(ccUser.userId, venueId)
        .then((response) => {
          setUserVenueIds(addedIdList);
        })
        .catch((error) => {});
    } else if (actionMeta.action === 'remove-value') {
      if (!actionMeta.removedValue) return;

      const venueId = actionMeta.removedValue.value;
      const removedIdList = [...userVenueIds].filter((id) => id != venueId);

      ApiClient.deleteVenueFromUser(ccUser.userId, venueId)
        .then((response) => {
          setUserVenueIds(removedIdList);
        })
        .catch((error) => {});
    }
  };

  return (
    <div className="m-2 p-2 w-full">
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {success ? (
            <div
              className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
              role="alert"
            >
              <span className="font-medium">Success!</span> {success}
            </div>
          ) : error ? (
            <div
              className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
              role="alert"
            >
              <span className="font-medium">Something wrong!</span> {error}
            </div>
          ) : (
            <h2 className="text-md font-semibold">
              Send a password reset email to this user: {ccUser.emailAddress}
            </h2>
          )}
          <div className="mt-10">
            {success || error ? (
              <button
                onClick={handleClose}
                type="button"
                className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Close
              </button>
            ) : (
              <div>
                <button
                  onClick={() => {
                    handleResetPassword(ccUser.emailAddress);
                  }}
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Send now
                </button>
                <button
                  onClick={handleClose}
                  type="button"
                  className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </Box>
      </Modal>

      <div className="max-w-lg p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div className="flex gap-4 items-center justify-start">
          {editUser?.userId === ccUser.userId ? (
            <div className="flex gap-2 justify-between">
              <input
                type="text"
                value={editUser?.firstName}
                onChange={(e) =>
                  setEditUser({ ...editUser, firstName: e.target.value })
                }
              />
              <input
                type="text"
                value={editUser?.lastName}
                onChange={(e) =>
                  setEditUser({ ...editUser, lastName: e.target.value })
                }
              />
            </div>
          ) : (
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {ccUser.firstName} {ccUser.lastName}
            </h5>
          )}
        </div>

        <div>
          {editUser ? (
            <div className="w-max flex mt-2">
              <button
                type="button"
                className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                onClick={() => handleEditUser(ccUser.userId)}
              >
                Save
              </button>
              <button
                type="button"
                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                onClick={() => setEditUser(null)}
              >
                Cancel
              </button>
            </div>
          ) : (
            <span>
              <PencilSquare
                className="h-10 w-4 hover:opacity-50"
                onClick={() => {
                  setEditUser(ccUser);
                }}
              />
            </span>
          )}
        </div>

        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {ccUser.emailAddress}
        </p>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          user has set password:
          {ccUser.userHasSetup ? (
            <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
              Yes
            </span>
          ) : (
            <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
              No
            </span>
          )}
        </p>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          time created:{' '}
          {moment(ccUser.timeCreated).format('h:mm:ss a, dddd, MMMM Do YYYY')}
        </p>
        <div>
          <Select
            css={{
              minWidth: 360,
              color: 'black',
              marginBottom: 20,
              marginTop: 20,
            }}
            value={selectedOptions}
            placeholder={'Select Venues...'}
            isSearchable={false}
            isClearable={false}
            isMulti
            onChange={(
              newValue: OnChangeValue<SelectedOptionType, true>,
              actionMeta: ActionMeta<SelectedOptionType>
            ) => selectVenueOption(newValue, actionMeta)}
            options={allSelectOptions}
          />
        </div>
        <div className="flex justify-between">
          <a
            onClick={(e) => {
              e.preventDefault();
              setOpenModal(true);
            }}
            className="mx-1 cursor-pointer inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            reset password
          </a>

          <a
            onClick={(e) => {
              e.preventDefault();
              handleDeleteUser(ccUser.userId);
            }}
            className="mx-1 cursor-pointer inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          >
            delete user
          </a>
        </div>
      </div>
    </div>
  );
});
