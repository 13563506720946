/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { reaction } from 'mobx';
import { useHistory } from 'react-router';
import { SummaryStore, UserStore } from '../../../index';
import { PageContainer, PageHeader } from '../../ui/PageComponents';
import ApiClient from '../../../ApiClient';
import { Colors } from '../../../Theme';
import Board from '../../dnd/board';
import { cloneDeep } from 'lodash';

export type GeneralAnalytics = {
  averageResponseTimeSecs: number;
  unresolvedTicketCount: number;
};

export const IncidentReasonsPage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();

  const [localIncidentReason, setLocalIncidentReason] = useState('');
  const [localCategory, setLocalCategory] =
    useState<string>('Select a category');
  const [customCategory, setCustomCategory] = useState<string | undefined>(
    undefined
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [isAddNewMode, setIsAddNewMode] = useState(false);

  const [categories, setCategories] = useState<string[]>([]);
  const [groupedByCategory, setGroupedByCategory] = useState<any>(null);

  const addIncidentReason = () => {
    setErrorText(undefined);

    if (!isFormValid) {
      setErrorText('You must fill out all fields');
      return;
    }

    ApiClient.addIncidentReason({
      stringContent: localIncidentReason,
      category:
        localCategory === 'new' && customCategory
          ? customCategory
          : localCategory,
    })
      .then((response) => {
        setLocalIncidentReason('');
        setLocalCategory('Select a category');
        summaryStore.incidentReasons.push(response.data.stringSetting);

        getUpdatedIncidentReasons();

        setIsAddNewMode(false);
      })
      .catch((error) => {});
  };

  const getUpdatedIncidentReasons = () => {
    setGroupedByCategory(null);
    // Grouping by category
    const byCategory = summaryStore.incidentReasons.reduce(
      (acc: any, obj: any) => {
        const category = obj.category;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push({ ...obj, id: obj.venueStringSettingId.toString() });
        return acc;
      },
      {}
    );

    // Sorting each category's items by sortOrder
    for (const category in byCategory) {
      byCategory[category].sort((a: any, b: any) => a.sortOrder - b.sortOrder);
    }

    const newCategories = Array.from(
      new Set(summaryStore.incidentReasons.map((obj) => obj.category))
    ).filter((category) => category !== undefined);

    setCategories(newCategories as string[]);

    setGroupedByCategory(byCategory);
  };

  useEffect(() => {
    setErrorText(undefined);

    let isValid = false;

    if (localIncidentReason.length < 0) {
      setErrorText('Incident Reason is required');
      isValid = false;
    }

    if (localCategory === null || localCategory === 'Select a category') {
      setErrorText('Category is required');
      isValid = false;
    }

    if (localCategory === 'new' && !customCategory) {
      setErrorText('New Category is required');
      isValid = false;
    }

    if (
      localCategory === 'new' &&
      localIncidentReason.length > 0 &&
      customCategory &&
      customCategory?.length > 0
    ) {
      isValid = true;
    }

    if (
      localCategory !== 'new' &&
      localCategory !== 'Select a category' &&
      localIncidentReason.length > 0 &&
      localCategory.length > 0
    ) {
      isValid = true;
    }

    setIsFormValid(isValid);
  }, [localIncidentReason, localCategory, customCategory]);

  useEffect(() => {
    getUpdatedIncidentReasons();
  }, [summaryStore.incidentReasons]);

  useEffect(() => {
    reaction(
      () => [userStore.isLoggedIn, userStore.selectedVenue],
      (values) => {
        const isLoggedIn = values[0];
        const selectedVenue = values[1];

        if (!isLoggedIn || !selectedVenue) {
          history.push('/login');
        } else {
          summaryStore.refreshHomeData();
        }
      },
      {
        fireImmediately: true,
      }
    );
  }, []);

  return (
    <PageContainer
      centerContent
      breadcrumbs={[
        { name: 'Home', route: '/home' },
        { name: 'Venue Setup', route: '/settings' },
        { name: 'Setup Incident Reasons', route: '/incident-reasons' },
      ]}
    >
      <PageHeader title={'Setup Incident Reasons'} />

      {isAddNewMode ? (
        <div>
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Add New Incident Reason
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Please provide the following information to add a new incident
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Incident Reason
                </label>
                <div className="mt-2">
                  <input
                    id="incident-reason"
                    name="incident-reason"
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={localIncidentReason}
                    onChange={({ target: { value } }) =>
                      setLocalIncidentReason(value)
                    }
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Category
                </label>
                <div className="mt-2">
                  <select
                    value={localCategory}
                    onChange={({ target: { value } }) => {
                      setLocalCategory(value);
                    }}
                    id="category"
                    name="category"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    {' '}
                    <option value="Select a category" disabled>
                      Select a category
                    </option>
                    {categories.map((category) => (
                      <option value={category}>{category}</option>
                    ))}
                    {categories.length < 4 && (
                      <option value="new">Write new category</option>
                    )}
                  </select>
                </div>

                {localCategory === 'new' && (
                  <div className="mt-4 sm:col-span-4">
                    <label
                      htmlFor="new-category"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      New Category
                    </label>
                    <div className="mt-2">
                      <input
                        id="new-category"
                        name="new-category"
                        type="text"
                        value={customCategory}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={({ target: { value } }) =>
                          setCustomCategory(value)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {errorText && (
              <div
                className="mt-2"
                style={{ fontSize: 16, color: Colors.RedMatte }}
              >
                {errorText}
              </div>
            )}
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
                onClick={() => setIsAddNewMode(false)}
              >
                Cancel
              </button>
              <button
                onClick={addIncidentReason}
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      ) : (
        <button
          className="btn btn-primary"
          type="submit"
          css={{
            width: 400,
            color: Colors.White,
            backgroundColor: Colors.GreenMatte,
            marginTop: 12,
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: Colors.GreenMatte,
              borderColor: 'green',
            },
          }}
          onClick={() => setIsAddNewMode(true)}
        >
          Add New Incident Reason
        </button>
      )}

      {groupedByCategory && (
        <Board
          initial={groupedByCategory}
          getUpdatedIncidentReasons={getUpdatedIncidentReasons}
          IncidentReasonCategory={summaryStore?.IncidentReasonCategory || []}
        />
      )}
    </PageContainer>
  );
});
