import { Fragment, ReactNode } from 'react';
import { NavMenu } from './NavMenu';

export const Layout = (props: { children: ReactNode }) => {
  return (
    <Fragment>
      <NavMenu />
      {props.children}
    </Fragment>
  );
};
