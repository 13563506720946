import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import moment from 'moment';

import { UserHelpRequests } from '../../../state/SummaryState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { handleColumns, initialColumnsState } from './CaseLogSearchPage';
import ApiClient from '../../../ApiClient';
import FilterContainer from './filter-container';
import ModalTable from './ModalTable';
import { PageLoader } from '../../ui/PageComponents';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const ModalContainer = ({
  data,
  openModal,
  handleClose,
}: {
  data: UserHelpRequests | any;
  openModal: boolean;
  handleClose: () => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(data);
  const [helpRequestId, setHelpRequestId] = useState<any>(null);

  const [requestActionLogs, setRequestActionLogs] = useState<any>(null);

  const [columnsState, setColumnsState] = useState([
    {
      show: true,
      name: 'Incident Time',
      key: 'timeCreated',
      modalKey: 'timeCreated',
      render: (val: any) => moment(val).format('h:mm a Do MMM YYYY'),
    },
    {
      show: true,
      name: 'Acting Staff Member Name',
      key: 'actingStaffMemberName',
      modalKey: 'venueStaffName',
      render: (val: any) => val ?? '',
    },
    {
      show: true,
      name: 'Incident Location',
      key: 'siteLocation',
      modalKey: 'siteLocation',
      render: (val: any) => val ?? '',
    },
    {
      show: true,
      name: 'Staff Action Taken',
      key: 'resultType',
      modalKey: 'actionStatus',
      render: (val: { isModal: boolean; value: string }) =>
        !val.isModal
          ? val.value === 'HELP_REQUEST'
            ? 'CREATED'
            : 'ACTION'
          : val.value,
    },
    {
      show: true,
      name: 'Details of Person Involved',
      key: 'requesterName',
      modalKey: 'requesterName',
      render: (val: any) => val ?? '',
    },
    {
      show: true,
      name: 'Notes about Actions',
      key: 'notes',
      modalKey: 'actionNote',
      render: (val: any) => val ?? '',
    },
    {
      show: true,
      name: 'Other Information',
      key: '',
      modalKey: 'otherInformation',
      render: (val: any) => val ?? '',
    },
  ]);

  const handleExportHelpRequest = ({ exportType }: { exportType: any }) => {
    const columnsDisplay = handleColumns(columnsState);
    ApiClient.exportCaseLogSearch({
      helpRequestId,
      columnsDisplay,
      searchType: 'singleHelpRequest',
      exportType,
      value: null,
    })
      .then((response: any) => {
        if (response.status !== 200) {
          return;
        }

        if (exportType === 'pdf') {
          // Convert response data to a Blob
          const blob = new Blob([response.data], {
            type: 'application/pdf', // Assuming response.data contains the PDF data
          });
          const fileName = `Incident_Logs_${moment().format(
            'YYYY-MM-DD_HH-mm-ss'
          )}.pdf`;

          const link = document.createElement('a');
          // create a blobURI pointing to our Blob
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          // some browsers need the anchor to be in the doc
          document.body.append(link);
          link.click();
        }

        if (exportType === 'excel') {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Correct MIME type for Excel
          });
          const fileName = `Incident_Logs_${moment().format(
            'YYYY-MM-DD_HH-mm-ss'
          )}.xlsx`;

          const link = document.createElement('a');
          // create a blobURI pointing to our Blob
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          // some browsers need the anchor to be in the doc
          document.body.append(link);
          link.click();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleGetData = (helpRequestId: string, requesterName: string) => {
    setLoading(true);
    ApiClient.getHelpRequestDetails(helpRequestId)
      .then((res) => {
        const requestActionLogs = res?.data?.requestActionLogs?.map(
          (log: any, i: number) => {
            if (i === 0) {
              return {
                ...log,
                siteLocation: res?.data?.siteLocation,
                requesterName,
              };
            } else {
              return {
                ...log,
                siteLocation: res?.data?.siteLocation,
                requesterName: '',
              };
            }
          }
        );

        setRequestActionLogs(requestActionLogs);
        setModalData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      data &&
      data?.searchResults &&
      data.searchResults[0]?.userHelpRequests &&
      data.searchResults[0]?.userHelpRequests[0].helpRequestId
    ) {
      const helpRequest = data.searchResults[0]?.userHelpRequests[0];

      setHelpRequestId(helpRequest.helpRequestId);
      handleGetData(helpRequest.helpRequestId, helpRequest.requesterName);
    } else {
      handleClose();
    }
  }, [data]);

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {loading ? (
        <Box sx={style}>
          <div className="flex flex-col gap-4 items-center justify-center h-64">
            <PageLoader />
          </div>
        </Box>
      ) : (
        <Box sx={style}>
          <div className="relative w-full max-h-full">
            <div className="relative bg-white rounded-lg dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl text-center w-full font-semibold text-gray-900 dark:text-white">
                  Action Log Details
                </h3>
              </div>
            </div>
          </div>

          <div>
            <FilterContainer
              columnsState={columnsState}
              setColumnsState={setColumnsState}
              data={modalData}
            />
          </div>

          <div className="mt-4 px-8">
            <div className="flex align-center w-full gap-4 items-center justify-between">
              <div>
                <p className="text-xl font-semibold text-gray-800">
                  Case number: {modalData?.helpRequestId}
                </p>
              </div>
              <div className="flex gap-2">
                <p>Status:</p>
                <p
                  className="text-sm text-white p-1 w-64 text-center"
                  style={{
                    backgroundColor:
                      modalData?.actionStatus === 'COMPLETE' ? 'green' : 'red',
                  }}
                >
                  {modalData?.actionStatus}
                </p>
              </div>
              <div className="flex gap-2">
                <p>Date and Time:</p>
                <p className="text-md">
                  {moment(modalData?.timeCreated).format(
                    'hh:mm a dddd, MMMM DD, YYYY'
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="p-4">
            <ModalTable
              data={requestActionLogs}
              visibleColumns={columnsState}
            />
          </div>
          <div className="mt-4 flex w-full gap-4 items-center justify-center">
            <div
              onClick={() => {
                handleExportHelpRequest({
                  exportType: 'excel',
                });
              }}
              className="flex items-center bg-gradient-to-r from-green-700 to-green-800 hover:from-green-800 hover:to-green-900 p-3 rounded-lg shadow-lg transition duration-150 ease-in-out cursor-pointer max-w-xs"
            >
              <span className="text-gray-200 font-medium truncate">
                Download as Excel
              </span>
              <FontAwesomeIcon
                icon={faFileExcel}
                fontSize="20px"
                className="text-gray-200 ml-2"
              />
            </div>

            <div
              onClick={() => {
                handleExportHelpRequest({
                  exportType: 'pdf',
                });
              }}
              className="flex items-center bg-gradient-to-r from-red-700 to-red-800 hover:from-red-800 hover:to-red-900 p-3 rounded-lg shadow-lg transition duration-150 ease-in-out cursor-pointer max-w-xs"
            >
              <span className="text-gray-200 font-medium truncate">
                Download as PDF
              </span>
              <FontAwesomeIcon
                icon={faFilePdf}
                fontSize="20px"
                className="text-gray-200 ml-2"
              />
            </div>
          </div>
          <div className="w-full text-center max-h-full border-t mt-4 rounded-t ">
            <button
              type="button"
              className="mt-4 focus:outline-none text-white bg-red-900 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Box>
      )}
    </Modal>
  );
};

export default ModalContainer;
