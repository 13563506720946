/** Generic alignment **/
import styled from "@emotion/styled";
import moment from "moment";
import {HelpRequestStatus} from "../state/SummaryState";
import {action} from "mobx";

export const Columns = styled.div({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
});

export const Rows = styled.div({
    display: 'flex',
    alignItems: 'center'
});

export const PageTitle = styled.div({
    fontSize: 24,
    textAlign: 'center'
});

export const caseTimeStatusHeaderText = (actionStatus: HelpRequestStatus, secondsSince?: number) => {
    if (!secondsSince) return undefined;
    
    if (actionStatus == "COMPLETE") {
        return "CASE CLOSED";        
    }
    
    let statusText;
    
    // if (actionStatus == "COMPLETE") {
    //     statusText = "CLOSED ";
    // } else {
        statusText = "Open for ";
    // }
    
    if (secondsSince < 60) return `${statusText} ${secondsSince}s`

    let mins = Math.floor(secondsSince / 60);
    let hours = Math.floor(mins / 60);
    let days = Math.floor(hours / 24);

    if (mins < 60) {
        return `${statusText} ${mins}m ${secondsSince % 60}s`;
    }

    if (hours < 24) {
        return `${statusText} ${hours}h ${mins % 60}m`
    }

    return `${statusText} ${days}d ${hours % 24}h`;
};

export const secondsSinceToTimestamp = (secondsSince?: number) => {
    if (!secondsSince || secondsSince == 0) return "N/A";
    
    if (secondsSince < 60) return `${secondsSince}s`

    let mins = Math.floor(secondsSince / 60);
    let hours = Math.floor(mins / 60);
    let days = Math.floor(hours / 24);
    
    if (mins < 60) return `${mins}m ${secondsSince % 60}s`;

    if (hours < 24) return `${hours}h ${mins % 60}m`
    
    return `${days}d ${hours % 24}h`;
};

// export const expandedSecondsSinceToTimestamp = (secondsSince?: number) => {
//     return {moment(device.lastLoginTime).format("h:mm:ss a, dddd, MMMM Do YYYY")}
//    
//     if (!secondsSince) return undefined;
//
//     if (secondsSince < 60) return `${secondsSince}s`
//
//     let mins = Math.floor(secondsSince / 60);
//     let hours = Math.floor(mins / 60);
//     let days = Math.floor(hours / 24);
//
//     if (mins < 60) return `${mins} minutes ${secondsSince % 60} secs ago`;
//
//     if (hours < 24) return `${hours} hours ${mins % 60} mins ago`
//
//     return `${days} days ${hours % 24} hours ago`;
// };