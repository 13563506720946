import { makeAutoObservable } from 'mobx';

export type VenueData = {
  loggedInStaffMember: "Stephen Yurka"
  
}

export default class DataState {
  public hasSignedUpPreviously = false;

  constructor() {
    // const hasSignedUpPreviously = UserStorage.hasLoggedInPreviously();
    //
    // if (hasSignedUpPreviously != null) {
    //   this.hasSignedUpPreviously = hasSignedUpPreviously;
    // }

    makeAutoObservable(this);
  }

  // setAppPage = (page: AppPages) => {
  //   this.currentAppPage = page;
  // };
}

export const dataState = new DataState();
