/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import * as React from 'react';
import {useParams} from 'react-router-dom';
import {observer, useLocalObservable} from "mobx-react";
import {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {SummaryStore, UserStore} from "../../../index";
import {observable, reaction, runInAction, when} from "mobx";
import {useHistory} from "react-router";
import {Button, Card, CardBody, CardHeader, CardImg, CardSubtitle, CardTitle, Container} from "reactstrap";
import {PageTitle, Rows, secondsSinceToTimestamp} from "../../UiKit";
import {getCaseSearchRequestIcon, getRequestBackground, getRequestIcon} from "../../ui/HelperFunctions";
import {AlertLevels, HelpRequestActionLogItem, HelpRequestActions, UserHelpRequests} from "../../../state/SummaryState";
import moment from "moment";
import {Colors} from "../../../Theme";
import {PageContainer, PageHeader} from "../../ui/PageComponents";
import {CaseActionModal} from "../../modals/CaseActionModal";
import ApiClient from "../../../ApiClient";

export const SearchCaseDetailsPage = observer(() => {
    const userStore = useContext(UserStore);
    const history = useHistory();
    const { helpRequestId } = useParams<{ helpRequestId: string }>();

    const pollingTimer = useRef<NodeJS.Timeout>();

    const [requestActionLog, setRequestActionLog] = useState<HelpRequestActionLogItem[]>([]);

    const localState = useLocalObservable<{userHelpRequest: UserHelpRequests | undefined}>(() => ({
        userHelpRequest: undefined,
    }));

    useEffect(() => {
        when(
            () => userStore.selectedVenue === undefined,
            () => {
                history.push('/login');
            }
        );

        // swap venue data
        reaction(
            () => userStore.selectedVenue,
            () => {
                history.goBack();
            }
        );

        reaction(
            () => userStore.isLoggedIn,
            async (isLoggedIn) => {
                if (!isLoggedIn) {
                    history.push('/login');
                }
            },
            {
                fireImmediately: true,
            }
        );

        updateHelpRequestDetails();

        startPolling();

        return () => {
          if (pollingTimer.current) clearInterval(pollingTimer.current)
        };
    }, [])

    const updateHelpRequestDetails = () => {
        ApiClient.getHelpRequestDetails(helpRequestId)
            .then(async (response) => {
                await setRequestActionLog(response.data.requestActionLog);

                runInAction(() => {
                    localState.userHelpRequest = response.data.userHelpRequest;

                    if (!localState.userHelpRequest) return;

                    const currentTime = moment().valueOf();
                    const secondsSince =  Math.ceil((currentTime - localState.userHelpRequest.requestInitiatedTime) / 1000);

                    const minsSince = Math.ceil(secondsSince / 60);
                    let alertLevel: AlertLevels;

                    if (localState.userHelpRequest.actionStatus == "COMPLETE" || minsSince <= 5) {
                        alertLevel = "OK";
                    } else if (minsSince <= 10) {
                        alertLevel = "DELAYED";
                    } else {
                        alertLevel = "WARNING";
                    }

                    localState.userHelpRequest.secondsSince = secondsSince;
                    localState.userHelpRequest.alertLevel = alertLevel;
                });
            })
            .catch(e => {});
    };

    const startPolling = () => {
        pollingTimer.current = setInterval(() => {
            updateHelpRequestDetails();
        }, 5000);
    };

    if (localState.userHelpRequest === undefined) {
        return <div>
            Loading...
        </div>;
    }

    const isMemberRequest = localState.userHelpRequest.requesterMemberNumber != undefined;

    return <PageContainer breadcrumbs={[
        {name: 'Home', route: '/home'},
        {name: 'Cases', route: '/cases'},
        {name: 'Case Search', route: '/case-search'},
        {name: 'View Case', route: '/case-details'},
    ]}>
        <PageHeader title={'Case Details'} />

        <Card style={{ marginTop: 24, marginBottom: 24 }}>
            <CardHeader style={{
                fontSize: 22,
                fontWeight: 'bold',
                borderColor: getRequestBackground(localState.userHelpRequest),
                display: 'flex',
                borderBottomWidth: 3
            }}>
                {localState.userHelpRequest.actionStatus == "COMPLETE" ? (
                    <Fragment>
                        Case was Closed {moment(localState.userHelpRequest.timeCompleted).format("h:mm:ss a, dddd, MMMM Do YYYY")} {getRequestIcon(localState.userHelpRequest)}
                    </Fragment>
                ) : (
                    <Fragment>
                        Case has been open for {secondsSinceToTimestamp(localState.userHelpRequest.secondsSince)} {getRequestIcon(localState.userHelpRequest)}
                    </Fragment>
                )}
            </CardHeader>

            <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {isMemberRequest ? (
                    <CardTitle tag="h5">MEMBER REQUEST</CardTitle>
                ) : (
                    <CardTitle tag="h5">NON-MEMBER REQUEST</CardTitle>
                )}

                <CardImg
                    style={{ maxHeight: 200, width: 'auto', borderRadius: 32, marginBottom: 20 }}
                    src={localState.userHelpRequest.requesterS3ImageFileName} />

                {isMemberRequest && (
                    <CardSubtitle style={{ marginBottom: 8 }}>
                        Member No: {localState.userHelpRequest.requesterMemberNumber}
                    </CardSubtitle>
                )}

                <CardSubtitle>
                    Name: {localState.userHelpRequest.requesterFirstName} {localState.userHelpRequest.requesterLastName}
                </CardSubtitle>

                <CardSubtitle>
                    Phone Number: {localState.userHelpRequest.requesterMobileNumber}
                </CardSubtitle>

                <CardTitle style={{ fontSize: 16, fontWeight: 'bold' }}>
                    Help Case Count: {localState.userHelpRequest.requesterCaseCount}
                </CardTitle>
            </CardBody>
        </Card>

        <CardHeader style={{
            fontSize: 18,
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            borderBottomWidth: 0
        }}>
            Case Action Logs
        </CardHeader>

        <Card style={{ marginTop: 24, marginBottom: 24 }}>
            <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {requestActionLog.map(actionLog => {
                    return <Fragment key={"scdpral" + actionLog.requestActionLogId}>
                        {actionLog.actionDisplayText != null && (
                            <Rows style={{ width: 500, padding: 4, opacity: 0.75, fontSize: 15 }}>
                                {actionLog.actionDisplayText}
                            </Rows>
                        )}

                        <Rows style={actionLog.actionStatus == "REOPENED" ? { width: 500, marginTop: 8 } : { width: 500 }}>
                            <div style={actionLog.actionStatus == "CLOSE_CASE" ? { color: Colors.GreenMatte } : {}}>
                                {actionLog.actionStatusDisplayText}
                            </div>

                            <div style={{ marginLeft: 'auto', fontSize: 15, color: 'gray' }}>
                                {moment(actionLog.timeCreated).format("h:mm:ss a, dddd, MMMM Do YYYY")}
                            </div>
                        </Rows>

                        <Rows style={{ width: 500, textAlign: 'center', padding: 12, opacity: 0.7, fontSize: 15 }}>
                            {actionLog.actionNote}
                        </Rows>
                    </Fragment>
                })}
            </CardBody>
        </Card>
    </PageContainer>
});
