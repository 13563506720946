/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { reaction } from 'mobx';
import { useHistory } from 'react-router';
import { SummaryStore, UserStore } from '../../../index';
import { PageContainer, PageHeader } from '../../ui/PageComponents';
import { Columns, Rows } from '../../UiKit';
import { Colors } from '../../../Theme';
import { XCircleFill } from 'react-bootstrap-icons';
import ApiClient from '../../../ApiClient';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

export type AlertReceiverType = {
  alertReceiverId: undefined;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
};

export const AlertReceiverSetupPage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();
  const [isAddAlertReceiverMode, setIsAddAlertReceiverMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    clearErrors,

    control,
    formState: { errors },
  } = useForm<AlertReceiverType>();

  const onSubmit: SubmitHandler<AlertReceiverType> = (data) => {
    setLoading(true);
    addAlertReceiver(data);
  };

  useEffect(() => {
    reaction(
      () => [userStore.isLoggedIn, userStore.selectedVenue],
      (values) => {
        const isLoggedIn = values[0];
        const selectedVenue = values[1];

        if (!isLoggedIn || !selectedVenue) {
          history.push('/login');
        } else {
          summaryStore.refreshHomeData();
        }
      },
      {
        fireImmediately: true,
      }
    );
  }, []);

  const addAlertReceiver = (data: AlertReceiverType) => {
    ApiClient.addNewAlertReceiver(data)
      .then((response) => {
        summaryStore.alertReceivers.push(response.data.alertReceiver);
        setIsAddAlertReceiverMode(false);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const deleteAlertReceiver = (alertReceiverId: number) => {
    ApiClient.deleteAlertReceiver(alertReceiverId)
      .then((response) => {
        summaryStore.deleteAlertReceiver(alertReceiverId);
      })
      .catch((error) => {});
  };

  return (
    <PageContainer
      centerContent
      breadcrumbs={[
        { name: 'Home', route: '/home' },
        { name: 'Settings', route: '/settings' },
        { name: 'Alert Receivers', route: '/alert-receivers' },
      ]}
    >
      <PageHeader title={'Alert Receivers Setup'} />
      {isAddAlertReceiverMode ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-lg w-full mx-auto border p-4 rounded"
        >
          <div className="mb-2">
            <label
              htmlFor="firstName"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              First Name
            </label>
            <input
              {...register('firstName', {
                required: true,
              })}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            {errors.firstName && (
              <p className="text-red-500 text-sm mt-1">
                First name is required
              </p>
            )}
          </div>
          <div className="mb-2">
            <label
              htmlFor="lastName"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Last Name
            </label>
            <input
              {...register('lastName', {
                required: true,
              })}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Last Name"
            />
            {errors.lastName && (
              <p className="text-red-500 text-sm mt-1">Last name is required</p>
            )}
          </div>
          <div className="mb-2">
            <label
              htmlFor="phoneNumber"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Phone Number
            </label>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: true,
                validate: (value) => {
                  return isValidPhoneNumber(value);
                },
              }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  // @ts-ignore
                  value={value}
                  onChange={onChange}
                  defaultCountry={'AU'}
                  country={'AU'}
                  id="phoneNumber"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  error={!!errors.phoneNumber}
                />
              )}
            />

            {errors.phoneNumber && (
              <p className="text-red-500 text-sm mt-1">
                Phone number is invalid
              </p>
            )}
          </div>
          <div className="mb-5">
            <label
              htmlFor="emailAddress"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Email Address
            </label>
            <input
              {...register('emailAddress', {
                required: true,
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            {errors.emailAddress && (
              <p className="text-red-500 text-sm mt-1">
                Email address is invalid
              </p>
            )}
          </div>
          {loading ? (
            <div>
              <p className="text-blue-500 text-sm">Loading...</p>
            </div>
          ) : (
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
                onClick={() => setIsAddAlertReceiverMode(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save
              </button>
            </div>
          )}
        </form>
      ) : (
        <button
          className="btn btn-primary"
          type="submit"
          css={{
            width: 400,
            color: Colors.White,
            backgroundColor: Colors.GreenMatte,
            marginTop: 12,
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: Colors.GreenMatte,
              borderColor: 'green',
            },
          }}
          onClick={() => setIsAddAlertReceiverMode(true)}
        >
          Add Alert Receiver
        </button>
      )}

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: '40px 0',
          width: '100%',
        }}
      >
        {summaryStore.alertReceivers.map((receiver) => {
          return (
            <Columns
              key={'ar' + receiver.alertReceiverId}
              css={{
                flexGrow: 1,
                margin: '8px auto',
                borderRadius: 6,
                backgroundColor: Colors.VeryLightGrey,
                padding: 24,
                width: '60%',
                flexDirection: 'row',
              }}
            >
              <div
                className={'flex-cen-stack'}
                css={{
                  flexGrow: 1,
                  marginRight: 24,
                }}
              >
                <Rows css={{ width: '100%' }}>
                  <div css={{ marginRight: 'auto' }}>Full Name:</div>
                  <div>
                    {receiver.firstName} {receiver.lastName}
                  </div>
                </Rows>

                <Rows css={{ width: '100%' }}>
                  <div css={{ marginRight: 'auto' }}>Mobile Number:</div>
                  <div>{receiver.phoneNumber}</div>
                </Rows>

                <Rows css={{ width: '100%' }}>
                  <div css={{ marginRight: 'auto' }}>Email Address:</div>
                  <div>{receiver.emailAddress}</div>
                </Rows>
              </div>

              <div>
                <XCircleFill
                  css={{
                    color: Colors.RedMatte,
                    alpha: 0.8,
                    '&:hover': {
                      cursor: 'pointer',
                      color: 'red',
                      alpha: 1.0,
                    },
                  }}
                  size={32}
                  onClick={() => deleteAlertReceiver(receiver.alertReceiverId!)}
                />
              </div>
            </Columns>
          );
        })}
      </div>
    </PageContainer>
  );
});
