/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { DataStore, SummaryStore, UserStore } from '../../../index';
import { useHistory } from 'react-router';
import { reaction, when } from 'mobx';
import { Colors } from '../../../Theme';
import {
  PageContainer,
  PageHeader,
  PageSubHeader,
  PageSubText,
} from '../../ui/PageComponents';
import ApiClient from '../../../ApiClient';
import { GoldBallLoader, GreenBallLoader } from '../../ui/Loading';
import moment from 'moment/moment';
import { Columns, Rows } from '../../UiKit';
import Select, { ActionMeta } from 'react-select';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';

type DeviceKey = {
  deviceKeyId: number;
  venueId: number;
  deviceKey: string;
  isConsumed: boolean;
  isRevoked: boolean;
  timeCreated: number;
};

type ValidVenue = {
  venueId: number;
  displayName: string;
};

type SelectedOptionType = {
  value: number;
  label: string;
};

// IN_APP_SIGNUP - for Responder App
// CREATE_CASE_APP - for Incident App

type VenueDeviceKeyContainerProps = {
  title: string;
  appType: 'IN_APP_SIGNUP' | 'CREATE_CASE_APP';
};

export const VenueDeviceKeyContainer = observer(
  (props: VenueDeviceKeyContainerProps) => {
    const userStore = useContext(UserStore);
    const summaryStore = useContext(SummaryStore);
    const history = useHistory();

    const pollingTimer = useRef<NodeJS.Timeout>();

    const [validVenues, setValidVenues] = useState<ValidVenue[] | undefined>(
      undefined
    );
    const [selectedOption, setSelectedOption] = useState<OnChangeValue<
      SelectedOptionType,
      false
    > | null>(null);

    const [unusedKeys, setUnusedKeys] = useState<DeviceKey[] | undefined>(
      undefined
    );
    const [usedKeys, setUsedKeys] = useState<DeviceKey[] | undefined>(
      undefined
    );

    const [isGeneratingDeviceKey, setIsGeneratingDeviceKey] = useState(false);
    const [generatedDeviceKey, setGeneratedDeviceKey] = useState<
      DeviceKey | undefined
    >(undefined);

    const [requestInProgress, setRequestInProgress] = useState(false);

    useEffect(() => {
      when(
        () => userStore.selectedVenue === undefined || !userStore.isAdmin,
        () => {
          history.push('/login');
        }
      );

      reaction(
        () => userStore.isLoggedIn,
        (isLoggedIn) => {
          if (!isLoggedIn) {
            history.push('/login');
            return;
          }

          loadValidVenues();
        },
        {
          fireImmediately: true,
        }
      );

      return () => {
        if (pollingTimer.current) clearInterval(pollingTimer.current);
      };
    }, []);

    const loadValidVenues = () => {
      ApiClient.getValidVenues()
        .then((response) => {
          setValidVenues(response.data.validVenues);
        })
        .catch((error) => {});
    };

    useEffect(() => {
      setGeneratedDeviceKey(undefined);
      setIsGeneratingDeviceKey(false);
      setUnusedKeys(undefined);
      setUsedKeys(undefined);

      if (selectedOption !== null) {
        setRequestInProgress(true);
        loadDeviceKeys();
        startPolling();
      }
    }, [selectedOption]);

    const selectVenueOption = (
      option: OnChangeValue<SelectedOptionType, false>
    ) => {
      if (pollingTimer.current) clearInterval(pollingTimer.current);
      setRequestInProgress(false);

      setSelectedOption(option);
    };

    const startPolling = () => {
      pollingTimer.current = setInterval(() => {
        loadDeviceKeys();
      }, 10000);
    };

    const loadDeviceKeys = () => {
      if (selectedOption == null || selectedOption.value == null) return;

      ApiClient.getDeviceKeys({
        venueId: selectedOption.value as number,
        appType: props.appType,
      })
        .then((response) => {
          setUnusedKeys(response.data.unusedKeys);
          setUsedKeys(response.data.usedKeys);

          setRequestInProgress(false);
        })
        .catch(() => {});
    };

    const generateNewDeviceKey = () => {
      if (selectedOption == null || selectedOption.value == null) return;

      setGeneratedDeviceKey(undefined);
      setIsGeneratingDeviceKey(true);

      ApiClient.generateNewDeviceKey({
        venueId: selectedOption.value as number,
        appType: props.appType,
      })
        .then((response) => {
          setUnusedKeys(response.data.unusedKeys);
          setGeneratedDeviceKey(response.data.deviceKey);
        })
        .catch(() => {
          setIsGeneratingDeviceKey(false);
        });
    };

    const resetGeneratedKey = () => {
      setGeneratedDeviceKey(undefined);
      setIsGeneratingDeviceKey(false);
    };

    return (
      <PageContainer
        centerContent
        breadcrumbs={[
          { name: 'Home', route: '/home' },
          { name: 'Device Keys Admin', route: '/admin/device-keys' },
        ]}
      >
        <PageHeader title={props.title} />

        {validVenues != undefined && (
          <div css={{ marginTop: 20 }}>
            <div
              css={{
                fontSize: 22,
                marginTop: -12,
                marginBottom: 12,
                textAlign: 'center',
              }}
            >
              {validVenues.length} Venue's Found
            </div>

            <Select
              css={{ minWidth: 360, color: 'black', marginBottom: 20 }}
              value={selectedOption}
              placeholder={'Select Venue...'}
              isSearchable
              isClearable
              isMulti={false}
              onChange={(newValue: OnChangeValue<SelectedOptionType, false>) =>
                selectVenueOption(newValue)
              }
              options={validVenues
                .sort((a, b) => a.displayName.localeCompare(b.displayName))
                .map((venue) => ({
                  value: venue.venueId,
                  label: venue.displayName,
                }))}
            />
          </div>
        )}

        {selectedOption !== null && requestInProgress && (
          <Columns css={{ marginTop: 60 }}>
            <div css={{ fontSize: 20, marginBottom: 8 }}>Loading...</div>
            <GoldBallLoader />
          </Columns>
        )}

        {selectedOption !== null && !requestInProgress && (
          <Fragment>
            <PageSubHeader
              title={'Un-Used Device Keys: ' + unusedKeys?.length}
            />

            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                margin: '0 0',
                width: '100%',
              }}
            >
              {unusedKeys?.map((key) => {
                return (
                  <Columns
                    css={{
                      flexGrow: 1,
                      margin: '8px auto',
                      borderRadius: 6,
                      backgroundColor: Colors.VeryLightGrey,
                      padding: 24,
                      width: '60%',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      className={'flex-cen-stack'}
                      css={{
                        flexGrow: 1,
                        marginRight: 24,
                      }}
                    >
                      <Rows css={{ width: '100%' }}>
                        <div css={{ marginRight: 'auto' }}>Key:</div>
                        <div>{key.deviceKey}</div>
                      </Rows>

                      <Rows css={{ width: '100%' }}>
                        <div css={{ marginRight: 'auto' }}>Generated:</div>
                        <div>
                          {moment(key.timeCreated).format(
                            'h:mm:ss a, dddd, MMMM Do YYYY'
                          )}
                        </div>
                      </Rows>
                    </div>
                  </Columns>
                );
              })}
            </div>

            <PageSubText
              title={
                'Please use existing device keys before generating new ones.'
              }
            />

            {isGeneratingDeviceKey ? (
              <div
                className={'flex-cen-stack'}
                css={{
                  width: '55%',
                  margin: '16px auto',
                  borderRadius: 12,
                  border: '2px solid',
                  borderColor: Colors.BlueMatte,
                  padding: 18,
                }}
              >
                {generatedDeviceKey === undefined ? (
                  <Fragment>
                    <div css={{ fontSize: 18, marginBottom: 12 }}>
                      Generating New Device key...
                    </div>
                    <GreenBallLoader />
                  </Fragment>
                ) : (
                  <Fragment>
                    <div css={{ fontSize: 18, marginBottom: 12 }}>
                      Success! Your new Device Key is:
                    </div>
                    <div css={{ fontSize: 18, marginBottom: 12 }}>
                      {generatedDeviceKey.deviceKey}
                    </div>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      css={{
                        width: 200,
                        color: Colors.White,
                        backgroundColor: Colors.GreenMatte,
                        marginTop: 12,
                        '&:hover': {
                          cursor: 'pointer',
                          backgroundColor: Colors.GreenMatte,
                          borderColor: 'green',
                        },
                      }}
                      onClick={resetGeneratedKey}
                    >
                      Back
                    </button>
                  </Fragment>
                )}
              </div>
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                css={{
                  width: 400,
                  color: Colors.White,
                  backgroundColor: Colors.GreenMatte,
                  marginTop: 12,
                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: Colors.GreenMatte,
                    borderColor: 'green',
                  },
                }}
                onClick={generateNewDeviceKey}
              >
                Generate New Device key
              </button>
            )}

            <PageSubHeader
              title={'Used Device Keys: ' + usedKeys?.length}
              cssProps={{ marginTop: 60 }}
            />

            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                margin: '0 0',
                width: '100%',
              }}
            >
              {usedKeys?.map((key) => {
                return (
                  <Columns
                    css={{
                      flexGrow: 1,
                      margin: '8px auto',
                      borderRadius: 6,
                      backgroundColor: Colors.VeryLightGrey,
                      padding: 24,
                      width: '60%',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      className={'flex-cen-stack'}
                      css={{
                        flexGrow: 1,
                        marginRight: 24,
                      }}
                    >
                      <Rows css={{ width: '100%' }}>
                        <div css={{ marginRight: 'auto' }}>Key:</div>
                        <div>{key.deviceKey}</div>
                      </Rows>

                      <Rows css={{ width: '100%' }}>
                        <div css={{ marginRight: 'auto' }}>Generated:</div>
                        <div>
                          {moment(key.timeCreated).format(
                            'h:mm:ss a, dddd, MMMM Do YYYY'
                          )}
                        </div>
                      </Rows>
                    </div>
                  </Columns>
                );
              })}
            </div>
          </Fragment>
        )}
      </PageContainer>
    );
  }
);
