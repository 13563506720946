/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {Colors} from "../../Theme";
import ReactModal from "react-modal";
import {useContext, useState} from "react";
import {SummaryStore} from "../../index";
import {exitButtonCss, modalStyle} from "../ui/Styles";
import {ModalTitle} from "./ModalComponents";
import TimePicker from 'rc-time-picker';
import moment, {Moment} from 'moment';
import { Rows } from '../UiKit';
import ApiClient from "../../ApiClient";

export type VenueTimeModalProps = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
};

const format = 'h:mm a';
const now = moment().hour(0).minute(0);

export const VenueTimeModal = (props: VenueTimeModalProps) => {
    const summaryStore = useContext(SummaryStore);
    
    // const [errorText, setErrorText] = useState<string | undefined>(undefined);
    const [utcMoment, setUtcMoment] = useState<Moment>(moment(summaryStore.venueClosingTime));
    
    const saveCloseTime = () => {
        let localTime = moment().hours(utcMoment.hours()).minutes(utcMoment.minutes()).seconds(0)

        console.log(localTime);
        console.log(localTime.valueOf());
        console.log(localTime.hours());
        console.log(localTime.minutes());
        console.log('isUTC ? ' + localTime.isUTC());

        let utcTime = localTime.utc(false)

        console.log(utcTime);
        console.log(utcTime.valueOf());
        console.log(utcTime.hours());
        console.log(utcTime.minutes());
        console.log('isUTC ? ' + utcTime.isUTC());
        
        ApiClient.setVenueCobTime(utcTime.hours(), utcTime.minutes(), utcTime.valueOf())
            .then((response) => {
                summaryStore.venueClosingTime = utcTime.valueOf();
                props.setShowModal(false);
            })
            .catch(error => {
            })
    };
    
    const onChange = (utcValue: moment.Moment) => {
        setUtcMoment(utcValue);
        // utcValue.local()
        
        let localTime = moment().hours(utcValue.hours()).minutes(utcValue.minutes()).seconds(0)
        
        console.log(localTime);
        console.log(localTime.valueOf());
        console.log(localTime.hours());
        console.log(localTime.minutes());
        console.log('isUTC ? ' + localTime.isUTC());

        let utcTime = localTime.utc(false)

        console.log(utcTime);
        console.log(utcTime.valueOf());
        console.log(utcTime.hours());
        console.log(utcTime.minutes());
        console.log('isUTC ? ' + utcTime.isUTC());
    };
    
    return (
        <ReactModal
            isOpen={props.showModal}
            contentLabel="Edit Terms and Conditions"
            style={modalStyle}
        >
            <ModalTitle title={"Venue Closing TIme"} />

            <div className={'flex-cen-stack'}
                 style={{ marginTop: 12 }}
            >
                <TimePicker
                    placeholder={"Select Closing Time"}
                    showSecond={false}
                    value={utcMoment}
                    css={{ width: 300, height: 40, fontSize: 20 }}
                    onChange={onChange}
                    format={format}
                    minuteStep={5}
                    use12Hours
                    inputReadOnly
                    focusOnOpen
                />
                
                {/*<TimePicker*/}
                {/*    disableClock*/}
                {/*    onChange={onChangeTime}*/}
                {/*    value={valueTime} />*/}
                
                {/*<form>*/}
                {/*    <div className="form-group">*/}
                {/*        <input*/}
                {/*            onChange={(event) => setHours(event.target.value)}*/}
                {/*            value={cobTime.hours}*/}
                {/*            type="number"*/}
                {/*            required={true}*/}
                {/*            inputMode={'numeric'}*/}
                {/*        />*/}
                
                {/*        <label htmlFor="input" className="control-label">Hours</label><i className="bar"></i>*/}
                {/*    </div>*/}
                
                {/*    <div className="form-group">*/}
                {/*        <input*/}
                {/*            onChange={(event) => setMinutes(event.target.value)}*/}
                {/*            value={cobTime.minutes}*/}
                {/*            type="number"*/}
                {/*            required={true}*/}
                {/*            inputMode={'numeric'}*/}
                {/*        />*/}
                {/*        */}
                {/*        <label htmlFor="input" className="control-label">Minutes</label><i className="bar"></i>*/}
                {/*    </div>*/}
                {/*    */}
                {/*    <div>{cobTime.type}</div>*/}
                {/*</form>*/}
                
                {/*<LocalizationProvider dateAdapter={AdapterMoment}>*/}
                {/*    <TimePicker*/}
                {/*        label="Basic example"*/}
                {/*        value={value}*/}
                {/*        onChange={(newValue: Moment | null) => {*/}
                {/*            setValue(newValue);*/}
                {/*            */}
                {/*            console.log(newValue);*/}
                {/*            */}
                {/*            console.log("millis: " + newValue?.valueOf());*/}
                {/*            console.log("is UTC?: " + newValue?.isUtc());*/}
                {/*            console.log("is UTC?: " + newValue?.isUTC());*/}
                {/*            */}
                {/*            console.log("is UTC?: " + moment(newValue).isUTC());*/}
                {/*            console.log("local: " + moment.parseZone(newValue));*/}
                {/*            */}
                {/*            var val = `${newValue?.hours()}:${newValue?.minutes()}`;*/}
                {/*            var selectedDate = moment(val, 'h:mm');*/}
                {/*            */}
                {/*            let utc = newValue?.utc();*/}
                {/*            let local = newValue?.local();*/}
                {/*            let diff = utc?.diff(local, "hours")*/}
                {/*            */}
                {/*            console.log("utc hour: " + utc?.hours())*/}
                {/*            console.log("local hour: " + local?.hours())*/}
                {/*            */}
                {/*            console.log("local offset: " + local?.utcOffset())*/}
                {/*            console.log("utc offset: " + utc?.utcOffset())*/}
                {/*            console.log(diff)*/}
                {/*            */}
                {/*            console.log(newValue?.utc().hour());*/}
                {/*            console.log(newValue?.utc().minute());*/}
                {/*            */}
                {/*            // TODO: take remote UTC and % 12 to make a regular moment again*/}
                {/*            */}
                {/*            */}
                {/*            // console.log(newValue?.valueOf());*/}
                {/*            // console.log(newValue?.utc(false).hour());*/}
                {/*            // console.log(newValue?.utc(false).minute());*/}
                {/*            */}
                {/*            */}
                {/*            */}
                {/*            // console.log(newValue?.moment);*/}
                {/*            // console.log(newValue?.toDateString());*/}
                {/*            // console.log(newValue?.toUTCString());*/}
                {/*            // console.log(newValue?.toISOString());*/}
                {/*        }}*/}
                {/*        renderInput={(params) => <TextField {...params} />}*/}
                {/*    />*/}
                {/*</LocalizationProvider>*/}
                
                <Rows css={{ marginTop: 12 }}>
                    <button
                        className="btn btn-primary"
                        type="submit"
                        css={{
                            // backgroundColor: this.props.userState?.userVenue?.headerBgColor,
                            // borderColor: this.props.userState?.userVenue?.headerBgColor,
                            color: Colors.DarkGrey,
                            marginTop: 12,
                            marginRight: 24
                            // opacity: isFormValid ? 1 : 0.5
                        }}
                        onClick={saveCloseTime}>
                        Set Time
                    </button>

                    <button
                        style={{ marginTop: 12 }}
                        className="btn btn-primary"
                        type="submit"
                        css={exitButtonCss}
                        onClick={() => props.setShowModal(false)}
                    >
                        Exit
                    </button>
                </Rows>
            </div>
        </ReactModal>
    )  
};