/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import * as React from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Fragment, useContext, useEffect, useState } from 'react';
import ApiClient from '../../ApiClient';
import { GoldBallLoader } from '../ui/Loading';
import './LoginPage.scss';
import { UserStore } from '../../index';
import { reaction } from 'mobx';
import { useHistory } from 'react-router';
import { Colors } from '../../Theme';
import checkPasswordStrength, { Result } from 'check-password-strength';

const passwordValidator = require('password-validator');
const schema = new passwordValidator();
schema
  .is()
  .min(8, 'The password should be at least 8 characters long')
  .is()
  .max(80, 'The password should be less than 80 characters long')
  .has()
  .uppercase(1, 'The password should contain an uppercase letter')
  .has()
  .lowercase(1, 'The password should contain a lowercase letter')
  .has()
  .digits(1, 'The password should contain at least 1 number')
  .has()
  .not()
  .spaces(0, 'The password should not contain any spaces');
type PasswordValidationDetail = {
  validation: string;
  arguments?: number;
  message: string;
};

export const LoginSetupPage = observer(() => {
  const userStore = useContext(UserStore);
  const history = useHistory();
  const { setupHash } = useParams<{ setupHash: string }>();

  const [userLogin, setUserLogin] = useState({
    password: '',
    confirmPassword: '',
  });

  const [loginInProgress, setLoginInProgress] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  const [passwordStrength, setPasswordStrength] = useState<
    Result<string> | undefined
  >(undefined);

  useEffect(() => {
    if (userStore.isLoggedIn) {
      userStore.logout();
    }
  }, []);

  useEffect(() => {
    setErrorText(undefined);

    setPasswordStrength(
      checkPasswordStrength.passwordStrength(userLogin.password)
    );

    console.log(passwordStrength);

    const rules: PasswordValidationDetail[] = schema.validate(
      userLogin.password,
      { details: true }
    );
    if (rules.length > 0) {
      setIsFormValid(false);
      setErrorText(rules[0].message);
      return;
    }

    // matches
    if (userLogin.password !== userLogin.confirmPassword) {
      setIsFormValid(false);
      setErrorText("Your password's do not match");
      return;
    }

    setIsFormValid(true);
  }, [userLogin]);

  const formSubmit = () => {
    if (!isFormValid) return;

    setLoginInProgress(true);

    ApiClient.setPassword(
      userLogin.password,
      userLogin.confirmPassword,
      setupHash
    )
      .then((response: any) => {
        userStore.login(response.data.user, response.data.commandCentreVenues);
        setLoginInProgress(false);
        history.push('/login');
      })
      .catch((error) => {
        setLoginInProgress(false);
      });
  };

  return (
    <div
      className={'login-container'}
      css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <div className={'text-center'}>
        <div className="brand-title">
          <div css={{ fontSize: 28, fontWeight: 'bold', marginBottom: -6 }}>
            Player Safety Solutions
          </div>
          <div css={{ fontSize: 24, fontStyle: 'italic' }}>by OK2Play</div>
        </div>

        <div css={{ fontSize: 18, marginTop: 40 }}>
          To complete your Command Centre Sign Up, plese set your password below
        </div>

        <div css={{ fontSize: 18, marginTop: 40, marginBottom: 40 }}>
          Your password should be:
          <ul css={{ fontSize: 16, marginTop: 8 }}>
            <li>At least 8 Characters Long</li>
            <li>Contain at least 1 Number</li>
            <li>Contain at least 1 Uppercase Letter</li>
            <li css={{ fontStyle: 'italic' }}>
              It is recommended to use 1 Special Character
            </li>
          </ul>
        </div>
      </div>

      {userLogin.password.length > 0 && passwordStrength !== undefined && (
        <div css={{ width: 480, padding: 6, marginTop: 20, marginBottom: 20 }}>
          <div
            id="color-indicators"
            css={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              columnGap: '0.2rem',
              '>span': {
                height: 5,
              },
            }}
          >
            <span css={{ backgroundColor: 'red' }} />
            <span
              css={{
                backgroundColor:
                  passwordStrength.id > 0 ? 'orange' : 'lightgrey',
              }}
            />
            <span
              css={{
                backgroundColor:
                  passwordStrength.id > 1 ? 'yellow' : 'lightgrey',
              }}
            />
            <span
              css={{
                backgroundColor:
                  passwordStrength.id > 2 ? 'green' : 'lightgrey',
              }}
            />
          </div>

          <div css={{ marginTop: 6 }}>
            Password Strength: {passwordStrength?.value}
          </div>
        </div>
      )}

      <div className="material-container signin-card">
        <form>
          <div className="form-group">
            <input
              onChange={(event) =>
                setUserLogin({
                  ...userLogin,
                  password: event.target.value,
                })
              }
              value={userLogin.password}
              type={'password'}
              required={true}
            />

            <label htmlFor="input" className="control-label">
              Password
            </label>
            <i className="bar"></i>
          </div>

          <div className="form-group">
            <input
              onChange={(event) =>
                setUserLogin({
                  ...userLogin,
                  confirmPassword: event.target.value,
                })
              }
              value={userLogin.confirmPassword}
              type={'password'}
              required={true}
            />

            <label htmlFor="input" className="control-label">
              Confirm Password
            </label>
            <i className="bar"></i>
          </div>
        </form>

        {errorText !== undefined && (
          <div
            css={{
              color: Colors.RedMatte,
              textAlign: 'center',
              fontSize: 14,
              marginBottom: 12,
            }}
          >
            {errorText}
          </div>
        )}

        {loginInProgress ? (
          <GoldBallLoader />
        ) : (
          <Fragment>
            <div className="button-container">
              <button
                type="button"
                className="button"
                css={{
                  backgroundColor: isFormValid
                    ? Colors.GreenMatte
                    : Colors.DarkGrey,
                  borderColor: isFormValid
                    ? Colors.GreenMatte
                    : Colors.DarkGrey,
                  color: isFormValid ? Colors.LegoBlack : Colors.DarkGrey,
                  marginTop: 12,
                  opacity: isFormValid ? 1 : 0.3,
                  marginRight: 18,
                  '&:hover': {
                    cursor: isFormValid ? 'pointer' : 'default',
                  },
                }}
                disabled={!isFormValid}
                onClick={formSubmit}
              >
                <span style={{ color: 'white' }}>Set Password</span>
              </button>
            </div>

            {/*<a className="forgot-password" style={{ color: 'black' }}>I forgot my password</a>*/}
          </Fragment>
        )}
      </div>
    </div>
  );
});
