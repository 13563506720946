
export const Colors = {
    PrimaryGold: '#CDAC3D',
    GoldStrong: 'goldenrod',
    GoldMediumStrong: '#D8A626',
    GoldMediumWeak: '#E1B439',
    GoldMedium: '#f6d365',

    DarkerGrey: '#222222',
    DarkGrey: '#343A40',

    LightGrey: 'lightgrey',
    VeryLightGrey: '#EAEAEA',

    BlueMatte: '#3494D5',
    RedMatte: '#E24A3B',
    GreenMatte: '#2CC66D',
    OrangeMatte: '#ED944D',
    PurpleMatte: '#392A48',

    White: '#FFFFFFFF',

    LiteralCssGold: 'gold',

    LegoYellow: '#FFCF00',
    LegoBlack: '#000000',
    LegoDark: '#2c2c2c'
};
