import { Fragment } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';

type Props = {
  searchType: string;
  setSearchValue: (value: string) => void;
  searchValue: string;
  dateRange: any;
  handleValueChange: (value: any) => void;
};
export default function SearchTypeContainer({
  searchType,
  setSearchValue,
  searchValue,
  dateRange,
  handleValueChange,
}: Props) {
  return (
    <Fragment>
      <div className="my-4">
        {searchType === 'date-range' ? (
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2 mt-8 sm:mt-0 sm:mb-4">
              Date Picker
            </h3>
            <Datepicker
              displayFormat="MMMM DD, YYYY"
              separator="to"
              value={dateRange}
              onChange={handleValueChange}
              showShortcuts={true}
              showFooter={true}
              inputClassName="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        ) : searchType === 'member-name' ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <label
              htmlFor="website-admin"
              className="text-lg font-semibold text-gray-900 mb-2 mt-8 sm:mt-0 sm:mb-4"
            >
              Search Incidents
            </label>
            <div className="flex">
              <span className="inline-flex items-center px-3 text-sm text-gray-900 border border-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg>
              </span>
              <input
                type="text"
                id="website-admin"
                className="border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search member Details..."
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
          </form>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <label
              htmlFor="website-admin"
              className="text-lg font-semibold text-gray-900 mb-2 mt-8 sm:mt-0 sm:mb-4"
            >
              Staff Name
            </label>
            <div className="flex">
              <span className="inline-flex items-center px-3 text-sm text-gray-900 border border-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg>
              </span>
              <input
                type="text"
                id="website-admin"
                className="border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Staff name"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
          </form>
        )}
      </div>
    </Fragment>
  );
}
