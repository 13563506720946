import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { Key, useEffect, useState } from 'react';
import { UserHelpRequests } from '../../state/SummaryState';
import { RequestActionTaken } from '../pages/cases/CaseDetailsPage';

import ApiClient from '../../ApiClient';

export type CaseActionModalProps = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  actionCallback: (actionTaken: RequestActionTaken, actionNote: string) => void;
  userHelpRequest: UserHelpRequests;
  customActions: any;
  incidentReasons: any;
};

export const CaseActionModal = (props: CaseActionModalProps) => {
  const [modalCustomActions, setModalCustomActions] = useState<any>([]);
  const [actionNote, setActionNote] = useState('');
  const [isClosingCase, setIsClosingCase] = useState(false);

  const handleCheckboxChange = (reason: string | number, action: any) => {
    if (reason === 'close case') {
      setIsClosingCase((prevState) => !prevState);
      return;
    }
  };

  const saveSelections = () => {
    const customActionsSelectedOnly = modalCustomActions
      .filter((action: any) => action.selected)
      .map((action: any) => ({
        VenueStringSettingId: action.venueStringSettingId,
        action: action.stringContent,
      }));

    let requestBody: any = {
      requestId: props.userHelpRequest.helpRequestId,
      actionNote,
      customActions: customActionsSelectedOnly,
    };

    if (isClosingCase) {
      requestBody = {
        ...requestBody,
        ActionTaken: 'CLOSE_CASE',
      };
    }

    ApiClient.addActionToIncident(requestBody)
      .then((response) => {
        console.log('Action saved:', response);
        resetModal();
      })
      .catch((error) => {
        console.error('Error saving action:', error);
      });
  };

  const resetModal = () => {
    setActionNote('');
    setIsClosingCase(false);
    props.setShowModal(false);
  };

  useEffect(() => {
    const venueCustomActions = props.customActions;
    setModalCustomActions(venueCustomActions);
  }, [props.showModal]);

  useEffect(() => {
    setActionNote(
      modalCustomActions
        .filter((action: any) => action.selected)
        .map((action: any) => action.stringContent)
        .join(', ')
    );
  }, [modalCustomActions]);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={props.showModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <form
            className="max-w p-10 mx-auto"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h1 className="text-left text-xl font-semibold text-gray-900 dark:text-white mb-4">
              {`Add Action`}
            </h1>
            <p className="text-left text-sm font-light text-gray-900 dark:text-white mb-4">
              Pick the sign that best describes the conversation.
            </p>
            <div className="mt-4 border-t">
              <div
                onClick={() => {
                  handleCheckboxChange('close case', true);
                }}
                className={`mt-6 cursor-pointer flex flex-row justify-between focus:outline-none items-center p-3 gap-3 rounded-xl`}
                style={{
                  outline: isClosingCase
                    ? '2px solid #eb4c2d'
                    : '1px solid gray',
                  color: isClosingCase ? '#eb4c2d' : 'black',
                }}
              >
                <label
                  id={`checkbox-close-case`}
                  className="cursor-pointer ms-2 text-sm font-medium dark:text-gray-300"
                >
                  {`Close Case`}
                </label>
                <input
                  checked={isClosingCase}
                  id={`checkbox-close-case`}
                  type="checkbox"
                  className="w-4 h-4 text-primary-900 bg-gray-100 border-gray-300 rounded focus:ring-primary-900 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
            </div>

            {modalCustomActions.filter((action: any) => action.selected)
              .length > 0 && (
              <div className="mt-4">
                <p className="text-sm font-semibold text-gray-900 dark:text-white">
                  You have selected{' '}
                  {
                    modalCustomActions.filter((action: any) => action.selected)
                      .length
                  }{' '}
                  actions
                </p>
                <div className="flex flex-wrap">
                  {modalCustomActions
                    .filter((action: any) => action.selected)
                    .map((action: any, index: number) => (
                      <p
                        key={index}
                        className="bg-red-100 inline-flex items-center m-2 text-primary-900 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300"
                      >
                        {action.stringContent}
                      </p>
                    ))}
                </div>
              </div>
            )}

            <div className="mt-4 border-t">
              <textarea
                value={actionNote}
                onChange={(e) => setActionNote(e.target.value)}
                placeholder="Enter actions description"
                className="w-full px-4 py-3 mt-4 text-sm text-gray-900 dark:text-gray-300 dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-900 focus:border-transparent dark:focus:border-transparent resize-none overflow-hidden"
                rows={1}
                onInput={(e: any) => {
                  const textarea = e.target;
                  textarea.style.height = 'auto'; // Reset the height
                  textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scroll height
                }}
                style={{ minHeight: '3rem' }}
              />
            </div>

            <div className="border-t mt-4">
              <h1 className="text-left text-sm font-semibold text-gray-900 dark:text-white mb-3 mt-3">
                {/* Staff Action */}
              </h1>
              <div className="mt-4">
                <div>
                  {' '}
                  <p className="text-md font-semibold text-gray-900 dark:text-white">
                    Staff Actions
                    {modalCustomActions.filter((action: any) => action.selected)
                      .length > 0
                      ? ` (${
                          modalCustomActions.filter(
                            (action: any) => action.selected
                          ).length
                        })`
                      : ''}
                  </p>
                </div>
                <div className="mt-4">
                  <div className="flex flex-col gap-2">
                    {modalCustomActions.map(
                      (action: any, index: Key | null | undefined) => {
                        const isChecked = action.selected || false;

                        if (isChecked) {
                          return null;
                        }

                        return (
                          <div
                            onClick={() => {
                              setModalCustomActions((prevState: never[]) => {
                                const currentSelections = prevState || [];
                                const isMatch = currentSelections.find(
                                  (item: any) =>
                                    item.venueStringSettingId ===
                                    action.venueStringSettingId
                                );

                                if (isMatch) {
                                  return currentSelections.map((item: any) => {
                                    if (
                                      item.venueStringSettingId ===
                                      action.venueStringSettingId
                                    ) {
                                      return {
                                        ...item,
                                        selected: !item.selected,
                                      };
                                    }
                                    return item;
                                  });
                                }
                                return currentSelections;
                              });
                            }}
                            key={`checkbox-${'custom actions'}-${index}`}
                            className={`transition duration-150 ease-out  cursor-pointer flex flex-row justify-between  items-center p-3 gap-3 rounded-xl ${
                              isChecked
                                ? ' border-primary-900 border-2'
                                : ' border'
                            }`}
                          >
                            <label
                              htmlFor={`checkbox-${'custom actions'}-${index}`}
                              className={`ms-2 text-sm font-medium dark:text-gray-300 ${
                                isChecked ? 'text-primary-900' : 'text-gray-900'
                              }`}
                            >
                              {action.stringContent}
                            </label>
                            <input
                              id={`checkbox-${'custom actions'}-${index}`}
                              type="checkbox"
                              checked={isChecked}
                              className="w-4 h-4 text-primary-900 bg-gray-100 border-gray-300 rounded focus:ring-primary-900 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center justify-center gap-2 mt-4 mb-2 dark:gap-4">
              <button
                onClick={saveSelections}
                type="button"
                className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Submit Action
              </button>
              <button
                type="button"
                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                onClick={resetModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};
