import { useContext, useEffect, useState } from 'react';
import { format, parseISO, subDays, addDays } from 'date-fns';
import { observer } from 'mobx-react';
import { SummaryStore, UserStore } from '../../../index';
import { useHistory } from 'react-router';
import { reaction } from 'mobx';
import { PageContainer, PageLoader } from '../../ui/PageComponents';
import { DateValueType } from 'react-tailwindcss-datepicker';
import ApiClient from '../../../ApiClient';
import moment from 'moment';
import { RequestSources } from '../../../state/SummaryState';

import RadioGroup from './radio-group';
import SearchTypeContainer from './search-type-container';
import ErrorTextContainer from './error-text-container';
import ExportButtonContainer from './export-button-container';
import FilterContainer from './filter-container';
import DataTableContainer from './data-table-container';
import { cloneDeep } from 'lodash';

export const getActionSourceText = (input: RequestSources) => {
  if (input == 'INCIDENT_APP') {
    return 'Incident App';
  } else if (input == 'FACIAL_ID') {
    return 'Facial ID';
  } else if (input == 'COMMAND_CENTRE') {
    return 'Command Centre';
  } else if (input == 'GENERAL_API') {
    return 'System Triggered';
  } else if (input == 'MOBILE') {
    return 'Responder App';
  }
  return input;
};

export const initialColumnsState = [
  {
    show: true,
    name: 'Incident Time',
    key: 'timeCreated',
    modalKey: 'timeCreated',
    render: (val: any) => moment(val).format('h:mm a MMM Do YYYY'),
  },
  {
    show: true,
    name: 'Acting Staff Member Name',
    key: 'actingStaffMemberName',
    modalKey: 'venueStaffName',
    render: (val: any) => val ?? '',
  },
  {
    show: true,
    name: 'Incident Location',
    key: 'siteLocation',
    modalKey: 'siteLocation',
    render: (val: any) => val ?? '',
  },
  {
    show: true,
    name: 'Staff Action Taken',
    key: 'resultType',
    modalKey: 'actionStatus',
    render: (val: { isModal: boolean; value: string }) =>
      !val.isModal
        ? val.value === 'HELP_REQUEST'
          ? 'CREATED'
          : 'ACTION'
        : val.value,
  },
  {
    show: true,
    name: 'Details of Person Involved',
    key: 'requesterName',
    modalKey: '',
    render: (val: any) => val ?? '',
  },
  {
    show: true,
    name: 'Notes about Actions',
    key: 'notes',
    modalKey: 'actionNote',
    render: (val: any) => val ?? '',
  },
  {
    show: true,
    name: 'Other Information',
    key: 'otherInformation',
    modalKey: 'otherInformation',
    render: (val: any) => val ?? '',
  },
];

export const handleColumns = (columnsState: any) => {
  return columnsState
    .filter((column: any) => column.show)
    .map((column: any) => {
      let key = column.key;
      if (key === 'helpRequestId') {
        return '';
      }
      if (
        key === 'otherInformation' ||
        column.modalKey === 'otherInformation'
      ) {
        key = 'Notes';
      }
      if (key === 'notes') {
        key = 'KeyNotes';
      }
      if (key === 'resultType') {
        key = 'Status';
      }
      return key
        .replace(/([A-Z])/g, '$1')
        .replace(/^./, (str: string) => str.toUpperCase());
    })
    .filter((s: string) => s !== '');
};

export const CaseLogSearchPage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();
  const [data, setData] = useState<any>({
    searchResults: [],
    totalResults: 0,
  });
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState('date-range');
  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: format(subDays(new Date(), 1), "yyyy-MM-dd'T'00:00:00"),
    endDate: format(subDays(new Date(), 0), "yyyy-MM-dd'T'00:00:00"),
  });
  const [columnsState, setColumnsState] = useState(initialColumnsState);

  const resetStates = () => {
    setData({
      searchResults: [],
      totalResults: 0,
    });
    setError('');
    setLoading(false);
    setSearchType('date-range');
    setSearchValue('');
    setDateRange({
      startDate: format(subDays(new Date(), 1), "yyyy-MM-dd'T'00:00:00"),
      endDate: format(subDays(new Date(), 0), "yyyy-MM-dd'T'00:00:00"),
    });
    setColumnsState(cloneDeep(initialColumnsState));
  };

  const handleValueChange = (newValue: {
    startDate: string;
    endDate: string;
  }) => {
    const dateRange = {
      startDate: format(newValue.startDate, "yyyy-MM-dd'T'00:00:00"),
      endDate: format(newValue?.endDate, "yyyy-MM-dd'T'00:00:00"),
    };

    setDateRange(dateRange);
  };

  const handleSearchByName = (searchType: string) => {
    if (searchType === 'date-range') {
      return;
    }

    if (searchValue.length < 3) {
      setError('Please enter atleast 3 characters to search');
      return;
    }

    setLoading(true);
    setError('');

    ApiClient.getCaseLogSearch({
      searchType,
      value: searchValue,
    })
      .then((response: any) => {
        if (response.data.searchResults.length > 0) {
          setData(response.data);
          return;
        }
        setError(`No results found for search term: ${searchValue}`);
        setData({
          searchResults: [],
          totalResults: 0,
        });
      })
      .catch((err) => {
        console.error(err);
        setError(`No results found for search term: ${searchValue}`);
        setData({
          searchResults: [],
          totalResults: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeValue = () => {
    if (
      ['member-name', 'staff-name'].includes(searchType) &&
      searchValue !== '' &&
      searchValue.length < 3
    ) {
      setError('Please enter atleast 3 characters to search');
      return;
    }
    setError('');
    handleSearchByName(searchType);
  };

  const handleExport = (exportType: 'pdf' | 'excel') => {
    if (data?.searchResults?.length < 1) {
      setError('No data to export');
      return;
    }

    const columnsDisplay = handleColumns(columnsState);

    ApiClient.exportCaseLogSearch({
      columnsDisplay,
      searchType,
      value:
        searchType === 'date-range' &&
        dateRange?.startDate &&
        dateRange?.endDate
          ? {
              startTime: format(
                parseISO(dateRange.startDate as string),
                "yyyy-MM-dd'T'HH:mm:ss"
              ),
              endTime: format(
                addDays(parseISO(dateRange.endDate as string), 1),
                "yyyy-MM-dd'T'HH:mm:ss"
              ),
            }
          : searchValue,
      exportType,
    })
      .then((response: any) => {
        if (response.status !== 200) {
          setError('Error exporting data');
          return;
        }

        if (exportType === 'pdf') {
          // Convert response data to a Blob
          const blob = new Blob([response.data], {
            type: 'application/pdf', // Assuming response.data contains the PDF data
          });
          const fileName = `Incident_Logs_${moment().format(
            'YYYY-MM-DD_HH-mm-ss'
          )}.pdf`;

          const link = document.createElement('a');
          // create a blobURI pointing to our Blob
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          // some browsers need the anchor to be in the doc
          document.body.append(link);
          link.click();
        }

        if (exportType === 'excel') {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Correct MIME type for Excel
          });
          const fileName = `Incident_Logs_${moment().format(
            'YYYY-MM-DD_HH-mm-ss'
          )}.xlsx`;

          const link = document.createElement('a');
          // create a blobURI pointing to our Blob
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          // some browsers need the anchor to be in the doc
          document.body.append(link);
          link.click();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getLogSearch = () => {
    if (
      dateRange?.startDate &&
      dateRange?.endDate &&
      searchType === 'date-range'
    ) {
      setLoading(true);
      setError('');
      ApiClient.getCaseLogSearch({
        searchType: 'date-range',
        value: {
          startTime: format(
            parseISO(dateRange?.startDate as any),
            "yyyy-MM-dd'T'HH:mm:ss"
          ),
          endTime: format(
            addDays(parseISO(dateRange?.endDate as any), 1),
            "yyyy-MM-dd'T'HH:mm:ss"
          ),
        },
      })
        .then((response: any) => {
          if (response?.data?.searchResults?.length > 0) {
            setData(response?.data);
            return;
          } else {
            setError(
              `No results found for date: ${moment(dateRange?.startDate).format(
                'YYYY-MM-DD'
              )} to ${moment(dateRange?.endDate).format('YYYY-MM-DD')}`
            );
            setData({
              searchResults: [],
              totalResults: 0,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          setError(
            `No results found for date: ${moment(dateRange?.startDate).format(
              'YYYY-MM-DD'
            )} to ${moment(dateRange?.endDate).format('YYYY-MM-DD')}`
          );
          setData({
            searchResults: [],
            totalResults: 0,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (searchValue === '') {
      setError('');
      setLoading(false);
      return;
    }
    setLoading(true);

    const timerId = setTimeout(() => {
      onChangeValue();
    }, 500);

    // Cleanup function to clear timeout on component unmount or input change
    return () => clearTimeout(timerId);
  }, [searchValue]);

  useEffect(() => {
    if (searchType === 'date-range') {
      getLogSearch();
    }
  }, [dateRange, searchType]);

  useEffect(() => {
    reaction(
      () => [userStore.isLoggedIn, userStore.selectedVenue],
      (values) => {
        const isLoggedIn = values[0];
        const selectedVenue = values[1];

        if (!isLoggedIn || !selectedVenue) {
          history.push('/login');
        } else {
          summaryStore.refreshHomeData();
          summaryStore.startHomeDataPolling();
          resetStates();
        }
      },
      {
        fireImmediately: true,
      }
    );
  }, []);

  if (!summaryStore.dataLoaded) {
    return <PageLoader />;
  }

  return (
    <PageContainer
      breadcrumbs={[
        { name: 'Home', route: '/home' },
        {
          name: 'Gaming Incident Register',
          route: '/gaming-incident-register',
        },
      ]}
    >
      <h1 className="text-3xl font-bold text-gray-900 mb-4">
        {userStore.selectedVenue?.displayName} Gaming Incident Register
      </h1>
      <div className="my-4">
        Search by date range to view all records, or search by member details to
        find all specific logged actions
      </div>
      <RadioGroup
        setSearchType={setSearchType}
        setSearchValue={setSearchValue}
        setError={setError}
        setData={setData}
      />
      <SearchTypeContainer
        searchType={searchType}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        dateRange={dateRange}
        handleValueChange={handleValueChange}
      />
      <ExportButtonContainer data={data} handleExport={handleExport} />
      <FilterContainer
        columnsState={columnsState}
        setColumnsState={setColumnsState}
        data={data}
      />
      <ErrorTextContainer error={error} />
      <DataTableContainer
        data={data}
        columnsState={columnsState}
        error={error}
        loading={loading}
      />
    </PageContainer>
  );
});
