/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { observer, useLocalObservable } from 'mobx-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { SummaryStore, UserStore } from '../../../index';
import { reaction, runInAction, when } from 'mobx';
import { useHistory } from 'react-router';
import {
  AlertLevels,
  HelpRequestActionLogItem,
  UserHelpRequests,
} from '../../../state/SummaryState';
import moment from 'moment';
import { PageContainer, PageHeader, PageLoader } from '../../ui/PageComponents';
import { CaseActionModal } from '../../modals/CaseActionModal';
import ApiClient from '../../../ApiClient';
import { CaseDetailsCardContainer } from './case-details-card-container';
import CaseDetailsTabs from './case-details-tabs';
import { cloneDeep, sum } from 'lodash';
import { CasePlayerAssessment } from '../../modals/CasePlayerAssessment';
import { CaseNoteModal } from '../../modals/CaseNoteModal';

export type RequestActionTaken =
  | 'CREATED'
  | 'CLOSE_CASE'
  | 'REOPENED'
  | 'OPEN_CASE'
  | 'ADD_NOTE'
  | 'SPOKE_IN_PERSON'
  | 'SPOKE_ON_PHONE'
  | 'NO_ANSWER'
  | 'LEFT_VOICEMAIL'
  | 'NUMBER_DOESNT_DIAL'
  | 'CALL_BUTTON'
  | 'HANDED_TO_THIRD_PARTY';

export const cardTimeStyle = {
  marginBottom: 8,
  marginLeft: 'auto',
  fontSize: 14,
};
export const cardHeaderStyle = {
  display: 'flex',
  alignItems: 'center',
  fontStyle: 'italic',
  fontWeight: 'bold',
};

export const dividerCss = {
  border: '1px solid lightgrey',
  marginTop: 12,
  marginBottom: 16,
  width: '90%',
  marginLeft: 'auto',
  marginRight: 'auto',
};

export const CaseDetailsPage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();
  const { helpRequestId } = useParams<{ helpRequestId: string }>();

  const pollingTimer = useRef<NodeJS.Timeout>();

  const [showCaseModal, setShowCaseModal] = useState(false);
  const [openModal, setOpenModal] = useState({
    playerAssessmentModal: false,
    addActionModal: false,
    addNoteModal: false,
  });
  const [requestActionLogs, setRequestActionLogs] = useState<
    HelpRequestActionLogItem[]
  >([]);

  const localState = useLocalObservable<{
    userHelpRequest: UserHelpRequests | undefined;
  }>(() => ({
    userHelpRequest: undefined,
  }));

  useEffect(() => {
    when(
      () => userStore.selectedVenue === undefined,
      () => {
        history.push('/login');
      }
    );

    // swap venue data
    reaction(
      () => userStore.selectedVenue,
      () => {
        history.goBack();
      }
    );

    reaction(
      () => userStore.isLoggedIn,
      async (isLoggedIn) => {
        if (!isLoggedIn) {
          history.push('/login');
        }
      },
      {
        fireImmediately: true,
      }
    );

    updateHelpRequestDetails();

    startPolling();

    return () => {
      if (pollingTimer.current) clearInterval(pollingTimer.current);
    };
  }, []);

  const doAction = (actionTaken: RequestActionTaken, actionNote: string) => {
    ApiClient.doHelpRequestAction(helpRequestId, actionTaken, actionNote)
      .then((response) => {
        updateHelpRequestDetails();
        setShowCaseModal(false);
      })
      .catch((e) => {});
  };

  const updateHelpRequestDetails = () => {
    ApiClient.getHelpRequestDetails(helpRequestId)
      .then(async (response) => {
        await setRequestActionLogs(response.data.requestActionLogs);

        runInAction(() => {
          localState.userHelpRequest = response.data;

          if (!localState.userHelpRequest) return;

          const currentTime = moment().valueOf();
          const secondsSince = Math.ceil(
            (currentTime - localState.userHelpRequest.requestInitiatedTime) /
              1000
          );

          const minsSince = Math.ceil(secondsSince / 60);
          let alertLevel: AlertLevels;

          if (
            localState.userHelpRequest.actionStatus == 'COMPLETE' ||
            minsSince <= 5
          ) {
            alertLevel = 'OK';
          } else if (minsSince <= 10) {
            alertLevel = 'DELAYED';
          } else {
            alertLevel = 'WARNING';
          }

          localState.userHelpRequest.secondsSince = secondsSince;
          localState.userHelpRequest.alertLevel = alertLevel;
        });
      })
      .catch((e) => {});
  };

  const startPolling = () => {
    pollingTimer.current = setInterval(() => {
      updateHelpRequestDetails();
    }, 5000);
  };

  if (localState.userHelpRequest === undefined) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }

  return (
    <PageContainer
      breadcrumbs={[
        { name: 'Home', route: '/home' },
        { name: 'Cases', route: '/cases' },
        { name: 'View Case', route: '/case-details' },
      ]}
    >
      <PageHeader title={'Case Details'} />

      <CaseActionModal
        showModal={openModal.addActionModal}
        setShowModal={(show: boolean) =>
          setOpenModal({ ...openModal, addActionModal: show })
        }
        actionCallback={doAction}
        customActions={summaryStore.customActions}
        incidentReasons={summaryStore.incidentReasons}
        userHelpRequest={localState.userHelpRequest}
      />

      <CaseNoteModal
        showModal={openModal.addNoteModal}
        setShowModal={(show: boolean) =>
          setOpenModal({ ...openModal, addNoteModal: show })
        }
        actionCallback={doAction}
        customActions={summaryStore.customActions}
        incidentReasons={summaryStore.incidentReasons}
        userHelpRequest={localState.userHelpRequest}
      />

      <CasePlayerAssessment
        showModal={openModal.playerAssessmentModal}
        setShowModal={(show: boolean) =>
          setOpenModal({ ...openModal, playerAssessmentModal: show })
        }
        actionCallback={doAction}
        customActions={summaryStore.customActions}
        incidentReasons={summaryStore.incidentReasons}
        userHelpRequest={localState.userHelpRequest}
      />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <CaseDetailsCardContainer
          case={localState.userHelpRequest}
          onClick={setOpenModal}
        />
        <CaseDetailsTabs
          requestActionLogs={requestActionLogs}
          case={localState.userHelpRequest}
        />
      </div>
    </PageContainer>
  );
});
