/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import * as React from 'react';
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {useContext, useEffect, useState} from "react";
import ApiClient from "../../ApiClient";
import {reaction} from "mobx";
import {UserStore} from "../../index";

type CaseRequestsCountRow = {
    name: string
    MembersCount: number
    NonMembersCount: number
}

export type PEDoubleLineGraphProps = {
    chartTitle: string

    firstItemHexColour: string
    secondItemHexColour: string
}

export const PEDoubleLineGraph = (props: PEDoubleLineGraphProps) => {
    const userStore = useContext(UserStore);
    
    const width = 600;
    const height = 300;
    
    // const [hasData, setHasData] = useState(false);
    const [dayCount, setDayCount] = useState(30);
    const [data, setData] = useState<CaseRequestsCountRow[] | undefined>(undefined);
    
    useEffect(() => {
        // swap venue data
        reaction(
            () => userStore.selectedVenue,
            () => {
                setData(undefined);

                if (userStore.selectedVenue === undefined) return;
                
                loadCaseData(dayCount);
            }
        );
        
        loadCaseData(dayCount);
    }, []);
    
    const loadCaseData = (days: number) => {
        setDayCount(days);
        
        ApiClient.getCaseCountData(days)
            .then((response: any) => {
                const data: CaseRequestsCountRow[] = [];
                
                response.data.dayData.forEach((dayData: any) => {
                    data.push({
                        name: dayData.dayDisplayText,
                        MembersCount: dayData.countFirst,
                        NonMembersCount: dayData.countSecond,
                    });
                });
                
                setData(data);
            })
            .catch((error) => {});
    };
    
    return <div className={'chart-container md-elevation-z4'}>
        
        <div css={{ color: 'black', textAlign: 'center', fontSize: 20, marginBottom: 18 }}>{props.chartTitle}</div>
        
        {data ? (
            <LineChart
                width={width} height={height}
                data={data}
                margin={{top: 5, right: 30, left: 20, bottom: 5}}>

                <CartesianGrid strokeDasharray="3 3" />
                
                <XAxis
                    dataKey="name"
                />

                <YAxis
                    allowDecimals={false}
                />

                <Tooltip label={"name"} />

                <Legend wrapperStyle={{color: 'white'}} />

                <Line
                    type="monotone"
                    dataKey="MembersCount"
                    stroke={props.secondItemHexColour}
                    name={"Members"}
                />
                
                <Line
                    type="monotone"
                    dataKey="NonMembersCount"
                    stroke={props.firstItemHexColour}
                    name={"Non-Members"}
                    activeDot={{ r: 5 }}
                />
                
            </LineChart>
        ) : (
            <div className={'flex-cen-stack'} css={{width: width, height: height}}>
                <h2 className={'analytics-loading-text'}>Loading</h2>
                <div className="ball-pulse">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )}

        <div className={'flex-cen-horizontal'} css={{marginTop: 16, marginBottom: -24}}>
            <button
                className={'btn btn-primary btn-small btn-data flex-cen-horizontal' + (dayCount == 30 ? '' : ' pe-fade')}
                onClick={() => loadCaseData(30)}>
                30 Days
            </button>
            
            <button
                className={'btn btn-primary btn-small btn-data flex-cen-horizontal' + (dayCount == 60 ? '' : ' pe-fade')}
                onClick={() => loadCaseData(60)}>
                60 Days
            </button>
            
            <button
                className={'btn btn-primary btn-small btn-data flex-cen-horizontal' + (dayCount == 90 ? '' : ' pe-fade')}
                onClick={() => loadCaseData(90)}>
                90 Days
            </button>
        </div>

        {/*{props.showTooltip &&*/}
        {/*    <GraphTooltip*/}
        {/*        tooltipInfo={props.tooltipInfo}*/}
        {/*    />*/}
        {/*}*/}
    </div>
}